import React, { useState } from 'react'
import {SectionTop} from '../../ui-elements/index';
import {Row, Col, Form, Input, Typography} from 'antd'
import ReplaButton from '../../components/buttons';
const {Text}= Typography
const ContactUsFrom=()=>
{
    const [state,setState]=useState({name:"",email:"",company:"",subject:"",message:""})
    const contacts=[
        {img:'map.png',title:'Visit Us Daily:',description:'Office No.2 Ovais Co, Islamabad, Pakistan'},
        {img:'phone.png',title:'Phone Us:',description:'+92 302 6469012'},
        {img:'mail.png',title:'Mail Us:',description:'admin@mkaits.com'},
    ]
    const handleChange=(key,value)=>{
        setState({...state,[key]:value})
    }
        return (
            <div className="flex-col-center py-5 w-100">
            <SectionTop title='OUR CONTACTS' description='We’re Here to Help You'/>
            <Row gutter={[64,0]}  style={{width:'93%'}}>
                <Col lg={{span:10}} sm={{span:24}} xs={{span:24}} className="brand-bg flex-col-center-center py-5">
                    {
                        contacts?.map((contact,c)=>
                            <div className={c===2?"align-center p-3 border w-100 rounded-1":"align-center p-3 border w-100 mb-5 rounded-1"}>
                                <img src={'/assets/'+contact?.img} alt='Mkaits' width='40px' height='auto' className='me-4'/>
                                <div>
                                    <h4 className='text-white m-0 mb-1'>{contact?.title}</h4>
                                    <p className='m-0 text-white'>{contact?.description}</p>
                                </div>
                            </div>
                        )
                    }
                </Col>
                <Col lg={{span:14}} sm={{span:24}} xs={{span:24}} className='py-5 light-bg'>
                    <Form layout="vertical">
                        <Row gutter={[16,16]}>
                            <Col md={{span:12}} sm={{span:24}} xs={{span:24}}>
                                <Form.Item
                                    name="name" 
                                    label={<Text strong>Name</Text>}
                                    rules={[
                                    {
                                        required: true,
                                        message: 'Please enter your name',
                                    },
                                    ]}
                                >
                                    <Input 
                                        size='large'
                                        placeholder="Please enter your name" 
                                        value={state?.name || ''} 
                                        onChange={(e)=>{handleChange('name',e.target.value)}}/>
                                </Form.Item>
                            </Col>
                            <Col md={{span:12}} sm={{span:24}} xs={{span:24}}>
                                <Form.Item
                                    name="email"
                                    label={<Text strong>Email</Text>}
                                    rules={[
                                    {
                                        required: true,
                                        message: 'Please enter your email',
                                    },
                                    ]}
                                >
                                <Input 
                                    size='large'
                                    placeholder="Please enter your email" 
                                    value={state?.email || ''} 
                                    onChange={(e)=>{handleChange('email',e.target.value)}}/>
                                </Form.Item>
                            </Col>
                            <Col md={{span:12}} sm={{span:24}} xs={{span:24}}>
                                <Form.Item
                                    name="company" 
                                    label={<Text strong>Company/ Oraganization</Text>}
                                    rules={[
                                    {
                                        required: true,
                                        message: 'Please enter your company/ oraganization',
                                    },
                                    ]}
                                >
                                    <Input 
                                        size='large'
                                        placeholder="Please enter your company/ oraganization" 
                                        value={state?.company || ''} 
                                        onChange={(e)=>{handleChange('company',e.target.value)}}/>
                                </Form.Item>
                            </Col>
                            <Col md={{span:12}} sm={{span:24}} xs={{span:24}}>
                                <Form.Item
                                    name="subject"
                                    label={<Text strong>Subject</Text>}
                                    rules={[
                                    {
                                        required: true,
                                        message: 'Please enter your subject',
                                    },
                                    ]}
                                >
                                <Input 
                                    size='large'
                                    placeholder="Please enter your subject" 
                                    value={state?.subject || ''} 
                                    onChange={(e)=>{handleChange('subject',e.target.value)}}/>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    name="message"
                                    label={<Text strong>Message</Text>}
                                    rules={[
                                    {
                                        required: true,
                                        message: 'Please enter your message',
                                    },
                                    ]}
                                >
                                <Input.TextArea
                                    rows={4}
                                    size='large'
                                    placeholder="Please enter your message" 
                                    value={state?.message || ''} 
                                    onChange={(e)=>{handleChange('message',e.target.value)}}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row className='justify-end'>
                            {/* <Button type="primary" size='large' htmlType="submit" ></Button> */}
                            <div style={{width:'230px'}}>
                                <ReplaButton title={'Submit Feedback'}/>
                            </div>
                        </Row>
                    </Form>
                </Col>
            </Row>
         </div>
        )
}
export default ContactUsFrom