import {Row, Col, Typography, Space} from "antd"
import "./index.css"
const {Title}= Typography
//style={{backgroundImage:"linear-gradient(rgba(0,0,0,0.9),rgba(0,0,0,0.9)), url(/assets/industry-bg.jpg)"
const WhyBCT=()=>
    {
        const state1=[
            {title:'98%',description:'On-Time & Budget Delivery'},
            {title:'10+',description:'Years of Experience'},
            {title:'15+',description:'Industries Served'}
        ]
        const state2=[
            {title:'24/7',description:'Dedicated Support'},
            {title:'150+',description:'Long-standing Clients'},
            {title:'250+',description:'Successful Projects'},
        ]
        return(
            <>
             <div className="py-5 w-100 why-choose-bct" style={{backgroundImage:"url(/assets/Group-3520.jpg)"}}>
                <div className='flex-col-center'>
                    <div className={'py-3 flex-col-center'} style={{width:'93%'}}>
                        <Title level={3} className='brand-color mb-1 text-center'>
                            Why Choose Us
                        </Title>
                        <Title level={1} className='mt-0 text-center'>
                            We just meet your expectations.
                        </Title>
                    </div>  
                    <Row style={{width:'93%'}} className='py-5'>
                        <Col md={{span:10}} lg={{span:12}} sm={{span:0}}></Col>
                        <Col md={{span:14}} lg={{span:12}}  sm={{span:24}}>
                            <Row gutter={[32,32]} className='m-0 p-0'>
                                {
                                    state1?.map((col,c)=>
                                    <Col sm={{span:8}} xs={{span:24}} className={c!==2?'border-end brand-border-color':''} key={c}>
                                        <Space direction="vertical" className="w-100 h-100 py-0">
                                            <Title level={1} className='brand-color text-center my-0'>
                                                {
                                                    col?.title
                                                }
                                            </Title>
                                            <Title level={4} className="text-center px-2 my-0">
                                                {
                                                    col?.description
                                                }
                                            </Title>
                                        </Space>
                                    </Col>
                                    )
                                }
                                <Col sm={{span:8}} xs={{span:0}}>
                                    <div className="border-top brand-border-color"></div>
                                </Col>
                                <Col sm={{span:8}} xs={{span:0}}>
                                    <div className="border-top brand-border-color"></div>
                                </Col>
                                <Col sm={{span:8}} xs={{span:0}}>
                                    <div className="border-top brand-border-color"></div>
                                </Col>
                                {
                                    state2?.map((col,c)=>
                                    <Col sm={{span:8}} xs={{span:24}} className={c!==2?'border-end brand-border-color':''} key={'state2-'+c}>
                                        <Space direction="vertical" className="w-100 h-100 py-0">
                                            <Title level={1} className='brand-color text-center my-0'>
                                                {
                                                    col?.title
                                                }
                                            </Title>
                                            <Title level={4} className="text-center px-2 my-0">
                                                {
                                                    col?.description
                                                }
                                            </Title>
                                        </Space>
                                    </Col>
                                    )
                                }
                            </Row>
                        </Col>
                    </Row>
                </div>
             </div>
            </>
        )
    }
export default WhyBCT;