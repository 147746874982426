import { BreadCrumb } from "../../ui-elements/index";
import ContactUsForm from "./contactus form";
import { Helmet } from "react-helmet";
import { ContactUs as MkaitsContactUs } from "../../components";
const ContactUs = () => {
  const title = "Mkaits - Contact Us";
  const description =
    "Connect with Mkaits today. Reach out to our team for inquiries, partnerships, or support. Let's start a conversation and explore how we can empower your business together.";
  const image = "https://www.mkaits.com/assets/logo.png";
  const url = "https://www.mkaits.com/contact-us";
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Mkaits Technology" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />
        <meta property="og:url" content={url} />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={image} />
      </Helmet>
      <BreadCrumb
        title="Contact Us"
        description="We Can Help You Succeed"
        pageName="Contact Us"
        breadcrumb={[{ to: "/", name: "Home" }]}
      />
      <div className="flex-col-center">
        <MkaitsContactUs />
        {/* <ContactUsForm/> */}
        <iframe
          title="mkaits-office-location"
          style={{ width: "100%", height: "70vh", border: "none" }}
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3387.9792571780777!2d115.85080889999999!3d-31.879980699999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2a32af82e33a1117%3A0x11f118b5ec6b9576!2sMkaits%20House!5e0!3m2!1sen!2s!4v1708374912954!5m2!1sen!2s"
          allowfullscreen=""
          loading="lazy"
        ></iframe>
      </div>
    </>
  );
};
export default ContactUs;
