import React, {useState} from 'react';
import Slider from 'react-slick';
import { Row, Col, Space } from "antd";
import Datanewsart from './Datanewsart.js';
import './newsartical.css';


function NewsArtical() {
    const [datanewsart, setDataNewsArt] = useState(Datanewsart)
    const [selectnewsart, setSelectNewsArt] = useState(0)

    let Newsart = {
        dots: false,
        infinite: true,
        speed: 2000,
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        autoplaySpeed: 1000,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };


  return (
    <div>
        <Row gutter={[32,24]}>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <section className="blog-two">
                    <div className="container">
                        <div className="section-title text-center">
                            <div className="section-title__tagline-box">
                                <span className="section-title__tagline">{datanewsart[selectnewsart]?.subtitle}</span>
                                <div className="section-title-shape">
                                    <img src="assets/section-title-shape.png" alt="" />
                                </div>
                            </div>
                            <h2 className="section-title__title">{datanewsart[selectnewsart]?.title}</h2>
                        </div>
                        <Slider {...Newsart}>
                            {
                                datanewsart[selectnewsart]?.newsdiv?.map((newsart,nwa)=>
                                <div className='ps-3 pe-3' key={nwa}>
                                    <div className="blog-two__single">
                                        <div className="blog-two__img">
                                            <img src={"assets/"+newsart?.image} alt="" />
                                            <div className="blog-two__tag">
                                                <p>{newsart?.type}</p>
                                            </div>
                                            <a href="/">
                                                <span className="blog-two__plus"></span>
                                            </a>
                                        </div>
                                        <div className="blog-two__content">
                                            <ul className="list-unstyled blog-two__meta">
                                                <li>
                                                    <a href="/" className='me-3'>
                                                        <Space size={5} align="flex-start">
                                                            <i>{newsart?.clockicon}</i> 
                                                            <p className='mb-0'>
                                                                {newsart.date}
                                                            </p>
                                                        </Space>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/">
                                                        <Space size={5} align="flex-start">
                                                            <i>{newsart?.usericon}</i>
                                                            <p className='mb-0'>
                                                                {newsart?.user} 
                                                            </p>
                                                        </Space>
                                                    </a>
                                                </li>
                                            </ul>
                                            <h3 className="blog-two__title">
                                                <a href="/">
                                                    {newsart?.description}
                                                </a>
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                                )
                            }
                        </Slider>
                    </div>
                </section>
            </Col>
        </Row>
    </div>
  )
}

export default NewsArtical