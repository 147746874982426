import { Row, Col, Typography, Space, Image } from "antd"
import './index.css'
const { Title } = Typography
const AboutRepla = () => {
    return (
        <div className="justify-center intro py-5 my-5">
            <Row gutter={[32, 32]} style={{ width: '93%' }}>
                <Col xl={{ span: 12 }} lg={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }} className='align-center'>
                    <Space direction='vertical' size={30}>
                        <div>
                            <Title level={3} className='brand-color'>Stay protected</Title>
                            <Title level={1} className='my-0'>
                                Mkaits is more than tech company
                            </Title>
                        </div>
                        <Title level={5} className='my-0'>
                            Providing Innovative Technology Solutions and Expert Brand Image to Achieve Business Goals
                        </Title>
                        <Row gutter={[24, 24]}>
                            <Col sm={{ span: 12 }} xs={{ span: 24 }}>
                                <Space direction="vertical">
                                    <img src='/assets/icons/manufacturing-brand.png' width={'90px'} />
                                    <Title level={5} className='my-0'>Developing Expert Brand Image to Enhance Recognition and Belief</Title>
                                    <p>Focus on providing high-quality technology solutions and services to showcase expertise and build a reputation as an industry leader</p>
                                </Space>
                            </Col>
                            <Col sm={{ span: 12 }} xs={{ span: 24 }}>
                                <Space direction="vertical">
                                    <img src='/assets/icons/mobile-banking-brand.png' width={'90px'} />
                                    <Title level={5} className='my-0'>Embracing Complexity to Gain a Competitive Edge</Title>
                                    <p>Develop tailored software solutions that enable businesses to embrace complexity, enhance productivity, and gain a competitive edge in their respective industries.</p>
                                </Space>
                            </Col>
                        </Row>
                        <Space className="align-center">
                            <div className='brand-border p-2' style={{ borderRadius: '50%', borderWidth: '2px' }} >
                                <Image
                                    preview={false}
                                    src='/assets/images/team/ceo.jpg'
                                    alt="Mkaits ceo Mughira Ahmed"
                                    style={{ borderRadius: '50%' }}
                                    width={70}
                                />
                            </div>
                            <Space direction='vertical' size={1}>
                                <Title level={4} className='my-0'>Our CEO Mughira Ahmed</Title>
                                <p className="my-0">Co founder of Mkaits</p>
                            </Space>
                        </Space>
                    </Space>
                </Col>
                <Col xl={{ span: 12 }} lg={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                    <div className="about-left align-center">
                        <div className="brand-bg">
                            <Image src='/assets/intro.jpg' alt='Mkaits' className="img-fluid" preview={false} />
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    )
}
export default AboutRepla