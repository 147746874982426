import React from "react";
import "antd/dist/antd.less";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Helmet } from "react-helmet";
// import TopSlip from '../components/topslip'
import Navbar from "../components/navbar/index";
import Footer from "../components/footer";
import FeatureBrand from "../components/feature/FeatureBrand";
import HomePage from "../pages/homePage";
import AboutUs from "../pages/about";
import Faq from "../pages/faq";
import Projects from "../pages/projects";
import Services from "../pages/services";
import ServicesDetail from "../pages/services/services detail";
import Gallery from "../pages/gallery/Gallery";
import NotFound404 from "../pages/notFound404";
import Offering from "../pages/offering/Offering";
// import Feedback from '../components/feedback'
import NewsArtical from "../pages/newsartical/NewsArtical";
import ContactUs from "../pages/contactUs";
import Blogs from "../pages/blogs";
import Team from "../pages/team";
import ProjectDetail from "../pages/projectDetail/ProjectDetail";
import ServiceFirstBlock from "../components/servicefirst/ServiceFirstBlock";
import Question from "../components/questions";
import Sher from "../components/sher";
import Blog1 from "../components/blog1";
import Blog2 from "../components/blog2";
import Blog3 from "../components/blog3";
import Blog4 from "../components/blog4";
import Blog5 from "../components/blog5";
import Blog6 from "../components/blog6";
import Blog7 from "../components/blog7";
import Blog8 from "../components/blog8";
import Blog9 from "../components/blog9";
import AIBlogs from "../pages/aiBlogs";
import AiBlog1 from "../components/aiBlog1";
import AiBlog2 from "../components/aiBlog2";
import AiBlog3 from "../components/aiBlog3";
import AiBlog4 from "../components/aiBlog4";
import AiBlog5 from "../components/aiBlog5";
import AiBlog6 from "../components/aiBlog6";
import DataAnalyticsBlogs from "../pages/dataAnalyticsBlogs";
import DataBlog1 from "../components/dataBlog1";
import DataBlog2 from "../components/dataBlog2";
import BlockchainBlogs from "../components/blockchainBlogs";
import DataBlog3 from "../components/dataBlog3";
import DataBlog4 from "../components/dataBlog4";
import DataBlog5 from "../components/dataBlog5";
import DataBlog6 from "../components/dataBlog6";
import DataBlog7 from "../components/dataBlog7";
import MobileAppDevelopmentBlogs from "../pages/mobileAppDevelopmentBlogs";
import MobileBlog1 from "../components/mobileBlog1";
import MobileBlog2 from "../components/mobileBlog2";
import MobileBlog3 from "../components/mobileBlog3";
import MobileBlog4 from "../components/mobileBlog4";

function Routing() {
  return (
    <Router>
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-F7SRVJ5W60"
        ></script>
        <script>
          {`
                        window.dataLayer = window.dataLayer || [];
                        function gtag() { dataLayer.push(arguments); }
                        gtag('js', new Date());
                        gtag('config', 'G-F7SRVJ5W60');
                    `}
        </script>
      </Helmet>
      {/* <TopSlip/> */}
      <Navbar />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="about-us" element={<AboutUs />} />
        <Route path="faqs" element={<Faq />} />
        <Route path="projects" element={<Projects />} />
        <Route path="mkaits-team" element={<Team />} />
        <Route path="services" element={<Services />} />
        <Route path="services">
          <Route path=":serviceID" element={<ServicesDetail />} />
        </Route>
        <Route path="contact-us" element={<ContactUs />} />
        <Route path="*" element={<NotFound404 />} />
        <Route path="gallery" element={<Gallery />} />
        <Route path="offering" element={<Offering />} />
        <Route path="newsartical" element={<NewsArtical />} />
        <Route path="blogs" element={<Blogs />} />
        <Route path="blogs/blockchain" element={<BlockchainBlogs />} />
        <Route path="blogs/artificial-intelligence" element={<AIBlogs />} />
        <Route path="blogs/data-analytics" element={<DataAnalyticsBlogs />} />
        <Route path="blogs/mobile-app-development" element={<MobileAppDevelopmentBlogs />} />
        <Route path="projectdetail" element={<ProjectDetail />} />
        <Route path="servicefirstblock" element={<ServiceFirstBlock />} />
        <Route path="question" element={<Question />} />
        <Route path="sher" element={<Sher />} />
        <Route
          path="blogs/blockchain/blockchain-the-unsung-hero-beyond-cryptocurrencies"
          element={<Blog1 />}
        />
        <Route
          path="blogs/blockchain/blockchain-unleashed-a-new-era-of-innovation"
          element={<Blog2 />}
        />
        <Route
          path="blogs/blockchain/one-layer-wonder-the-marvels-of-1-layer-blockchain"
          element={<Blog3 />}
        />
        <Route
          path="blogs/blockchain/double-the-fun-with-2-layer-blockchain-a-revolutionary-approach-to-decentralization"
          element={<Blog4 />}
        />
        <Route path="blogs/blockchain/smart-contracts" element={<Blog5 />} />
        <Route
          path="blogs/blockchain/decentralized-autonomous-organization"
          element={<Blog6 />}
        />
        <Route
          path="blogs/blockchain/transformative-potential-of-decentralized-exchanges"
          element={<Blog7 />}
        />
        <Route
          path="blogs/blockchain/defi-reshaping-the-financial-landscape"
          element={<Blog8 />}
        />
        <Route
          path="blogs/blockchain/exploring-the-world-of-dapps-the-future-of-decentralized-applications"
          element={<Blog9 />}
        />
        <Route
          path="/blogs/artificial-intelligence/what-is-coming-in-the-world-of-ai-in-2024"
          element={<AiBlog1 />}
        />
        <Route
          path="/blogs/artificial-intelligence/how-to-begin-learning-ai-skills"
          element={<AiBlog2 />}
        />
        <Route
          path="/blogs/artificial-intelligence/machine-learning-vs-deep-learning"
          element={<AiBlog3 />}
        />
        <Route
          path="/blogs/artificial-intelligence/elevate-your-customer-experience-with-ai-at-mkaits"
          element={<AiBlog4 />}
        />
        <Route
          path="/blogs/artificial-intelligence/ethical-considerations-of-ai"
          element={<AiBlog5 />}
        />
        <Route
          path="/blogs/artificial-intelligence/impact-of-ai-transformation-on-industries"
          element={<AiBlog6 />}
        />
        <Route
          path="/blogs/data-analytics/the-role-of-big-data-analytics-in-business-intelligence"
          element={<DataBlog1 />}
        />
        <Route
          path="/blogs/data-analytics/comparative-analysis-of-big-data-tools"
          element={<DataBlog2 />}
        />
        <Route
          path="/blogs/data-analytics/understanding-5-vs-of-big-data"
          element={<DataBlog3 />}
        />
        <Route
          path="/blogs/data-analytics/machine-learning-techniques-in-big-data-analytics"
          element={<DataBlog4 />}
        />
        <Route
          path="/blogs/data-analytics/big-data-in-healthcare-a-game-changer-for-patient-care-and-operational-efficiency"
          element={<DataBlog5 />}
        />
        <Route
          path="/blogs/data-analytics/impact-of-big-data-on-marketing-strategies"
          element={<DataBlog6 />}
        />
        <Route
          path="/blogs/data-analytics/the-future-of-big-data-analytics"
          element={<DataBlog7 />}
        />
        <Route
          path="/blogs/mobile-app-development/unlocking-success-why-does-your-business-needs-a-custom-mobile-app"
          element={<MobileBlog1 />}
        />
        <Route
          path="/blogs/mobile-app-development/mobile-app-development-services-we-offer-from-concept-to-launch"
          element={<MobileBlog2 />}
        />
        <Route
          path="/blogs/mobile-app-development/role-of-artificial-intelligence-in-mobile-apps"
          element={<MobileBlog3 />}
        />
        <Route
          path="/blogs/mobile-app-development/role-of-iot-in-mobile-app-development"
          element={<MobileBlog4 />}
        />

      </Routes>
      {/* <FeatureBrand/> */}
      <Footer />
    </Router>
  );
}

export default Routing;
