import React from 'react'
import Routing from './routes'
import "./App.less"
const App = () => {    
    return (
        <Routing />
      )
}

export default App
