import React from 'react';
import { Row, Col, Typography, Image, Collapse } from "antd";
import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll';
import ShareBlog from '../shareBlog';
import ShareMidButton from '../ShareMidButton';
import "./index.css";
import {
    Helmet
} from 'react-helmet';
import { BreadCrumb } from '../../ui-elements/index';

const { Title } = Typography;
const { Panel } = Collapse;

const DataBlog3 = () => {
    const title = "The 5 Vs of Big Data: Unpacking the Essentials | Mkaits";
    const description = "Discover the fundamental concepts of big data with our casual breakdown of the 5 Vs—Volume, Velocity, Variety, Veracity, and Value. Learn how these elements impact data analysis and decision-making.";
    const image = "https://www.mkaits.com/assets/datablog3cover.jpg";
    const url = "https://www.mkaits.com/blogs/data-analytics/understanding-5-vs-of-big-data";
    // Set up scroll behavior on component mount and clean up on unmount
    React.useEffect(() => {
        Events.scrollEvent.register('begin', function () {
            console.log("begin", arguments);
        });

        Events.scrollEvent.register('end', function () {
            console.log("end", arguments);
        });

        scrollSpy.update();

        return () => {
            Events.scrollEvent.remove('begin');
            Events.scrollEvent.remove('end');
        };
    }, []);

    return (
        <>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
                <meta name="robots" content="index, follow" />
                <meta name="author" content="Mkaits Technology" />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
                <meta property="og:image" content={image} />
                <meta property="og:url" content={url} />
                <meta property="og:type" content="website" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={title} />
                <meta name="twitter:description" content={description} />
                <meta name="twitter:image" content={image} />
            </Helmet>
            <BreadCrumb title='Blogs' description='Your Business Partner' pageName="5 V's of Big Data" breadcrumb={[{ to: '/blogs/data-analytics', name: 'Blogs' }]} />
            <div className="py-5 px-lg-5 px-md-5 px-sm-0">
                <div className=''>
                    <ShareBlog url={"/blogs/data-analytics/understanding-5-vs-of-big-data"} />
                </div>
                <Row>
                    <Col xs={0} sm={0} md={0} lg={6} className="toc-container px-3 my-3 h-50">
                        {/* Links for scrolling */}
                        <h2 className="text-black fw-bold">Table of Contents</h2>
                        <div className='px-2 mx-2 border-black'>
                            {/* <Link activeClass="activeTab" to="section1" spy={true} smooth={true} duration={50}>
                                A Glimpse into the Future with Mkaits
                            </Link>
                            <br />
                            <br /> */}
                            <Link activeClass="activeTab" to="section2" spy={true} smooth={true} duration={50}>
                                Volume
                            </Link>
                            <br />
                            <br />
                            <Link activeClass="activeTab" to="section3" spy={true} smooth={true} duration={50}>
                                Velocity
                            </Link>
                            <br />
                            <br />
                            <Link activeClass="activeTab" to="section4" spy={true} smooth={true} duration={50}>
                                Variety
                            </Link>
                            <br />
                            <br />
                            <Link activeClass="activeTab" to="section5" spy={true} smooth={true} duration={50}>
                                Veracity
                            </Link>
                            <br />
                            <br />
                            <Link activeClass="activeTab" to="section6" spy={true} smooth={true} duration={50}>
                                Value
                            </Link>
                            <br />
                            <br />
                            <Link activeClass="activeTab" to="section7" spy={true} smooth={true} duration={50}>
                                Conclusion
                            </Link>
                        </div>
                    </Col>
                    <Collapse accordion className="d-md-none w-100 mx-3 position-sticky z_i">
                        <Panel header="Table of Contents" key="1" className='py-2 ' >
                            <div className='d-flex flex-column gap-3 border-black mx-3 px-3'>
                                {/* <Link activeClass="activeTab" to="section1" spy={true} smooth={true} duration={50}>
                                    A Glimpse into the Future with Mkaits
                                </Link> */}
                                <Link activeClass="activeTab" to="section2" spy={true} smooth={true} duration={50}>
                                    Volume
                                </Link>
                                <Link activeClass="activeTab" to="section3" spy={true} smooth={true} duration={50}>
                                    Velocity
                                </Link>
                                <Link activeClass="activeTab" to="section4" spy={true} smooth={true} duration={50}>
                                    Variety
                                </Link>
                                <Link activeClass="activeTab" to="section5" spy={true} smooth={true} duration={50}>
                                    Veracity
                                </Link>
                                <Link activeClass="activeTab" to="section6" spy={true} smooth={true} duration={50}>
                                    Value
                                </Link>
                                <Link activeClass="activeTab" to="section7" spy={true} smooth={true} duration={50}>
                                    Conclusion
                                </Link>
                            </div>

                        </Panel>
                    </Collapse>
                    <Col xs={24} sm={24} md={24} lg={14} className="content px-3 my-3">
                        <Title className='text-black mb-1 fs-1'>
                            Understanding the 5 Vs of Big Data: A Casual Dive into Data Dynamics
                        </Title>
                        <br />
                        <p>Hey there, data enthusiasts! Today, we’re diving into the fascinating realm of Big Data. If you’ve ever heard the term and scratched your head wondering what all the fuss is about, fear not! We're breaking it down into bite-sized pieces, and the best way to do that is by exploring the famous 5 Vs of Big Data. So, let’s jump right in!</p>
                        <br />
                        <Element name="section1" className="element">
                        </Element>
                        <Element name="section2" className="element">
                            <Title className='text-black mb-1 fs-4'>
                                1. Volume
                            </Title>
                            <br />
                            <p>Let’s start with the big kahuna—Volume! When we talk about volume, we’re referring to the sheer amount of data generated every second. We’re living in a world where every click, tweet, heart, and like contributes to an ocean of information. Think about it: social media, IoT devices, online transactions, and more—these generate zettabytes (that’s a trillion gigabytes, people!) of data. Companies that can harness this wave of data have the potential to drive significant insights and make data-driven decisions that can skyrocket their productivity.</p>
                            <br />
                        </Element>
                        <div className='custom-width-sm custom-width-md-lg m-auto'>
                            <Image src="/assets/datablog3a.jpg" alt="Data Analytics" className='img-fluid w-100' preview={false} />
                        </div>
                        <br />
                        <Element name="section3" className="element">
                            <Title className='text-black mb-1 fs-4'>
                                2. Velocity
                            </Title>
                            <br />
                            <p>Next up is Velocity. This one’s all about the speed at which data is generated and processed. In today’s fast-paced world, data isn’t just building up—it’s streaming in real-time! Whether it's live social media feeds or real-time monitoring of IoT devices, the ability to capture and analyze data quickly can give businesses a competitive edge. Think about how quickly trends can shift on social platforms; being able to analyze data at such a rapid pace can help brands stay relevant and responsive.</p>
                            <br />
                        </Element>
                        <div className='custom-width-sm custom-width-md-lg m-auto'>
                            <Image src="/assets/datablog3b.jpg" alt="Data Analytics" className='img-fluid w-100' preview={false} />
                        </div>
                        <br />
                        <Element name="section4" className="element">
                            <Title className='text-black mb-1 fs-4'>
                                3. Variety
                            </Title>
                            <br />
                            <p>And then we have Variety! Data doesn't just come in one flavor; it’s like a buffet of information! You’ve got structured data (think rows and columns in databases) alongside unstructured data (like emails, videos, and social media posts). This diversity in data types means that businesses must be equipped to handle various formats and sources. Understanding the different varieties can help in enriching data analysis and uncovering insights that can inform strategic decisions.</p>
                            <br />
                        </Element>
                        <div className='custom-width-sm custom-width-md-lg m-auto'>
                            <Image src="/assets/datablog3c.jpg" alt="Data Analytics" className='img-fluid w-100' preview={false} />
                        </div>
                        <br />
                        <Element name="section5" className="element">
                            <Title className='text-black mb-1 fs-4'>
                                4. Veracity
                            </Title>
                            <br />
                            <p>Coming in hot at number four is Veracity, which is all about the trustworthiness of the data. With so much information floating around, not all of it is accurate or reliable. For companies, ensuring the quality and accuracy of their data is crucial; after all, bad data can lead to bad decisions! It’s about distinguishing between “noise” and the valuable signals that can lead to real insights. Companies often employ data cleansing techniques and validation processes to enhance veracity and ensure they’re working with the best data available.</p>
                            <br />
                        </Element>
                        <div className='custom-width-sm custom-width-md-lg m-auto'>
                            <Image src="/assets/datablog3d.jpg" alt="Data Analytics" className='img-fluid w-100' preview={false} />
                        </div>
                        <br />
                        <Element name="section6" className="element">
                            <Title className='text-black mb-1 fs-4'>
                                5. Value
                            </Title>
                            <br />
                            <p>Last but not least, we have Value! This one ties it all together. All the data in the world means nothing if you can’t extract value from it. The magic happens when businesses use the insights gathered from voluminous, rapidly processed, varied, and trustworthy data to create strategies that drive growth, enhance customer experiences, and innovate. The goal is to turn raw data into actionable insights that provide a solid return on investment (ROI) and help companies thrive in their respective markets.</p>
                            <br />
                        </Element>
                        <div className='custom-width-sm custom-width-md-lg m-auto'>
                            <Image src="/assets/datablog3e.jpg" alt="Data Analytics" className='img-fluid w-100' preview={false} />
                        </div>
                        <br />
                        <Element name="section7" className="element">
                            <br />
                            <p className='bg_grey p-lg-5 p-3 rounded-3 fst_italic'> <span className='fs-1'>‟
                                <br />
                                <span className='fw-bold fs-3'>Wrapping It Up</span> </span><br />
                                So there you have it—the 5 Vs of Big Data: Volume, Velocity, Variety, Veracity, and Value. Each of these components plays a vital role in how businesses understand and leverage data. In an increasingly data-driven world, grasping these concepts isn’t just a nice-to-know; it's essential for the success of any organization.
                            </p>
                            <br />
                            <p>As we continue to witness the evolution of Big Data, it’s exciting to think about what the future holds. Who knows—maybe one day, we’ll be discussing 10 Vs! Until then, keep your data game strong, and happy analyzing!</p>
                            <br />
                            <p>Catch you later!</p>
                        </Element>
                    </Col>
                </Row>
                <div className='d-flex justify-content-center mt-3'>
                    <ShareMidButton url={"/blogs/data-analytics/understanding-5-vs-of-big-data"} />
                </div>
            </div>
        </>
    );
}

export default DataBlog3;
