import React, { useState } from 'react'
import { Col, Row, Image, Space, Typography } from 'antd'
import { SectionTop } from '../../ui-elements';
import './index.css';
import Dataindustry from './Dataindustry';
const { Title } = Typography

function Industrywe() {

    const [industrydata, setIndustryData] = useState(Dataindustry)
    const [selectindustry, setSelectIndustry] = useState(0)
    return (
        <div>
            <SectionTop title='IT services customized for your industry...' description='Industries we’re serving' />
            <Row className='industry-cover' style={{ backgroundImage: "linear-gradient(rgba(0,0,0,0.9),rgba(0,0,0,0.9)), url(/assets/industry-bg.jpg)" }}>
                <Col lg={{ span: 12 }} xs={{ span: 24 }} className='flex-col-center-end mb-5'>
                </Col>
                {/* <Col lg={{span:12}} xs={{span:0}} className='mb-5'>
                <Image src={"/assets/business-from-two-img.jpg"} alt="Mkaits" style={{maxHeight:'calc(100% - 20px)',maxWidth:'100%'}} preview={false}/>
            </Col> */}
                <Col span={24} className='justify-center pb-5'>
                    <Row gutter={[64, 64]} className='py-5' style={{ width: '93%' }}>
                        {
                            industrydata[selectindustry]?.listItem?.map((industry, i) =>
                                <Col lg={{ span: 6 }} md={{ span: 12 }} xs={{ span: 24 }} key={i}>
                                    <div className='industries rounded'>
                                        <div className='one animation brand-bg'></div>
                                        <div className='two animation brand-bg'></div>
                                        <div className='three flex-col-center py-5 animation'>
                                            <img src={'/assets/icons/' + industry?.iconBrand} width='90px' className='iconBrand animation mb-3' />
                                            <img src={'/assets/icons/' + industry?.iconWhite} width='90px' className='iconWhite animation mb-3' />
                                            <Title level={4} className='text-white'>{industry?.title}</Title>
                                        </div>
                                    </div>
                                </Col>
                            )
                        }
                    </Row>
                </Col>
            </Row>
        </div>
    )
}

export default Industrywe