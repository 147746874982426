import React, { useState } from 'react'
import './offer.css';
import { Row,Col,Image } from 'antd';
import Offerdata from './Offerdata.js'

function Offering() {

    const [offerdata, setOfferData] = useState(Offerdata)
  return (
    <div>
        <Row gutter={[32,32]} align="center" className="mb-5">
            <Col xl={24} lg={24} md={24} sm={24} xs={24} className='text-center'>
            <section className="services-two" style={{backgroundImage: "url(assets/service-bg-1-1.png)"}}>
            <div className="service-two__shape-1"></div>
            <div className="service-two__shape-2"></div>
            <div className="container">
                <div className="section-title text-center">
                    <div className="section-title__tagline-box">
                        <span className="section-title__tagline">What we’re offering</span>
                        <div className="section-title-shape">
                            <Image src="assets/section-title-shape.png" alt="" />
                        </div>
                    </div>
                    <h2 className="section-title__title">Services we’re providing <br /> to our customers</h2>
                </div>
                <Row gutter={[16,24]} align="center">
                    {
                        offerdata?.map((offer,fr)=>
                        <Col xl={6} lg={6} md={8} sm={12} xs={12} key={fr} className="wow fadeInUp animated" data-wow-delay="100ms" style={{visibility: 'visible', animationDelay: '100ms', animationName: 'fadeInUp'}}>
                            <div className="services-two__single">
                                <div className="services-two__icon">
                                    <span className="icon-coding">{offer.icon}</span>
                                </div>
                                <h3 className="services-two__title">
                                    <a href="/">
                                        {offer.title}
                                    </a>
                                </h3>
                                <p className="services-two__text">{offer.description}</p>
                                <div className="services-two__btn-box">
                                    <a href="product-development.html" className="thm-btn services-two__btn">{offer.btntext}</a>
                                </div>
                            </div>
                        </Col>
                        )
                    }
                </Row>
            </div>
        </section>
            </Col>
        </Row>
    </div>
  )
}

export default Offering