import { Link } from "react-router-dom";
import { FacebookFilled, LinkedinFilled, TwitterSquareFilled } from '@ant-design/icons';
import { Typography, Row, Col, Space, Divider, Breadcrumb, Menu } from 'antd';
import './footer.css';

const { Text } = Typography;

const Footer = () => {
    return (
        <div className='footer py-5' style={{ background: '#222528' }}>
            <div style={{ width: '85%' }} className='justify-center'>
                {/* Breadcrumb navigation */}
                <Breadcrumb>
                    <Breadcrumb.Item className='footer-text footer-text-hover'>
                        <Link to='/' className="text-white">
                            Home
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item className="text-white" style={{ cursor: 'pointer' }}>
                        <Link to='/about-us' className="text-white">Company</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item className="text-white" style={{ cursor: 'pointer' }}>
                        <Link to='/services' className="text-white">Services</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item className='footer-text footer-text-hover'>
                        <Link to='/blogs' className="text-white">
                            Blogs
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item className='footer-text footer-text-hover'>
                        <Link to='/contact-us' className="text-white">
                            Contact us
                        </Link>
                    </Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <Divider style={{ borderColor: 'rgba(255, 255, 255, 0.1)' }} className='my-5' />
            <Row gutter={[64, 32]} style={{ width: '90%' }}>
                <Col lg={{ span: 5 }} md={{ span: 12 }} sm={{ span: 24 }} className='align-center' style={{ borderRight: '1px solid rgba(255, 255, 255, 0.1)' }}>
                    <Space>
                        <FacebookFilled
                            className='footer-text footer-text-hover icons text-white'
                            onClick={() => { window.location.href = 'https://www.facebook.com/mughira.ahmad/' }}
                        />
                        <LinkedinFilled
                            className='footer-text footer-text-hover icons text-white'
                            onClick={() => { window.location.href = 'https://www.linkedin.com/in/mughira-ahmad-19722b1aa/' }}
                        />
                        <TwitterSquareFilled
                            className='footer-text footer-text-hover icons text-white'
                            onClick={() => { window.location.href = 'https://twitter.com/MMkaits' }}
                        />
                    </Space>
                </Col>
                <Col lg={{ span: 7 }} md={{ span: 12 }} sm={{ span: 24 }} className='align-center' style={{ borderRight: '1px solid rgba(255, 255, 255, 0.1)' }}>
                    <Text strong className='footer-text text-white'>
                        Mkaits Technologies innovates businesses with effective and adaptable digital solutions that satisfy today’s competition and liberate the opportunities of the future.
                    </Text>
                </Col>
                <Col lg={{ span: 7 }} md={{ span: 12 }} sm={{ span: 24 }} className='align-center' style={{ borderRight: '1px solid rgba(255, 255, 255, 0.1)' }}>
                    <Space direction='vertical'>
                        <Space className='footer-text'>
                            <Text strong className="text-white">Email: </Text>
                            <Text className=''>
                                <a href="mailto:admin@mkaits.com" className="footer-text footer-text-hover text-white">admin@mkaits.com</a>
                            </Text>
                        </Space>
                        <Space className='footer-text text-white'>
                            <Text strong className="text-white">Tel: </Text>
                            <Text className=' text-white'>
                                <a href="https://api.whatsapp.com/send?phone=923026469012" target="_blank" rel="noopener noreferrer" className="footer-text footer-text-hover text-white">
                                    +92 302 6469012
                                </a>
                            </Text>
                        </Space>
                        <Space className='footer-text' style={{ alignItems: 'flex-start' }}>
                            <Text strong className="text-white">Address: </Text>
                            <Text className='footer-text footer-text-hover text-white'>Office No.2 Ovais Co, Islamabad, Pakistan</Text>
                        </Space>
                    </Space>
                </Col>
                <Col lg={{ span: 5 }} md={{ span: 12 }} sm={{ span: 24 }} className='align-center'>
                    <Text strong className='footer-text text-white'>© 2022. Mkaits Technologies. All rights reserved.</Text>
                </Col>
            </Row>
        </div>
    );
}

export default Footer;
