import React, { useState } from 'react'
import { DataVideo } from './DataVideo';
import Slider from "react-slick";
import { Button, Typography } from 'antd';
import './index.css'
const {Title, Text}= Typography
const Videoslider = () => {
    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 2000,
        cssEase: "linear",
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 650,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                initialSlide: 0
              }
            },
            {
            breakpoint: 500,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                initialSlide: 0
            }
            }
          ]
      };
  return (
    <div style={{overflowX:'hidden'}}>
        <Slider {...settings}>
            {
                DataVideo?.map((dvideo, dv)=>{
                    return (
                        <div className='video-box'>
                            <video 
                                key={dv}
                                className='w-100 my-0'
                                autoplay="autoplay" 
                                loop="loop" 
                                muted="muted" >
                                <source 
                                    src={'assets/'+dvideo?.video} 
                                    type="video/mp4" />
                            </video>
                            <div className='two animation'>
                                <Title level={4} className='text-white two-text-1 animation my-0'>
                                    {
                                        dvideo?.title
                                    }
                                </Title>
                                <Title level={3} className='brand-color two-text-2 animation mt-2 '>
                                    {
                                        dvideo?.linkvideo
                                    }
                                </Title>
                            </div>
                        </div>
                    )
                })
            }
        </Slider>
    </div>
  )
}

export default Videoslider