import React, { useState, useRef } from 'react';
import './index.css';
import { Button } from 'antd';

const ShareBlog = ({url}) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [copyText, setCopyText] = useState('Copy');
    const inputRef = useRef(null);

    const handleCopy = () => {
        const inputElement = inputRef.current;
        inputElement.select();
        navigator.clipboard.writeText(inputElement.value).then(() => {
            setCopyText('Copied');
            setTimeout(() => setCopyText('Copy'), 4000);
        });
    };

    return (
        <>
            <Button className='shareBtn brand-border' onClick={() => setModalOpen(true)}>
                <img src="/assets/shareBlog.png" alt="Share" className='img-fluid' />
            </Button>
            {modalOpen && (
                <div className='modal-overlay' onClick={() => setModalOpen(false)}>
                    <div className='custom-modal' onClick={(e) => e.stopPropagation()}>
                        <h2 className='mb-4'>Share This Blog</h2>
                        <div className='d-flex justify-content-evenly align-items-center mb-4'>
                            <a href={`https://www.facebook.com/sharer/sharer.php?u=https://mkaits.com${url}`} target="_blank" rel="noreferrer">
                                <img src="/assets/fbBlog.png" alt="Facebook" className='img-fluid shareIcon' />
                            </a>
                            <a href={`https://telegram.org/`} target="_blank" rel="noreferrer">
                                <img src="/assets/telegramBlog.png" alt="Telegram" className='img-fluid shareIcon' />
                            </a>
                            <a href={`https://www.linkedin.com/shareArticle?mini=true&url=https://mkaits.com${url}`} target="_blank" rel="noreferrer">
                                <img src="/assets/linkedInBlog.png" alt="Linkedin" className='img-fluid shareIcon' />
                            </a>
                            <a href={`https://discord.com/login`} target="_blank" rel="noreferrer">
                                <img src="/assets/discordBlog.png" alt="Discord" className='img-fluid shareDiscord' />
                            </a>
                            <a href={`https://www.instagram.com/`} target="_blank" rel="noreferrer">
                                <img src="/assets/instaBlog.png" alt="Instagram" className='img-fluid shareDiscord' />
                            </a>
                        </div>
                        <div className='d-flex flex-column justify-content-between mt-4'>
                            <input
                                ref={inputRef}
                                type="url"
                                name="url"
                                placeholder='Phone number'
                                required
                                className='border-bottom-only w-100 fs-5'
                                value={`https://mkaits.com${url}`}
                                readOnly
                            />
                            <button onClick={handleCopy} className='brand-bg brand-border rounded-0 py-1 w-25 mx-auto'>{copyText}</button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default ShareBlog;
