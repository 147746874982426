import {Row, Col, Typography, Space, Image} from "antd"
import './index.css'
const {Title}=Typography
const ReplaIntro=()=>{
    return (
            <div className="justify-center light-bg intro py-5">
                <Row gutter={[64,32]} style={{width:'93%'}} className='my-5'>
                    <Col xl={{span:12}} lg={{span:24}} md={{span:24}} sm={{span:24}} xs={{span:24}}>
                        <div className="intro-left">
                            <Image src='/assets/intro.jpg' alt='repla' width={'100%'} preview={false}/>
                            <p className="py-4 px-3">
                                Mkaits is providing the best solution by our experts...
                            </p>
                        </div>
                    </Col>
                    <Col xl={{span:12}} lg={{span:24}} md={{span:24}} sm={{span:24}} xs={{span:24}} className='align-center'>
                        <Space direction='vertical' size={30}>
                                <Title level={2} className='brand-color my-0'>Company introduction</Title>
                            <div>
                                <Title level={4}>
                                    Mkatis - Innovative and Adaptable Digital Solutions for Modern Business
                                </Title>
                                <p>
                                    Mkaits - Innovative and Adaptable Digital Solutions for Modern Business Mkaits is a leading IT company that specializes in delivering top-quality services to clients across different industries. Our extensive experience in diverse sectors enables us to offer valuable insights and innovative solutions that guarantee customer satisfaction. At Mkaits we specialize in providing innovative and adaptable digital solutions that empower businesses to compete in today's fast-paced market and unlock new opportunities for growth.
                                </p>
                            </div>
                            <Row gutter={[12,24]}>
                                <Col sm={{span:12}} xs={{span:24}}>
                                    <Space>
                                        <img src='/assets/icons/checked.png' width={'20px'}/>
                                        <Title level={5} className='my-0'>Software Development</Title>
                                    </Space>
                                </Col>
                                <Col sm={{span:12}} xs={{span:24}}>
                                    <Space>
                                        <img src='/assets/icons/checked.png' width={'20px'}/>
                                        <Title level={5} className='my-0'>Website Development</Title>
                                    </Space>
                                </Col>
                                <Col sm={{span:12}} xs={{span:24}}> 
                                    <Space>
                                        <img src='/assets/icons/checked.png' width={'20px'}/>
                                        <Title level={5} className='my-0'>Mobile App Development</Title>
                                    </Space>
                                </Col>
                                <Col sm={{span:12}} xs={{span:24}}>
                                    <Space>
                                        <img src='/assets/icons/checked.png' width={'20px'}/>
                                        <Title level={5} className='my-0'>Digital Marketing</Title>
                                    </Space>
                                </Col>
                                <Col sm={{span:12}} xs={{span:24}}>
                                    <Space>
                                        <img src='/assets/icons/checked.png' width={'20px'}/>
                                        <Title level={5} className='my-0'>Game development</Title>
                                    </Space>
                                </Col>
                                <Col sm={{span:12}} xs={{span:24}}>
                                    <Space>
                                        <img src='/assets/icons/checked.png' width={'20px'}/>
                                        <Title level={5} className='my-0'>Artificial intelligence</Title>
                                    </Space>
                                </Col>
                                <Col sm={{span:12}} xs={{span:24}}>
                                    <Space>
                                        <img src='/assets/icons/checked.png' width={'20px'}/>
                                        <Title level={5} className='my-0'>Blockchains</Title>
                                    </Space>
                                </Col>
                                <Col sm={{span:12}} xs={{span:24}}>
                                    <Space>
                                        <img src='/assets/icons/checked.png' width={'20px'}/>
                                        <Title level={5} className='my-0'>UI/UX design</Title>
                                    </Space>
                                </Col>
                            </Row>
                            <Space className="align-center">
                                <div className='brand-border p-2' style={{borderRadius:'50%',borderWidth:'2px'}} >
                                    <Image 
                                        preview={false}
                                        src='/assets/images/team/ceo.jpg' 
                                        alt="Mkaits ceo Mughira Ahmed"
                                        style={{borderRadius:'50%'}} 
                                        width={70}
                                    />
                                </div>
                                <Space direction='vertical' size={1}>
                                    <Title level={4} className='my-0'>Our CEO</Title>
                                    <p className="my-0">Co founder of Mkaits</p>
                                </Space>
                            </Space>
                        </Space>
                    </Col>
                </Row>
            </div>
    )
}
export default ReplaIntro