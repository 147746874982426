import './index.css'
import { SectionTop } from '../../ui-elements'
import {Row, Col, Typography, Space, Image} from "antd"
import { FacebookFilled, LinkedinFilled, TwitterSquareFilled, InstagramFilled } from '@ant-design/icons'
const {Title}= Typography
const Profiles=()=>
    {
        const state=
            [
                {
                    name:'Faaiz Tariq',
                    position:'Director',
                    profilePicture:'director.jpg'
                },
                {
                    name:'Mughira Ahmed',
                    position:'CEO',
                    profilePicture:'ceo.jpg'
                },
                // {
                //     name:'Abdul Hanan',
                //     position:'Development Team Lead',
                //     profilePicture:'dev team lead.jpg'
                // },
            ]
        return(
            <div className='flex-col-center'>
                <div className='justify-center profile-bg'
                 style={{width:'100%',height:'350px',backgroundImage:`url(${'/assets/our-team-bg.jpg'})`}}
                 >
                    <div className='flex-col-center-center' style={{width:'97%'}}>
                        <Typography.Title level={2} className='brand-color my-0'>
                            Our Team
                        </Typography.Title>
                        <Typography.Title level={1} className='my-0 text-white text-center'>
                            Team of experienced professionals
                        </Typography.Title>
                    </div>
                </div>
                <div className='justify-center' style={{width:'97%',transform:'translateY(-100px)'}}>
                    <Row style={{width:'93%'}} gutter={[32,32]}>
                        <Col xl={{span:4}} lg={{span:4}} sm={{span:0}}>
                        </Col>
                        {
                            state?.map((data, d)=>
                                <Col key={'profile-'+d} xl={{span:8}} lg={{span:8}} sm={{span:12}}>
                                    <div className="profile animation secondary-bg">
                                            <img 
                                                src={'/assets/images/team/'+data?.profilePicture} 
                                                alt='Mkaits team'
                                                className='one animation' 
                                                width={'100%'} 
                                                height='auto'/>
                                            <div className='two animation center'>
                                                <Space size={15} className='profile-info' direction='vertical'>
                                                    <div className='flex-col-center'>
                                                    <Title level={3} className='text-white mb-1'>{data?.name}</Title>
                                                    <Title level={5} className='text-white mt-0'>{data?.position}</Title>
                                                    </div>
                                                    <Space className='text-white justify-center w-100'>
                                                        <a 
                                                            href='https://www.facebook.com/mughira.ahmad/' 
                                                            target="_blank" 
                                                            className='text-white'
                                                        >
                                                            <FacebookFilled style={{fontSize:'22px'}}/>
                                                        </a>
                                                        <a 
                                                            href='https://www.linkedin.com/in/mughira-ahmad-19722b1aa/' 
                                                            className='text-white'
                                                            target="_blank"
                                                        >
                                                            <LinkedinFilled style={{fontSize:'22px'}}/>
                                                        </a>
                                                        <a 
                                                            href='https://twitter.com/MMkaits' 
                                                            className='text-white'
                                                            target="_blank"
                                                        >
                                                            <TwitterSquareFilled style={{fontSize:'22px'}}/>
                                                        </a>
                                                    </Space>
                                                </Space>
                                            </div>
                                    </div>
                                </Col>
                                )
                        } 
                    </Row>
                </div>
            </div>
        )
    }
export default Profiles;