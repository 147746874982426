import React, { useState } from 'react';
import 'antd/dist/antd.css';
import { Button, Switch } from 'antd';

const Sher = () => {
  const [disabled, setDisabled] = useState([
    {
      item: 1,
      checked: false,
      checkedChildren:"Ali",
      unCheckedChildren:"Amina",
      select:"Amina",
  },
  {
      item: 2,
      checked: false,
      checkedChildren:"Ali",
      unCheckedChildren:"Amina",
      select:"Amina",
  },
  {
      item: 3,
      checked: false,
      checkedChildren:"Hussna",
      unCheckedChildren:"Hanan",
      select:"Hanan",
  },
  {
      item: 4,
      checked: false,
      checkedChildren:"Hoskey",
      unCheckedChildren:"Cricket",
      select:"Cricket",
  },
  {
      item: 5,
      checked: false,
      checkedChildren:"Female",
      unCheckedChildren:"Male",
      select:"Male",
  },
  ])

const handleChange=(checked, index)=>{
    disabled[index]={
        ...disabled[index],
        checked,
        select:checked?disabled[index]?.checkedChildren:disabled[index]?.unCheckedChildren,
    }
    setDisabled([...disabled])
}
console.log(disabled)
  return (
    <div>
      {
        disabled?.map((data, index)=>{
          return(
            <>
            <Switch 
             onChange={(checked)=>{handleChange(checked,index)}}
             checked={data?.checked}
             checkedChildren={data?.checkedChildren}
              unCheckedChildren={data?.unCheckedChildren}
              />
              <br/>
              </>
          )
        })
      }
    </div>
  );
};
export default Sher;