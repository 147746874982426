import React from 'react';
import { Row, Col, Typography, Image, Collapse } from "antd";
import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll';
import ShareBlog from '../shareBlog';
import ShareMidButton from '../ShareMidButton';
import "./index.css";
import {
    Helmet
} from 'react-helmet';
import { BreadCrumb } from '../../ui-elements/index';

const { Title } = Typography;
const { Panel } = Collapse;

const DataBlog4 = () => {
    const title = "Exploring Machine Learning Techniques for Big Data Analytics | Mkaits";
    const description = "Dive into the world of big data analytics! Discover the most common machine learning techniques, including clustering and classification algorithms, that drive insights from massive data sets.";
    const image = "https://www.mkaits.com/assets/datablog4cover.jpg";
    const url = "https://www.mkaits.com/blogs/data-analytics/machine-learning-techniques-in-big-data-analytics";
    // Set up scroll behavior on component mount and clean up on unmount
    React.useEffect(() => {
        Events.scrollEvent.register('begin', function () {
            console.log("begin", arguments);
        });

        Events.scrollEvent.register('end', function () {
            console.log("end", arguments);
        });

        scrollSpy.update();

        return () => {
            Events.scrollEvent.remove('begin');
            Events.scrollEvent.remove('end');
        };
    }, []);

    return (
        <>
            <Helmet>
                <title>{title}</title>
                <meta name="title" content={title} />
                <meta name="description" content={description} />

                <meta property="og:type" content="website" />
                <meta property="og:url" content={url} />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
                <meta property="og:image" content={image} />

                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content={url} />
                <meta property="twitter:title" content={title} />
                <meta property="twitter:description" content={description} />
                <meta property="twitter:image" content={image} />

            </Helmet>
            <BreadCrumb title='Blogs' description='Your Business Partner' pageName="ML Techniques Big Data" breadcrumb={[{ to: '/blogs/data-analytics', name: 'Blogs' }]} />
            <div className="py-5 px-lg-5 px-md-5 px-sm-0">
                <div className=''>
                    <ShareBlog url={"/blogs/data-analytics/machine-learning-techniques-in-big-data-analytics"} />
                </div>
                <Row>
                    <Col xs={0} sm={0} md={0} lg={6} className="toc-container px-3 my-3 h-50">
                        {/* Links for scrolling */}
                        <h2 className="text-black fw-bold">Table of Contents</h2>
                        <div className='px-2 mx-2 border-black'>
                            {/* <Link activeClass="activeTab" to="section1" spy={true} smooth={true} duration={50}>
                                A Glimpse into the Future with Mkaits
                            </Link>
                            <br />
                            <br /> */}
                            <Link activeClass="activeTab" to="section2" spy={true} smooth={true} duration={50}>
                                Clustering: Grouping Like a Pro
                            </Link>
                            <br />
                            <br />
                            <Link activeClass="activeTab" to="section3" spy={true} smooth={true} duration={50}>
                                Classification: Sorting It Out
                            </Link>
                            <br />
                            <br />
                            <Link activeClass="activeTab" to="section4" spy={true} smooth={true} duration={50}>
                                Why It Matters
                            </Link>
                            <br />
                            <br />
                            <Link activeClass="activeTab" to="section7" spy={true} smooth={true} duration={50}>
                                Conclusion
                            </Link>
                        </div>
                    </Col>
                    <Collapse accordion className="d-md-none w-100 mx-3 position-sticky z_i">
                        <Panel header="Table of Contents" key="1" className='py-2 ' >
                            <div className='d-flex flex-column gap-3 border-black mx-3 px-3'>
                                {/* <Link activeClass="activeTab" to="section1" spy={true} smooth={true} duration={50}>
                                    A Glimpse into the Future with Mkaits
                                </Link> */}
                                <Link activeClass="activeTab" to="section2" spy={true} smooth={true} duration={50}>
                                    Clustering: Grouping Like a Pro
                                </Link>
                                <Link activeClass="activeTab" to="section3" spy={true} smooth={true} duration={50}>
                                    Classification: Sorting It Out
                                </Link>
                                <Link activeClass="activeTab" to="section4" spy={true} smooth={true} duration={50}>
                                    Why It Matters
                                </Link>
                                <Link activeClass="activeTab" to="section7" spy={true} smooth={true} duration={50}>
                                    Conclusion
                                </Link>
                            </div>

                        </Panel>
                    </Collapse>
                    <Col xs={24} sm={24} md={24} lg={14} className="content px-3 my-3">
                        <Title className='text-black mb-1 fs-1'>
                            Machine Learning Techniques in Big Data Analytics
                        </Title>
                        <br />
                        <p>Hey there, data enthusiasts! Today, let’s dive into the exciting world of machine learning (link of machine learning vs….)and how it's making waves in big data analytics. With the sheer volume of data that businesses collect daily, harnessing insights from this treasure trove is crucial. And that's where machine learning comes in! Find out about  what is big data analytics.<a href="https://mkaits.com/blogs/data-analytics/the-role-of-big-data-analytics-in-business-intelligence" target='_blank' className='text-primary text-decoration-underline'>Big Data Analytics </a></p>
                        <br />
                        <Element name="section1" className="element">
                        </Element>
                        <div className='custom-width-sm custom-width-md-lg m-auto'>
                            <Image src="/assets/datablog4a.jpg" alt="Data Analytics" className='img-fluid w-100' preview={false} />
                        </div>
                        <br />
                        <Element name="section2" className="element">
                            <Title className='text-black mb-1 fs-4'>
                                Clustering: Grouping Like a Pro
                            </Title>
                            <br />
                            <p>Ever heard of clustering? It’s like throwing a big party and then trying to figure out which guests have the most in common. Clustering algorithms group data points into clusters, where items in the same group are more similar to each other than those in other groups. </p>
                            <br />
                            <p>One popular clustering technique is <span className='fw-bold fst-italic'>K-Means</span>. You specify how many clusters you want, and the algorithm does its magic, sorting the data into those clusters. This method is super handy for customer segmentation, where businesses can identify distinct groups of customers and tailor their marketing strategies accordingly.</p>
                            <br />
                        </Element>
                        <div className='custom-width-sm custom-width-md-lg m-auto'>
                            <Image src="/assets/datablog4b.jpg" alt="Data Analytics" className='img-fluid w-100' preview={false} />
                        </div>
                        <br />
                        <Element name="section3" className="element">
                            <Title className='text-black mb-1 fs-4'>
                                Classification: Sorting It Out
                            </Title>
                            <br />
                            <p>Next up is classification, the ultimate digital sorting hat!  It’s all about predicting which category new data points belongs to based on past observations. Picture this: you have emails, and you want to separate them into "spam" and "not spam." That’s classification in action!</p>
                            <br />
                            <p>Some well-known classification algorithms include <span className='fw-bold fst-italic'>Decision Trees</span> and <span className='fw-bold fst-italic'>Random Forests</span>. Decision Trees plot out decisions based on certain features and are easy to understand, while Random Forests are like a team of decision-making trees that work together for more accurate predictions.</p>
                            <br />
                        </Element>
                        <div className='custom-width-sm custom-width-md-lg m-auto'>
                            <Image src="/assets/datablog4c.jpg" alt="Data Analytics" className='img-fluid w-100' preview={false} />
                        </div>
                        <br />
                        <Element name="section4" className="element">
                            <Title className='text-black mb-1 fs-4'>
                                Why It Matters
                            </Title>
                            <br />
                            <p>So, why should you care about these methods? Well, with big data continuing to explode, mastering techniques like clustering and classification can help businesses unlock valuable insights. From predicting customer behavior to optimizing operations, the possibilities are endless!</p>
                            <br />
                        </Element>
                        <Element name="section7" className="element">
                            <br />
                            <p className='bg_grey p-lg-5 p-3 rounded-3 fst_italic'> <span className='fs-1'>‟
                                <br />
                                <span className='fw-bold fs-3'>Conclusion</span> </span><br />
                                In conclusion, machine learning isn’t just a buzzword; it’s a game-changer in the realm of big data analytics. Whether you’re grouping similar data or sorting items into categories, these techniques are the backbone of turning raw data into actionable knowledge.
                            </p>
                            <br />
                            <p>Happy analyzing, and remember—in the world of data, every bit counts! </p>
                        </Element>
                    </Col>
                </Row>
                <div className='d-flex justify-content-center mt-3'>
                    <ShareMidButton url={"/blogs/data-analytics/machine-learning-techniques-in-big-data-analytics"} />
                </div>
            </div>
        </>
    );
}

export default DataBlog4;
