import { PlusOutlined } from '@ant-design/icons';
const GalleryData = [
    {
        galleryImage: "gallery-page-1.jpg",
        icon: <PlusOutlined />
    },
    {
        galleryImage: "gallery-page-2.jpg",
        icon: <PlusOutlined />
    },
    {
        galleryImage: "gallery-page-3.jpg",
        icon: <PlusOutlined />
    },
    {
        galleryImage: "gallery-page-4.jpg",
        icon: <PlusOutlined />
    },
    {
        galleryImage: "gallery-page-5.jpg",
        icon: <PlusOutlined />
    },
    {
        galleryImage: "gallery-page-6.jpg",
        icon: <PlusOutlined />
    },
    {
        galleryImage: "gallery-page-7.jpg",
        icon: <PlusOutlined />
    },
    {
        galleryImage: "gallery-page-8.jpg",
        icon: <PlusOutlined />
    },
    {
        galleryImage: "gallery-page-9.jpg",
        icon: <PlusOutlined />
    }
]

export default GalleryData;