import React, { useState } from 'react'
import { Row, Col, Typography, Space } from 'antd'
import "./index.css"
import { useEffect } from 'react';
import ReplaButton from '../buttons';
import { Link } from 'react-router-dom';
const { Title } = Typography;
const HomeTopSection = () => {
    const state = [
        {
            bg: 'slider-bg-1.jpg',
            title: "Mkaits innovative solutions for your businesses",
            description: "We help you figure out what you existing and potential clients exactly want, and then equip you with the tool to provide whats's necessary"

        },
        {
            bg: 'slider-bg-2.jpg',
            title: 'Empowering Business Through Innovative Technology',
            description: "We help you figure out what you existing and potential clients exactly want, and then equip you with the tool to provide whats's necessary"
        },
        {
            bg: 'slider-bg-3.jpg',
            title: "Mkaits innovative solutions for your businesses",
            description: "We help you figure out what you existing and potential clients exactly want, and then equip you with the tool to provide whats's necessary"
        },
    ]
    const [currentState, setCurrentState] = useState()
    useEffect(() => {
        setCurrentState(0)
    }, [])
    console.log(currentState)
    useEffect(() => {
        let left = document.getElementsByClassName("slider-left")[0]
        let right = document.getElementsByClassName("slider-right")[0]
        let sliderBg = document.getElementsByClassName("slider-bg")[0]
        let one = document.getElementsByClassName("text-one")[0]
        let two = document.getElementsByClassName("text-two")[0]
        setTimeout(() => {
            left.setAttribute("class", 'slider-left slider-left-animation')
            right.setAttribute("class", 'slider-right slider-right-animation animations')
            sliderBg.setAttribute("class", 'slider-bg slider-bg-animation')
            one.setAttribute("class", 'text-one text-one-animation text-animation')
            two.setAttribute("class", 'text-two text-two-animation text-animation')
        }, 10)
    }, [currentState])
    const myfun = () => {
        let left = document.getElementsByClassName("slider-left")[0]
        let right = document.getElementsByClassName("slider-right")[0]
        let sliderBg = document.getElementsByClassName("slider-bg")[0]
        let one = document.getElementsByClassName("text-one")[0]
        let two = document.getElementsByClassName("text-two")[0]
        left.setAttribute("class", 'slider-left');
        right.setAttribute("class", 'slider-right');
        sliderBg.setAttribute("class", 'slider-bg');
        one.setAttribute("class", 'text-one')
        two.setAttribute("class", 'text-two')

    }
    const bg = { src: `${process.env.PUBLIC_URL + '/assets/slider-bg-1.jpg'}` }
    return (
        <div>
            <div className='home-slider'>
                <div className='slider-bg' style={{ backgroundImage: `linear-gradient(rgba(1,1,1,0.8),rgba(1,1,1,0.8)),url(/assets/${state[currentState]?.bg})` }}>
                    {/* <img src={'/assets/'+state[currentState]?.bg} style={{ filter: 'brightness(100%)'}}/> */}
                </div>
                <img src='/assets/slider-left.png' className='slider-left' />
                <img src='/assets/slider-right.png' className='slider-right' />
                <div className='slider-text'>
                    Mkaits
                </div>
                <div className='center' style={{ position: 'relative', width: '100%', height: '100%' }}>
                    <Row style={{ width: '93%', borderWidth: '4px' }}>
                        <Col lg={{ span: 16 }} md={{ span: 24 }}>
                            <Space size={25} direction='vertical justify-center'>
                                <div className='text-one'>
                                    <Title level={1} className='text-white home-top-section-title mt_top' style={{ textTransform: 'uppercase' }}>
                                        {
                                            state[currentState]?.title
                                        }
                                    </Title>
                                </div>
                                <div style={{ width: '300px' }} className='text-two'>
                                    <a href="https://calendly.com/mughiraaa" target='_blank' >
                                        <ReplaButton title='Book a Meeting' />
                                    </a>
                                </div>
                                <div style={{ width: '300px' }} className=''>
                                    <Link to={"/about-us"}>
                                        <ReplaButton title='Discover More About Mkaits' />
                                    </Link>
                                </div>
                            </Space>
                        </Col>
                        <Col lg={{ span: 8 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }} className='align-end-center'>
                            <Space size={12} direction='vertical' className='home-top-section-dots'>
                                <div className={(currentState === 0 ? 'brand-bg' : 'bg-white') + ' slider-circle animation'} onClick={() => { myfun(); setCurrentState(0) }}></div>
                                <div className={(currentState === 1 ? 'brand-bg' : 'bg-white') + ' slider-circle animation'} onClick={() => { myfun(); setCurrentState(1) }}></div>
                                <div className={(currentState === 2 ? 'brand-bg' : 'bg-white') + ' slider-circle animation'} onClick={() => { myfun(); setCurrentState(2) }}></div>
                            </Space>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    )
}

export default HomeTopSection;