const Dataindustry = [
    {
        firstimage: "business-from-two-img.jpg",
        title:"IT services customized for your industry",
        subtitle: "Industries we’re serving",
        shapicon:"section-title-shape.png",
        listItem:[
            {
                iconBrand:"mobile-banking-brand.png",
                iconWhite:"mobile-banking-white.png",
                title: "Banking"
            },
            {
                iconBrand:"healthcare-brand.png",
                iconWhite:"healthcare-white.png",
                title: "Healthcare"
            },
            {
                iconBrand:"education-brand.png",
                iconWhite:"education-white.png",
                title: "Higher education"
            },
            {
                iconBrand:"manufacturing-brand.png",
                iconWhite:"manufacturing-white.png",
                title: "Manufacturing"
            },
        ]
    }
]

export default Dataindustry