const data=[
    {
        title:'Software development',
        paras:[
            {
                title:"Software development",
                description:"Mkaits develops innovative and intuitive Software that ensure product quality and customer satisfaction, all resulting in a high conversion rate. As an experienced website development company, we design and develop functional, well-designed, and interactive websites. With expertise advanced tools, frameworks, APIs, and programming languages, our developers cater to diverse many industries.<br>We adopt a strategic project mapping technique to represent your project requirements, priorities, and potential challenges, streamlining the existing and future strategies. Our technical design is centered on collecting project requirements. With the acquired data, we design the prototype and perform its user testing. At development stage, our developers perform coding and programming on the approved design. Our developers deploy the modified and optimized websites, making your websites extensively accessible. To ensure your website functional as intended, our team performs considerable tracking and maintenance.<br>From single-page applications to complex business enterprise networks, Mkaits develops functional, reliable, high quality custom websites exactly the way you envision them. Along with your target market and intended functionality in mind, we develop fully-fledged web sites which can be interactive and intuitive."
            },
            {
                title:'Field and Technologies',
                description:" We develop Software for different industries like Education, Manufacturing, Healthcare, Banking and Finance, E-commerce, MLM, Games and Entertainment. Mkaits has a team of professional developers who are skilled to create websites with expertise in a lot of languages and technologies like React, Angular, Bootstrap, PHP, Laravel, Node Js(asp.net, asp.net mvc).<br>Our highly expert team develops websites in React. js for developing their user interfaces because it helps to speed up the development process and creates applications that work fast. Angular incorporates complex functionalities like progressive, single-page, and native web apps. Through Bootstrap we create responsive websites quickly. Mkaits has a remarkable professional team to develop websites in Laravel which is flexible and rich in creating unique websites.",
            }
        ],
        slider:[
            {
                title:"Innovative Technologies",
                description:"Finding innovative solutions and technologies to improve the processes and value of a website often uses qualifying research activities that can reap the benefits."
            },
            {
                title:"Research",
                description:"Website developers perform research used for implementing a new system or process. "
            },
            {
                title:'R&D Solutions',
                description:"R&D Solutions has proven track record developing custom software from scratch for eenterprises."
            }
        ],
        benefits:{
            title:"Our Significance",
            description:"",
            list:[
                "Mkaits provides efficient websites which are functional and intrusive.",
                "Mkaits gives positive impression to your potential customers.",
                "Mkaits develops websites with quality programming code using innovative tools and techniques."
            ]
        },
        accordin:[
            {
                title:'How do Mkaits give your company a competitive advantage?',
                description:"We harness the most promising web technologies and tech stacks to give you a competitive advantage.  Mkaits works on React, Angular, Bootstrap, PHP, Laravel, Node Js(asp.net, asp.net mvc)."
            },
            {
                title:'How do you create a a standardized website quality assurance process?',
                description:"To create a standardized website quality assurance process Mkaits undergoes various testing processes under design and development stages. We ensure that our customers get a functional website."
            }
        ]
    },
    {
        title:'Website development',
        paras:[
            {
                title:"Website development",
                description:"Mkaits develops innovative and intuitive websites that ensure product quality and customer satisfaction, all resulting in a high conversion rate. As an experienced website development company, we design and develop functional, well-designed, and interactive websites. With expertise advanced tools, frameworks, APIs, and programming languages, our developers cater to diverse many industries.<br>We adopt a strategic project mapping technique to represent your project requirements, priorities, and potential challenges, streamlining the existing and future strategies. Our technical design is centered on collecting project requirements. With the acquired data, we design the prototype and perform its user testing. At development stage, our developers perform coding and programming on the approved design. Our developers deploy the modified and optimized websites, making your websites extensively accessible. To ensure your website functional as intended, our team performs considerable tracking and maintenance.<br>From single-page applications to complex business enterprise networks, Mkaits develops functional, reliable, high quality custom websites exactly the way you envision them. Along with your target market and intended functionality in mind, we develop fully-fledged web sites which can be interactive and intuitive."
            },
            {
                title:'Field and Technologies',
                description:" We develop websites for different industries like Education, Manufacturing, Healthcare, Banking and Finance, E-commerce, MLM, Games and Entertainment. Mkaits has a team of professional developers who are skilled to create websites with expertise in a lot of languages and technologies like React, Angular, Bootstrap, PHP, Laravel, Node Js(asp.net, asp.net mvc).<br>Our highly expert team develops websites in React. js for developing their user interfaces because it helps to speed up the development process and creates applications that work fast. Angular incorporates complex functionalities like progressive, single-page, and native web apps. Through Bootstrap we create responsive websites quickly. Mkaits has a remarkable professional team to develop websites in Laravel which is flexible and rich in creating unique websites.",
            }
        ],
        slider:[
            {
                title:"Innovative Technologies",
                description:"Finding innovative solutions and technologies to improve the processes and value of a website often uses qualifying research activities that can reap the benefits."
            },
            {
                title:"Research",
                description:"Website developers perform research used for implementing a new system or process. "
            },
            {
                title:'R&D Solutions',
                description:"R&D Solutions has proven track record developing custom software from scratch for eenterprises."
            }
        ],
        benefits:{
            title:"Our Significance",
            description:"",
            list:[
                "Mkaits provides efficient websites which are functional and intrusive.",
                "Mkaits gives positive impression to your potential customers.",
                "Mkaits develops websites with quality programming code using innovative tools and techniques."
            ]
        },
        accordin:[
            {
                title:'How do Mkaits give your company a competitive advantage?',
                description:"We harness the most promising web technologies and tech stacks to give you a competitive advantage.  Mkaits works on React, Angular, Bootstrap, PHP, Laravel, Node Js(asp.net, asp.net mvc)."
            },
            {
                title:'How do you create a a standardized website quality assurance process?',
                description:"To create a standardized website quality assurance process Mkaits undergoes various testing processes under design and development stages. We ensure that our customers get a functional website."
            }
        ]
    },
    {
        title:'App Development',
        paras:[
            {
                title:'Mobile App Development',
                description:"Mkaits develops the perfect mobile app with an innovative mixture of professional team, advanced tools, and formidable information of the crowded mobile landscape. Mkaits provides mobile app development services which includes building iOS & Android and applications that efficiently complement web solutions. We have developed 1000+ mobile apps. Mkaits ensures mobile app success by delivering professional UI and secure app code."
            },
            {
                title:"Field and Technologies",
                description:"We develop mobile applications for different industries like Education, Manufacturing, Healthcare, Banking and Finance, E-commerce, MLM, Games and Entertainment. Mkaits has a team of professional developers who are skilled on mobile apps with expertise in a lot of languages and technologies like React Native and Flutter. We always focus on our client’s sales and profits. Our highly expert team develops mobile apps in React Native which gives more revenue to your business in less time. World’s most famous apps like Facebook and Instagram, Skype, Pinterest use React Native as their mobile app development framework. React Native enhance the business productivity in less time. In short React Native gives a significant boost to your business. Mkaits has a remarkable professional team to build mobile apps in Flutter also which allows you to launch apps faster as in this digital world there comes a lot of market competition, so brands and businesses must maintain their pace to keep a reputed position in the market.  Flutter plays a significant role in your business growth."
            },
            {
                title:'Significance of mobile apps developed by Mkaits',
                description:"The question arises why do you need a mobile app for your business? Mobile applications are really very important as around 6.37 billion people around the world use smartphones now, and 90% of the time spent on them is on mobile apps. The marketplace for mobile apps is growing and getting larger day by day.  There were about 230 billion mobile app downloads last year. Mobile apps are more and more turning into clients’ preferred manner to interact with others. Therefore, maximum companies/brands are demanding for mobile apps to lure maximum customers. Mobile apps enable customers to customize statistics including messages, pointers, and offers in step with the person.<br>Mkaits develops mobile application that accumulates and examine user facts and will let you acquire insights on user engagement. We develop mobile application which gives your business a large edge. Mobile apps enhance the user experience via third party tool integrations. We develop successful mobile application which focus not only appropriate design and friction-free UX, but also a keen understanding of the fierce competition within your app store category."
            }
        ],
        slider:[
            {
                title:'Lead Generation',
                description:"We innovate and execute a lead generation strategy using SEO, social ads, and SEM to help engage your customers to your brand."
            },
            {
                title:'Lead Capture',
                description:'We optimize your digital presence to drive conversion leads using effective websites, landing pages and forms.'
            },
            {
                title:'Lead Nurturing',
                description:'We provide insightful content to drive your customers closer to your brand and persuade them to buy.'
            }
        ],
        benefits:{
            title:"Our Significance",
            description:"choose the best one’s",
            list:[
                "Mkaits provides a great user experience to your mobile app.",
                "Mkaits targets audience in a cost effective and measurable way.",
                "We positively impact digital business with our innovative tools and techniques."
            ]
        },
        accordin:[
            {
                title:'How do Mkaits give your company a competitive advantage?',
                description:"We harness the most promising mobile apps to give you a competitive advantage. Mkaits has a team of professional developers with expertise in a lot of languages and technologies like React Native and Flutter which gives a boost to your business."
            },
            {
                title:'How do you create a a standardized mobile app quality assurance process?',
                description:"To create a standardized mobile app quality assurance process Mkaits undergoes various testing processes under design and development stages. We ensure that our customers get a functional website."
            }
        ]
    },
    {
        title:'Game Development',
        paras:[
            {
                title:'Game Development',
                description:"Mkaits develops the perfect gaming apps with an innovative mixture of professional team, advanced tools, and formidable information of the crowded mobile landscape. Mkaits provides mobile app development services which includes building iOS & Android and applications that efficiently complement web solutions. We have developed 1000+ mobile apps. Mkaits ensures mobile app success by delivering professional UI and secure app code."
            },
            {
                title:"Field and Technologies",
                description:"We develop blockchain applications for different industries like Education, Manufacturing, Healthcare, Banking and Finance, E-commerce, MLM, Games and Entertainment. Mkaits has a team of professional developers who are skilled on mobile apps with expertise in a lot of languages and technologies like React Native and Flutter. We always focus on our client’s sales and profits. Our highly expert team develops mobile apps in React Native which gives more revenue to your business in less time. World’s most famous apps like Facebook and Instagram, Skype, Pinterest use React Native as their mobile app development framework. React Native enhance the business productivity in less time. In short React Native gives a significant boost to your business. Mkaits has a remarkable professional team to build mobile apps in Flutter also which allows you to launch apps faster as in this digital world there comes a lot of market competition, so brands and businesses must maintain their pace to keep a reputed position in the market.  Flutter plays a significant role in your business growth."
            },
            {
                title:'Significance of mobile apps developed by Mkaits',
                description:"The question arises why do you need a mobile app for your business? Mobile applications are really very important as around 6.37 billion people around the world use smartphones now, and 90% of the time spent on them is on mobile apps. The marketplace for mobile apps is growing and getting larger day by day.  There were about 230 billion mobile app downloads last year. Mobile apps are more and more turning into clients’ preferred manner to interact with others. Therefore, maximum companies/brands are demanding for mobile apps to lure maximum customers. Mobile apps enable customers to customize statistics including messages, pointers, and offers in step with the person.<br>Mkaits develops mobile application that accumulates and examine user facts and will let you acquire insights on user engagement. We develop mobile application which gives your business a large edge. Mobile apps enhance the user experience via third party tool integrations. We develop successful mobile application which focus not only appropriate design and friction-free UX, but also a keen understanding of the fierce competition within your app store category."
            }
        ],
        slider:[
            {
                title:'Lead Generation',
                description:"We innovate and execute a lead generation strategy using SEO, social ads, and SEM to help engage your customers to your brand."
            },
            {
                title:'Lead Capture',
                description:'We optimize your digital presence to drive conversion leads using effective websites, landing pages and forms.'
            },
            {
                title:'Lead Nurturing',
                description:'We provide insightful content to drive your customers closer to your brand and persuade them to buy.'
            }
        ],
        benefits:{
            title:"Our Significance",
            description:"choose the best one’s",
            list:[
                "Mkaits provides a great user experience to your app.",
                "Mkaits targets audience in a cost effective and measurable way.",
                "We positively impact digital business with our innovative tools and techniques."
            ]
        },
        accordin:[
            {
                title:'How do Mkaits give your company a competitive advantage?',
                description:"We harness the most promising mobile apps to give you a competitive advantage. Mkaits has a team of professional developers with expertise in a lot of languages and technologies like React Native and Flutter which gives a boost to your business."
            },
            {
                title:'How do you create a a standardized mobile app quality assurance process?',
                description:"To create a standardized mobile app quality assurance process Mkaits undergoes various testing processes under design and development stages. We ensure that our customers get a functional website."
            }
        ]
    },
    {
        title:'Blockchain',
        paras:[
            {
                title:'Blockchain',
                description:"Mkaits develops the perfect blockchain app with an innovative mixture of professional team, advanced tools, and formidable information of the crowded mobile landscape. Mkaits provides mobile app development services which includes building iOS & Android and applications that efficiently complement web solutions. We have developed 1000+ mobile apps. Mkaits ensures mobile app success by delivering professional UI and secure app code."
            },
            {
                title:"Field and Technologies",
                description:"We develop blockchain applications for different industries like Education, Manufacturing, Healthcare, Banking and Finance, E-commerce, MLM, Games and Entertainment. Mkaits has a team of professional developers who are skilled on mobile apps with expertise in a lot of languages and technologies like React Native and Flutter. We always focus on our client’s sales and profits. Our highly expert team develops mobile apps in React Native which gives more revenue to your business in less time. World’s most famous apps like Facebook and Instagram, Skype, Pinterest use React Native as their mobile app development framework. React Native enhance the business productivity in less time. In short React Native gives a significant boost to your business. Mkaits has a remarkable professional team to build mobile apps in Flutter also which allows you to launch apps faster as in this digital world there comes a lot of market competition, so brands and businesses must maintain their pace to keep a reputed position in the market.  Flutter plays a significant role in your business growth."
            },
            {
                title:'Significance of mobile apps developed by Mkaits',
                description:"The question arises why do you need a mobile app for your business? Mobile applications are really very important as around 6.37 billion people around the world use smartphones now, and 90% of the time spent on them is on mobile apps. The marketplace for mobile apps is growing and getting larger day by day.  There were about 230 billion mobile app downloads last year. Mobile apps are more and more turning into clients’ preferred manner to interact with others. Therefore, maximum companies/brands are demanding for mobile apps to lure maximum customers. Mobile apps enable customers to customize statistics including messages, pointers, and offers in step with the person.<br>Mkaits develops mobile application that accumulates and examine user facts and will let you acquire insights on user engagement. We develop mobile application which gives your business a large edge. Mobile apps enhance the user experience via third party tool integrations. We develop successful mobile application which focus not only appropriate design and friction-free UX, but also a keen understanding of the fierce competition within your app store category."
            }
        ],
        slider:[
            {
                title:'Lead Generation',
                description:"We innovate and execute a lead generation strategy using SEO, social ads, and SEM to help engage your customers to your brand."
            },
            {
                title:'Lead Capture',
                description:'We optimize your digital presence to drive conversion leads using effective websites, landing pages and forms.'
            },
            {
                title:'Lead Nurturing',
                description:'We provide insightful content to drive your customers closer to your brand and persuade them to buy.'
            }
        ],
        benefits:{
            title:"Our Significance",
            description:"choose the best one’s",
            list:[
                "Mkaits provides a great user experience to your mobile app.",
                "Mkaits targets audience in a cost effective and measurable way.",
                "We positively impact digital business with our innovative tools and techniques."
            ]
        },
        accordin:[
            {
                title:'How do Mkaits give your company a competitive advantage?',
                description:"We harness the most promising mobile apps to give you a competitive advantage. Mkaits has a team of professional developers with expertise in a lot of languages and technologies like React Native and Flutter which gives a boost to your business."
            },
            {
                title:'How do you create a a standardized mobile app quality assurance process?',
                description:"To create a standardized mobile app quality assurance process Mkaits undergoes various testing processes under design and development stages. We ensure that our customers get a functional website."
            }
        ]
    },
    {
        title:'Desktop App Dev',
        paras:[
            {
                title:'Desktop Application Development',
                description:"Mkaits develops the perfect blockchain app with an innovative mixture of professional team, advanced tools, and formidable information of the crowded mobile landscape. Mkaits provides mobile app development services which includes building iOS & Android and applications that efficiently complement web solutions. We have developed 1000+ mobile apps. Mkaits ensures mobile app success by delivering professional UI and secure app code."
            },
            {
                title:"Field and Technologies",
                description:"We develop blockchain applications for different industries like Education, Manufacturing, Healthcare, Banking and Finance, E-commerce, MLM, Games and Entertainment. Mkaits has a team of professional developers who are skilled on mobile apps with expertise in a lot of languages and technologies like React Native and Flutter. We always focus on our client’s sales and profits. Our highly expert team develops mobile apps in React Native which gives more revenue to your business in less time. World’s most famous apps like Facebook and Instagram, Skype, Pinterest use React Native as their mobile app development framework. React Native enhance the business productivity in less time. In short React Native gives a significant boost to your business. Mkaits has a remarkable professional team to build mobile apps in Flutter also which allows you to launch apps faster as in this digital world there comes a lot of market competition, so brands and businesses must maintain their pace to keep a reputed position in the market.  Flutter plays a significant role in your business growth."
            },
            {
                title:'Significance of mobile apps developed by Mkaits',
                description:"The question arises why do you need a mobile app for your business? Mobile applications are really very important as around 6.37 billion people around the world use smartphones now, and 90% of the time spent on them is on mobile apps. The marketplace for mobile apps is growing and getting larger day by day.  There were about 230 billion mobile app downloads last year. Mobile apps are more and more turning into clients’ preferred manner to interact with others. Therefore, maximum companies/brands are demanding for mobile apps to lure maximum customers. Mobile apps enable customers to customize statistics including messages, pointers, and offers in step with the person.<br>Mkaits develops mobile application that accumulates and examine user facts and will let you acquire insights on user engagement. We develop mobile application which gives your business a large edge. Mobile apps enhance the user experience via third party tool integrations. We develop successful mobile application which focus not only appropriate design and friction-free UX, but also a keen understanding of the fierce competition within your app store category."
            }
        ],
        slider:[
            {
                title:'Lead Generation',
                description:"We innovate and execute a lead generation strategy using SEO, social ads, and SEM to help engage your customers to your brand."
            },
            {
                title:'Lead Capture',
                description:'We optimize your digital presence to drive conversion leads using effective websites, landing pages and forms.'
            },
            {
                title:'Lead Nurturing',
                description:'We provide insightful content to drive your customers closer to your brand and persuade them to buy.'
            }
        ],
        benefits:{
            title:"Our Significance",
            description:"choose the best one’s",
            list:[
                "Mkaits provides a great user experience to your mobile app.",
                "Mkaits targets audience in a cost effective and measurable way.",
                "We positively impact digital business with our innovative tools and techniques."
            ]
        },
        accordin:[
            {
                title:'How do Mkaits give your company a competitive advantage?',
                description:"We harness the most promising mobile apps to give you a competitive advantage. Mkaits has a team of professional developers with expertise in a lot of languages and technologies like React Native and Flutter which gives a boost to your business."
            },
            {
                title:'How do you create a a standardized mobile app quality assurance process?',
                description:"To create a standardized mobile app quality assurance process Mkaits undergoes various testing processes under design and development stages. We ensure that our customers get a functional website."
            }
        ]
    },
    {
        title:'Digital Marketing',
        paras:[
            {
                title:'The Digital Marketing',
                description:"Mkaits takes an innovative approach to digital advertising and marketing, focused on targeted customers at every level of the digital marketing. Effective digital marketing can lead to beyond business transactions and helps to further develop a company's brand. We create focus on digital engagement and multitouch customer journeys. It allows our clients to stand out in the market competition. We help businesses create fast, helpful, and proactive digital customer service."
            },
            {
                title:"",
                description:"Mkaits understands your targeted audience with the appropriate marketing techniques.  We reach your targeted audience in a cost-effective and measurable way. Our digital marketing strategy includes increasing brand loyalty and driving online sales. In this way digital marketing allows you to reach out a wide audience promptly. Our team has years of experience working with businesses to help them grow. Our team is our strength, and their skills is our product."
            }
        ],
        slider:[
            {
                title:'Lead Generation',
                description:"We innovate and execute a lead generation strategy using SEO, social ads, and SEM to help engage your customers to your brand."
            },
            {
                title:'Lead Capture',
                description:'We optimize your digital presence to drive conversion leads using effective websites, landing pages and forms.'
            },
            {
                title:'Lead Nurturing',
                description:'We provide insightful content to drive your customers closer to your brand and persuade them to buy.'
            }
        ],
        benefits:{
            title:"Our Significance",
            description:"choose the best one’s",
            list:[
                "Mkaits provides a great user experience to your website.",
                "Mkaits targets audience in a cost effective and measurable way.",
                "We positively impact digital business with our innovative tools and techniques."
            ]
        },
        accordin:[
            {
                title:'What are our digital marketing techniques?',
                description:"We tackle digital marketing through a universal strategy focused on impact. We build a solid digital marketing framework. We use effective and innovative digital marketing tactics like SEO, PPC, E-mail Marketing, Social Media Marketing, Content Marketing, Marketing Automation and PR."
            },
            {
                title:'How Mkaits uses innovation in Digital Marketing?',
                description:"Mkaits focus on brand reputation. Innovation is the main factor which differentiates you from your competitors in the market. With the help of research and development to apprehend the crucial needs of your marketplace and its ongoing traits, we effortlessly place your commercial enterprise in a role to cater important needs of the marketplace."
            }
        ]
    },
    {
        title:'UI/UX Designing',
        paras:[
            {
                title:'UI/UX Designing',
                description:"Mkaits’s innovative approach to UI/UX Design ensures customer satisfaction as they are meant for developing visually attractive, emblem strengthening digital interfaces that attracts customers. Mkaits understands the full scope of the project as well as the technical requirements to create a digital product."
            },
            {
                title:"Mkaits creates innovative UI/UX designs",
                description:"We understand customers’ requirements from the scratch. We make extensively innovative designs by thinking out of the box. Through our extensive research and expertise backed designs your product is given an aesthetically appealing UI. Creating user flows, engaging front-end designs, and product conceptualization gives a complete user engagement evaluation."
            }
        ],
        slider:[
            {
                title:'Branding effect',
                description:"Branding effect develops user friendly user-interface design that reflects brand qualities for seamless customer experience."
            },
            {
                title:'Technological Advancement',
                description:'There is a fundamental shift in the way we interact with technology. Latest designing with new technologies differentiates you from others in the market.'
            },
            {
                title:'Marketing Role of Designers',
                description:'UI/UX designers engage customers with content and brand.'
            }
        ],
        benefits:{
            title:"Our Significance",
            description:"choose the best one’s",
            list:[
                "Mkaits designs an appropriate UI/UX design which enhance customers’ satisfaction.",
                "Mkaits designs an innovative UI/UX design which maintains brand loyalty.",
                "Increase your productivity with our intuitive UI/UX design "
            ]
        },
        accordin:[
            {
                title:'How do Mkaits understand products and user goals?',
                description:"Through research Mkaits understands product and user requirements. Our research phase includes to examine the product vertically, user interviews, creating user personas, competitive analysis, and evaluating & integrating the results."
            },
            {
                title:'What is our design procedure?',
                description:"The design technique starts with writing use cases, to better see how users use your product. Then we start UX, where we smash down complex queries into their most effective forms. Next, we develop a visual language to ensure integration across the product and platforms. Eventually, we create prototypes to test our design theories."
            }
        ]
    },
    {
        title:'SEO',
        paras:[
            {
                title:"Search Engine Optimization",
                description:"As your outsourced search engine optimization partner, we give a complete-scale assessment of the growth of your website, rate analysis, a lead monitoring and reporting network. We then work with a professional SME from your industry to provide two unique parts of content material. Your inbound advertising and marketing will be close to-computerized, attracting efficient leads, potential employees, business growth opportunities and press. In terms of business enterprise search engine optimization, experience matters. In our 5 years within the industry, we’ve helped many businesses of various sizes meet their goals. "
            },
            {
                title:'The question arises why do you need SEO for your company? ',
                description:"To get a high engagement of customers Mkaits provides most viable and cost-effective SEO services in the market. organic search is a huge part of most companies’ website overall performance and an important aspect of the buyer funnel and in the long run getting customers to complete a conversion or engagement. Mkaits develops a strong foundation for a professional website with an impressive user experience which is easily accessible in search. We provide the most effective SEO services which understands macro marketplace, credibility of your company and its digital properties. Every brand wants maximum organic rankings and visibility to be on the top of the list. Using our SEO strategies to relay your messaging for right offers, ground-breaking services and products, and the importance and dependability of what you provide customers will be a revolution for your brand. More ratings and placement assist elevate your company’s overall reputation.",
            }
        ],
        slider:[
        ],
        benefits:{
            title:"Our SEO",
            description:"Our seo service is the most effective within the industry",
            list:[
                "utilizing most effective white-hat strategies",
                "Our site remained a hit through more than 5 years of Google’s regular algorithm adjustments.",
                "We positively impact your business with our seo techniques."
            ]
        },
        accordin:[
            {
                title:'How Mkaits helps to increases traffic with SEO?',
                description:"Mkaits focus to increase rankings. Once your rankings begin to increase, you will also begin to see gradual increases in traffic to your website. "
            },
            {
                title:'How will Mkaits communicate changes made on sites and how often?',
                description:"Our effective SEO tactics translate several changes to your site, all occur with your approval. Whether your SEO strategy is being updated or created from scratch, it’s important to know what adjustments are being made on each page."
            }
        ]
    },
]
export default data