import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { Row, Col, Collapse, Typography, Space, Image } from 'antd';
import './index.css';
import { BreadCrumb } from "../../ui-elements/breadCrump"
import { Helmet } from 'react-helmet';
import ServiceFirstBlock from '../../components/servicefirst/ServiceFirstBlock';
import ServiceOffering from '../../components/serviceoffering';
import AboutServices from '../../components/aboutServices';
import Feedback from '../../components/feedback';
const { Title, Text } = Typography;
const Services = () => {
    const title = "Mkaits - Services";
    const description = "Elevate your IT infrastructure with Mkaits. Discover our comprehensive range of services including website development, mobile app development, and blockchain development. Partner with us for reliable performance and expert support.";
    const image = "https://www.mkaits.com/assets/logo.png";
    const url = "https://www.mkaits.com/services";
    return (
        <>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
                <meta name="robots" content="index, follow" />
                <meta name="author" content="Mkaits Technology" />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
                <meta property="og:image" content={image} />
                <meta property="og:url" content={url} />
                <meta property="og:type" content="website" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={title} />
                <meta name="twitter:description" content={description} />
                <meta name="twitter:image" content={image} />
            </Helmet>
            <BreadCrumb
                title='Services'
                description='We Can Help You Succeed'
                pageName={'services'}
                breadcrumb={[{ to: '/', name: 'Home' }]}
            />
            <ServiceFirstBlock />
            <ServiceOffering />
            <AboutServices />
            <Feedback />
        </>
    )
}
export default Services