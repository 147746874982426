import React from 'react';
import { Row, Col, Typography, Image, Collapse } from "antd";
import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll';
import ShareBlog from '../shareBlog';
import ShareMidButton from '../ShareMidButton';
import {
    Helmet
} from 'react-helmet';
import { BreadCrumb } from '../../ui-elements/index';

const { Title } = Typography;
const { Panel } = Collapse;

const MobileBlog2 = () => {
    const title = "Mobile App Development Services | Mkaits";
    const description = "Discover our comprehensive mobile app development services. ";
    const image = "https://www.mkaits.com/assets/mobileblog2cover.jpg";
    const url = "https://www.mkaits.com/blogs/mobile-app-development/mobile-app-development-services-we-offer-from-concept-to-launch";
    // Set up scroll behavior on component mount and clean up on unmount
    React.useEffect(() => {
        Events.scrollEvent.register('begin', function () {
            console.log("begin", arguments);
        });

        Events.scrollEvent.register('end', function () {
            console.log("end", arguments);
        });

        scrollSpy.update();

        return () => {
            Events.scrollEvent.remove('begin');
            Events.scrollEvent.remove('end');
        };
    }, []);

    return (
        <>
            <Helmet>
                <title>{title}</title>
                <meta name="title" content={title} />
                <meta name="description" content={description} />

                <meta property="og:type" content="website" />
                <meta property="og:url" content={url} />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
                <meta property="og:image" content={image} />

                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content={url} />
                <meta property="twitter:title" content={title} />
                <meta property="twitter:description" content={description} />
                <meta property="twitter:image" content={image} />

            </Helmet>
            <BreadCrumb title='Blogs' description='Your Business Partner' pageName="Mobile App Development Services We Offer" breadcrumb={[{ to: '/blogs/mobile-app-development', name: 'Blogs' }]} />
            <div className="py-5 px-lg-5 px-md-5 px-sm-0">
                <div className=''>
                    <ShareBlog url={"/blogs/mobile-app-development/mobile-app-development-services-we-offer-from-concept-to-launch"} />
                </div>
                <Row>
                    <Col xs={0} sm={0} md={0} lg={6} className="toc-container px-3 my-3 h-50">
                        {/* Links for scrolling */}
                        <h2 className="text-black fw-bold">Table of Contents</h2>
                        <div className='px-2 mx-2 border-black'>
                            {/* <Link activeClass="activeTab" to="section1" spy={true} smooth={true} duration={50}>
                                A Glimpse into the Future with Mkaits
                            </Link>
                            <br />
                            <br /> */}
                            <Link activeClass="activeTab" to="section2" spy={true} smooth={true} duration={50}>
                                Our Approach: Turning Dreams into Reality
                            </Link>
                            <br />
                            <br />
                            <Link activeClass="activeTab" to="section3" spy={true} smooth={true} duration={50}>
                                Design That Delights
                            </Link>
                            <br />
                            <br />
                            <Link activeClass="activeTab" to="section4" spy={true} smooth={true} duration={50}>
                                Development: Where the Magic Happens
                            </Link>
                            <br />
                            <br />
                            <Link activeClass="activeTab" to="section5" spy={true} smooth={true} duration={50}>
                                Rigorous Testing: Quality Assurance
                            </Link>
                            <br />
                            <br />
                            <Link activeClass="activeTab" to="section6" spy={true} smooth={true} duration={50}>
                                Launch Strategy: Making a Splash
                            </Link>
                            <br />
                            <br />
                            <Link activeClass="activeTab" to="section7" spy={true} smooth={true} duration={50}>
                                Ongoing Support: We’re in This Together
                            </Link>
                            <br />
                            <br />
                            <Link activeClass="activeTab" to="section8" spy={true} smooth={true} duration={50}>
                                Why Choose Mkaits Technologies?
                            </Link>
                        </div>
                    </Col>
                    <Collapse accordion className="d-md-none w-100 mx-3 position-sticky z_i">
                        <Panel header="Table of Contents" key="1" className='py-2 ' >
                            <div className='d-flex flex-column gap-3 border-black mx-3 px-3'>
                                {/* <Link activeClass="activeTab" to="section1" spy={true} smooth={true} duration={50}>
                                    A Glimpse into the Future with Mkaits
                                </Link> */}
                                <Link activeClass="activeTab" to="section2" spy={true} smooth={true} duration={50}>
                                    Our Approach: Turning Dreams into Reality
                                </Link>
                                <Link activeClass="activeTab" to="section3" spy={true} smooth={true} duration={50}>
                                    Design That Delights
                                </Link>
                                <Link activeClass="activeTab" to="section4" spy={true} smooth={true} duration={50}>
                                    Development: Where the Magic Happens
                                </Link>
                                <Link activeClass="activeTab" to="section5" spy={true} smooth={true} duration={50}>
                                    Rigorous Testing: Quality Assurance
                                </Link>
                                <Link activeClass="activeTab" to="section6" spy={true} smooth={true} duration={50}>
                                    Launch Strategy: Making a Splash
                                </Link>
                                <Link activeClass="activeTab" to="section7" spy={true} smooth={true} duration={50}>
                                    Ongoing Support: We’re in This Together
                                </Link>
                                <Link activeClass="activeTab" to="section8" spy={true} smooth={true} duration={50}>
                                    Why Choose Mkaits Technologies?
                                </Link>
                            </div>

                        </Panel>
                    </Collapse>
                    <Col xs={24} sm={24} md={24} lg={14} className="content px-3 my-3">
                        <Title className='text-black mb-1 fs-1'>
                            Mobile App Development Services We Offer: From Concept to Launch
                        </Title>
                        <br />
                        <p>Hey there! If you’re reading this, chances are you’re on the hunt for mobile app development services that can take your idea from a spark of inspiration to a fully-fledged app ready for the world to use. Well, you’ve landed in the right spot! At Mkaits Technologies, we specialize in turning concepts into cutting-edge mobile applications, and we’re here to guide you through the process.</p>
                        <br />
                        <div className='custom-width-sm custom-width-md-lg m-auto'>
                            <Image src="/assets/mobileblog2a.jpg" alt="Data Analytics" className='img-fluid w-100' preview={false} />
                        </div>
                        <br />
                        <Element name="section1" className="element">
                        </Element>
                        <Element name="section2" className="element">
                            <Title className='text-black mb-1 fs-4'>
                                Our Approach: Turning Dreams into Reality
                            </Title>
                            <br />
                            <p>We believe that every great mobile app starts with a brilliant idea. Whether you’re dreaming of a game-changing tool that improves daily life, an innovative e-commerce platform, or a fresh social networking experience, we’re all ears! Our approach is collaborative, and we start by getting to know your vision, goals, and audience. After all, an app without a purpose is just a series of code!</p>
                            <br />
                        </Element>
                        <div className='custom-width-sm custom-width-md-lg m-auto'>
                            <Image src="/assets/mobileblog2b.jpg" alt="Data Analytics" className='img-fluid w-100' preview={false} />
                        </div>
                        <br />
                        <Element name="section3" className="element">
                            <Title className='text-black mb-1 fs-4'>
                                Design That Delights
                            </Title>
                            <br />
                            <p>Once we’ve crystallized your concept, it’s time to focus on design. We know that the user experience (UX) and user interface (UI) can make or break an app. Our talented design team specializes in crafting intuitive and visually appealing interfaces that resonate with users. We prioritize user-friendly navigation and compelling visuals to keep users engaged and coming back for more!</p>
                            <br />
                        </Element>
                        <div className='custom-width-sm custom-width-md-lg m-auto'>
                            <Image src="/assets/mobileblog2c.jpg" alt="Data Analytics" className='img-fluid w-100' preview={false} />
                        </div>
                        <br />
                        <Element name="section4" className="element">
                            <Title className='text-black mb-1 fs-4'>
                                Development: Where the Magic Happens
                            </Title>
                            <br />
                            <p>Now comes the exciting part—development! Our skilled developers break down your app idea into manageable tasks. Using the latest technologies, we create a robust app that functions beautifully across various devices and platforms. Whether you’re looking for iOS, Android, or a hybrid approach, we’ve got you covered! Our team follows best practices to ensure your app is optimized for performance and scalability.</p>
                            <br />
                        </Element>
                        <div className='custom-width-sm custom-width-md-lg m-auto'>
                            <Image src="/assets/mobileblog2d.jpg" alt="Data Analytics" className='img-fluid w-100' preview={false} />
                        </div>
                        <br />
                        <Element name="section5" className="element">
                            <Title className='text-black mb-1 fs-4'>
                                Rigorous Testing: Quality Assurance
                            </Title>
                            <br />
                            <p>At Mkaits Technologies, we take quality seriously. Before your app sees the light of day, it goes through a rigorous testing phase. Our quality assurance experts comb through each aspect of the app, checking for bugs, compatibility issues, and overall performance. We don’t just want an app that works—we want an app that WOWs!</p>
                            <br />
                        </Element>
                        <div className='custom-width-sm custom-width-md-lg m-auto'>
                            <Image src="/assets/mobileblog2e.jpg" alt="Data Analytics" className='img-fluid w-100' preview={false} />
                        </div>
                        <br />
                        <Element name="section6" className="element">
                            <Title className='text-black mb-1 fs-4'>
                                Launch Strategy: Making a Splash
                            </Title>
                            <br />
                            <p>Once your app has passed all tests and is polished to perfection, it’s time for the grand launch! But hold on—we don’t just toss it out into the wild and hope for the best. Our team creates a comprehensive launch strategy tailored to your app’s target audience. From marketing campaigns to app store optimization, we ensure your app gets the attention it deserves.</p>
                            <br />
                        </Element>
                        <div className='custom-width-sm custom-width-md-lg m-auto'>
                            <Image src="/assets/mobileblog2f.jpg" alt="Data Analytics" className='img-fluid w-100' preview={false} />
                        </div>
                        <br />
                        <Element name="section7" className="element">
                            <Title className='text-black mb-1 fs-4'>
                                Ongoing Support: We’re in This Together
                            </Title>
                            <br />
                            <p>Our relationship doesn't end at launch. We believe in building long-term partnerships. Our team provides ongoing support and maintenance to keep your app running smoothly and up-to-date with the latest trends and technologies. We’re here to help with updates, new features, and anything else you might need as your user base grows.</p>
                            <br />
                        </Element>
                        <Element name="section8" className="element">
                            <br />
                            <p className='bg_grey p-lg-5 p-3 rounded-3 fst_italic'> <span className='fs-1'>‟
                                <br />
                                <span className='fw-bold fs-3'>Why Choose Mkaits Technologies?</span> </span><br />
                                With a proven track record of successful app launches and a passionate team that lives and breathes mobile development, Mkaits Technologies stands out in the crowded tech landscape.We’re not just developers; we’re your partners in innovation. Our commitment to excellence and customer satisfaction drives us to deliver apps that truly make an impact.
                            </p>
                            <br />
                            <p>So, if you have a mobile app idea brewing in your mind, let’s bring it to life together! Drop us a line, and let’s chat about how we can turn your concept into a reality. Because at Mkaits Technologies, we believe that every great app starts with a great partnership. </p>
                        </Element>
                    </Col>
                </Row>
                <div className='d-flex justify-content-center mt-3'>
                    <ShareMidButton url={"/blogs/mobile-app-development/mobile-app-development-services-we-offer-from-concept-to-launch"} />
                </div>
            </div>
        </>
    );
}

export default MobileBlog2;
