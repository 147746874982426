import { Typography } from "antd"
import './index.css'
const { Title } = Typography
const ReplaButton = ({ title }) => {
    return (
        <div className="repla-button py-3 w-100 brand-bg">
            <div className="one secondary-bg animation"></div>
            <Title level={5} className='my-0 two text-white'>{title || 'Click Me'}</Title>
        </div>
    )
}
export default ReplaButton