import { Typography, InputNumber, Space, Button } from "antd"
import { useState } from "react"
const {Title}= Typography
const Question=()=>{
    const [answer, setAnswer]= useState(null)
    const [state, setState]= useState({
        numA:1,
        denA:6,
        numX:1,
        denX:2,
        numY:3,
        denY:4,
    })
    const result=()=>{
        setAnswer((state?.numA*(state?.denX*state?.denY))/(state?.denA*(state?.numX*state?.numY)))
    }
    const handleChange=(key,value)=>{

    }
    return (
        <div className="p-3">
            <Title level={5}>
                <Space size={15} className="align-center">
                    <span>Q):</span><InputNumber onChange={(value)=>{handleChange('',value)}}/><span>/</span><InputNumber/><span>is</span><InputNumber/><span>/</span><InputNumber/><span>of</span><InputNumber/><span>/</span><InputNumber/><span>of a certain number.</span>
                </Space>
            </Title>
            <Button onClick={result}>Check</Button>
            <Title level={5}>
                <Space size={15} className="align-center">
                    <span>Answer: </span>
                    <span>{answer?answer.toFixed(2):''}</span>
                </Space>
            </Title>
        </div>
    )
}
export default Question