import React from 'react'
import { SectionTop } from '../../ui-elements';
import { Typography, Image, Space, Row, Col, Pagination } from 'antd';
import { ClockCircleOutlined, UserOutlined } from "@ant-design/icons"
import { Link } from 'react-router-dom';
import './index.css'

const AiBlogBlock = () => {
    const scrollToTop = () => {
        window.scrollTo({ top: 0 });
    };

    const news = [
        {
            title: "Impact of AI transformation on industries",
            date: '30 July 2024',
            url: 'aiblog6cover.jpg',
            type: 'Artificial Intelligence',
            route: '/blogs/artificial-intelligence/impact-of-ai-transformation-on-industries'
        },
        {
            title: "Ethical Considerations of AI",
            date: '27 July 2024',
            url: 'aiblog5cover.jpg',
            type: 'Artificial Intelligence',
            route: '/blogs/artificial-intelligence/ethical-considerations-of-ai'
        },
        {
            title: "Elevate Your Customer Experience with AI at Mkaits",
            date: '26 July 2024',
            url: 'aiblog4cover.jpg',
            type: 'Artificial Intelligence',
            route: '/blogs/artificial-intelligence/elevate-your-customer-experience-with-ai-at-mkaits'
        },
        {
            title: "Machine Learning vs. Deep Learning: What's the Difference?",
            date: '24 July 2024',
            url: 'aiblog3cover.jpg',
            type: 'Artificial Intelligence',
            route: '/blogs/artificial-intelligence/machine-learning-vs-deep-learning'
        },
        {
            title: 'How to Begin Learning AI Skills?',
            date: '23 July 2024',
            url: 'aiblog2cover.jpg',
            type: 'Artificial Intelligence',
            route: '/blogs/artificial-intelligence/how-to-begin-learning-ai-skills'
        },
        {
            title: 'What is coming in the world of AI in 2024?',
            date: '17 July 2024',
            url: 'aiblog1cover.jpg',
            type: 'Artificial Intelligence',
            route: '/blogs/artificial-intelligence/what-is-coming-in-the-world-of-ai-in-2024'
        },
    ]
    return (
        <div>
            <div className="py-5" style={{ overflowX: 'hidden' }}>
                <div className='py-5 flex-col-center'>
                    {/* <SectionTop title='' description='News & Updates'/> */}
                    <div style={{ width: '93%' }}>
                        <Row gutter={[32, 32]}>
                            {
                                news?.map((news, n) => {
                                    return (
                                        <Col xs={24} sm={12} md={8}>
                                            <div className='px-2' key={'news-' + n}>
                                                <Link to={news?.route} className=" rounded-1 news-slider" onClick={scrollToTop}>
                                                    <div className='news-slider-image-box'>
                                                        <Image src={'/assets/' + news?.url} preview={false} alt='Mkaits' className='img-fluid one animation' style={{ maxWidth: '100%', maxHeight: '100%' }} />
                                                        <div className='two animation'>
                                                            <Image src={'/assets/plus.png'} alt='olive' />
                                                        </div>
                                                        <span className='tag px-3 py-1'>
                                                            <Typography.Text strong className='text-white'>
                                                                {
                                                                    news?.type
                                                                }
                                                            </Typography.Text>
                                                        </span>
                                                    </div>
                                                    <Space direction='vertical' className='align-center py-3 px-2' style={{ width: '100%' }}>
                                                        <Space direction='horizontal' size={10}>
                                                            <Space>
                                                                <ClockCircleOutlined
                                                                    style={{ transform: 'translateY(-4px)' }}
                                                                    className='brand-color'
                                                                />
                                                                <Typography.Text className='my-0' strong>{news?.date}</Typography.Text>
                                                            </Space>
                                                            <Space>
                                                                <UserOutlined
                                                                    style={{ transform: 'translateY(-4px)' }}
                                                                    className='brand-color'
                                                                />
                                                                <Typography.Text className='my-0' strong>By Admin</Typography.Text>
                                                            </Space>
                                                        </Space>
                                                        <Typography.Title className='w-100 my-0 text-center news-heading' level={4}>
                                                            {
                                                                news?.title
                                                            }
                                                        </Typography.Title>
                                                    </Space>
                                                </Link>
                                            </div>
                                        </Col>

                                    )
                                })
                            }
                        </Row>

                        {/* <Row gutter={[32, 32]} className='text-center mt-5'>
                            <Col span={24}>
                                <Space direction='vertical' className='pagin'>
                                    <Pagination defaultCurrent={1} total={50} />
                                </Space>
                            </Col>
                        </Row> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AiBlogBlock