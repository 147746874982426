import {Row, Col, Typography, Space, Image, Descriptions} from "antd"
import { useState } from "react"
import './index.css'
const {Title}=Typography
const Choose=()=>{
    const [currentButton,setCurrentButton]= useState(1)
    const state= [
        {
            title:'Our mission',
            description:"There are many variations of passages of avaialable but the majority have in some form, by injected humou or words which don't look even slightly believable"

        },
        {
            title:'Our vision',
            description:"There are many variations of passages of avaialable but the majority have in some form, by injected humou or words which don't look even slightly believable"
        },
        {
            title:'Our history',
            description:"There are many variations of passages of avaialable but the majority have in some form, by injected humou or words which don't look even slightly believable"
        }
    ]
    return (
            <div className="justify-center py-5">
                <Row gutter={[32,32]} style={{width:'93%'}} className='my-5'>
                    <Col xl={{span:12}} lg={{span:12}} md={{span:24}} sm={{span:24}} xs={{span:24}} className='align-center'>
                        <Space direction='vertical' size={40}>
                            <div>
                                <Title level={3} className='brand-color'>Mkaits benefits</Title>
                                <Title level={1} className='my-0'>
                                    Why you should choose Mkaits?
                                </Title>
                            </div>
                            <Row gutter={[8,0]}>
                                {
                                    state?.map((button,b)=>
                                    <Col sm={{span:8}} xs={{span:24}} key={b}>
                                        <div className="Repla-button py-3" onClick={()=>{setCurrentButton(b)}}>
                                            <div className="one brand-bg animation" style={b===currentButton?{height:'100%'}:{}}></div>
                                            <Title level={5} className='my-0 two' style={b===currentButton?{color:'white'}:{}}>{button?.title}</Title>
                                        </div>
                                    </Col>
                                    )
                                }
                            </Row>
                            <p>
                                Mkaits innovates businesses with effective and adaptable digital solutions that satisfy the today’s competition and liberate the opportunities of the future. Our innovative tools, team of professionals and years of revel in software development gives us the benefit to face out from the crowd
                            </p>
                        </Space>
                    </Col>
                    <Col xl={{span:12}} lg={{span:12}} md={{span:24}} sm={{span:24}} xs={{span:24}} className='align-center'>
                        <Space direction='vertical' size={30}>    
                            <div>
                                <Title level={2} className='brand-color'>Our Mission</Title>
                                <p>  
                                    At Mkaits, we strive to provide top-notch IT services and products that guarantee customer satisfaction. We are dedicated to delivering innovative solutions that help businesses achieve their goals.
                                </p>
                            </div>
                            <Row gutter={[12,24]}>
                                <Col xl={{span:12}} lg={{span:24}} md={{span:12}} sm={{span:24}} className='align-center'>
                                    <Image src='/assets/why-choose-one-points-img.jpg' alt='Mkaits-mission' preview={false}/>
                                </Col>
                                <Col xl={{span:12}} lg={{span:24}} md={{span:12}} sm={{span:24}} className='align-center'>
                                    <Space direction="vertical">
                                        <Space className="align-top">
                                            <Image src='/assets/icons/dot-arrow.png' alt="Mkaits" preview={false}/>
                                            <p className="my-0">Focus on providing high-quality IT services and products that exceed customer expectations</p>
                                        </Space>
                                        <Space className="align-top">
                                            <Image src='/assets/icons/dot-arrow.png' alt="Mkaits" preview={false}/>
                                            <p className="my-0">Continuously innovate and improve IT solutions to meet evolving business needs</p>
                                        </Space>
                                        <Space className="align-top">
                                            <Image src='/assets/icons/dot-arrow.png' alt="Mkaits" preview={false}/>
                                            <p className="my-0">Offer customized IT solutions that are tailored to each client's unique requirements and goals</p>
                                        </Space>
                                        <Space className="align-top">
                                            <Image src='/assets/icons/dot-arrow.png' alt="Mkaits" preview={false}/>
                                            <p className="my-0">Invest in research and development to stay ahead of market trends and emerging technologies</p>
                                        </Space>
                                    </Space>
                                </Col>
                            </Row>
                        </Space>
                    </Col>
                </Row>
            </div>
    )
}
export default Choose