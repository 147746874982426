import { PlusOutlined,ClockCircleOutlined,UserOutlined } from '@ant-design/icons'

const Datanewsart = [
    {
        title:"News articles",
        subtitle: "What’s happening",
        newsdiv:[
            {
                image:"blog-2-1.jpg",
                type:"Tech",
                clockicon: <ClockCircleOutlined />,
                date:"8 Jan, 2022",
                usericon: <UserOutlined />,
                user:"by example",
                description: "Improving business growth with new technology"
            },
            {
                image:"blog-2-2.jpg",
                type:"Tech",
                clockicon: <ClockCircleOutlined />,
                date:"8 Jan, 2022",
                usericon: <UserOutlined />,
                user:"by example",
                description: "Improving business growth with new technology"
            },
            {
                image:"blog-2-3.jpg",
                type:"Tech",
                clockicon: <ClockCircleOutlined />,
                date:"8 Jan, 2022",
                usericon: <UserOutlined />,
                user:"by example",
                description: "Improving business growth with new technology"
            },
        ]
    }
]

export default Datanewsart