import React,{ useState } from 'react';
import './index.css';
import { Dataservicefirst } from './Dataservicefirst';
import { BreadCrumb } from '../../ui-elements';

const ServiceFirstBlock = () => {

    const [dataservicefirst, setDataServiceFirst] = useState(Dataservicefirst)
  return (
    <div>
        <section className="feature-one">
            <div className="container">
                <div className="row">
                    {
                        dataservicefirst?.map((dataser,dsf)=>
                            <div className="col-xl-4 col-lg-4" key={dsf}>
                                <div className="feature-one__single">
                                    <div className="feature-one__img">
                                        <img src={"assets/"+dataser?.image} alt="" />
                                        <div className="feature-one__title-box">
                                            <h3 className="feature-one__title">
                                                <a href="/">
                                                    {dataser?.title}
                                                </a>
                                            </h3>
                                        </div>
                                        <div className="feature-one__hover-content">
                                            <div className="feature-one__icon">
                                                <span>
                                                    <img src={"assets/"+dataser?.hoverImage} alt="" />
                                                </span>
                                            </div>
                                            <h3 className="feature-one__hover-title">
                                                <a href="/">
                                                    {dataser?.hovertitle}
                                                </a>
                                            </h3>
                                            <p className="feature-one__hover-text">
                                                {dataser?.hoverdescription}
                                            </p>
                                            <div className="feature-one__learn-more">
                                                <a href="">
                                                    {dataser?.learn}
                                                    <i>
                                                        {dataser?.icon}
                                                    </i>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    
                </div>
            </div>
        </section>
    </div>
  )
}

export default ServiceFirstBlock