import ReplaProjects from '../../components/projects';
import { BreadCrumb } from '../../ui-elements/index';
import { Helmet } from 'react-helmet';
import Videoslider from '../../components/videoSlider/index'
const Projects = () => {
    const title = "Mkaits - Projects";
    const description = "Explore our recent projects showcasing our expertise in software development, innovative tools, and professional excellence. See how we help clients thrive in today's competitive landscape and unlock future opportunities.";
    const image = "https://www.mkaits.com/assets/logo.png";
    const url = "https://www.mkaits.com/projects";
    return <>
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta name="robots" content="index, follow" />
            <meta name="author" content="Mkaits Technology" />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={image} />
            <meta property="og:url" content={url} />
            <meta property="og:type" content="website" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content={image} />
        </Helmet>
        <BreadCrumb title='Recent Projects' pageName='Recent Projects' breadcrumb={[{ to: '/', name: 'Home' }]} />
        <Videoslider />
        <ReplaProjects />
    </>
}
export default Projects;
