import { ArrowRightOutlined } from '@ant-design/icons';

export const Dataservicefirst = [
    {
        image: 'resources/feature-1-1.jpg',
        hoverImage: 'resources/manage.png',
        title: 'Manage IT services',
        hovertitle: 'Manage IT services',
        hoverdescription: 'There are many lipsum of in pass sages of available some.',
        learn: 'Learn more',
        icon:<ArrowRightOutlined />
    },
    {
        image: 'resources/feature-1-2.jpg',
        hoverImage: 'resources/cyber.png',
        title: 'Cyber security',
        hovertitle: 'Cyber security',
        hoverdescription: 'There are many lipsum of in pass sages of available some.',
        learn: 'Learn more',
        icon:<ArrowRightOutlined />
    },
    {
        image: 'resources/feature-1-3.jpg',
        hoverImage: 'resources/digital-exp.png',
        title: 'Digital experience',
        hovertitle: 'Digital experience',
        hoverdescription: 'There are many lipsum of in pass sages of available some.',
        learn: 'Learn more',
        icon:<ArrowRightOutlined />
    },
]