import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { Row, Col, Collapse, Typography, Space, Image } from 'antd';
import './index.css';
import { PlusCircleOutlined, SwapRightOutlined, CaretRightOutlined, } from '@ant-design/icons'
import ReplaButton from '../../components/buttons';
import data from "./data"
import { BreadCrumb } from "../../ui-elements/breadCrump"
import { Helmet } from 'react-helmet';
const { Title, Text } = Typography;
const { Panel } = Collapse;
const genExtra = () => (

    <PlusCircleOutlined
        onClick={event => {
            // If you don't want click extra trigger collapse, you can prevent this:
            event.display = "hide";
        }}
    />
);
const ServicesDetail = () => {
    const services = [
        { name: 'Software Development', path: '/services/0' },
        { name: 'Web Development', path: '/services/1' },
        { name: 'App Development', path: '/services/2' },
        { name: 'Game Development', path: '/services/3' },
        { name: 'Block Chain', path: '/services/4' },
        { name: 'Desktop App Development', path: '/services/5' },
        { name: 'Digital Marketing', path: '/services/6' },
        // {name:'UI/UX Design',path:'/services/7'},
        // {name:'SEO',path:'/services/8'},
    ]
    const [currentPanel, setCurrentPanel] = useState(['1'])
    const [state, setState] = useState(data)
    const { serviceID } = useParams()
    console.log(data[serviceID])
    return (
        <>
            <Helmet>
                <title>Mkaits - Services</title>
                <meta name="description" content="Elevate your IT infrastructure with Mkaits. Discover our comprehensive range of services including website development, mobile app development, and blockchain development. Partner with us for reliable performance and expert support" />
            </Helmet>
            <BreadCrumb title='Services' description='We Can Help You Succeed' pageName={state[serviceID]?.title} breadcrumb={[{ to: '/', name: 'Home' }]} />
            <div style={{ margin: "30px", padding: "10px" }}>
                <Row gutter={[32, 32]} justify="center">
                    <Col xs={24} sm={24} md={24} lg={7}>
                        <Space direction='vertical' size={30} className='w-100'>
                            <Space direction='vertical p-4' style={{ width: '100%', background: '#F7F7F9' }}>
                                <Title level={3} className='brand-color'>
                                    {state[serviceID]?.title}
                                </Title>
                                <Space direction='vertical' size={10} style={{ width: '100%' }}>
                                    {
                                        services?.map((service, s) =>
                                            <Link to={service?.path} key={'service-' + s} className={(s == serviceID ? 'services-active ' : 'services') + ' animation align-center-space-between px-3 py-2'}>
                                                <Text className='my-0' strong={s == serviceID}>{service?.name}</Text>
                                                <span className='center' style={{ width: '20px' }}>
                                                    <SwapRightOutlined className={(s == serviceID ? 'services-arrow-active' : 'services-arrow') + ' animation brand-color'} />
                                                </span>
                                            </Link>
                                        )
                                    }
                                </Space>
                            </Space>
                            <div className="py-5 service-contact-box" style={{ backgroundImage: "linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5)), url(/assets/images/card.jpg)" }}>
                                <Space direction='vertical w-100 flex-col-center px-3 my-3' size={30}>
                                    <Image src='/assets/logo.png' alt='mkaits' className='brand-border' preview={false} style={{ width: '190px', borderRadius: '45%', borderWidth: '2px', overflow: 'hidden', backgroundColor: 'rgba(0,0,0,0.6)' }} />
                                    <Space direction='vertical flex-col-center'>
                                        <Title level={4} className='text-white text-center'>Easy solutions to your tech problem</Title>
                                        <Text className='text-white'>Have any question?</Text>
                                        <Text className='text-white' strong>Free: +98 (000)-1234</Text>
                                    </Space>
                                </Space>
                            </div>
                            <div>
                                <ReplaButton title={'Download our flyer'} />
                            </div>
                        </Space>
                    </Col>
                    <Col className="gutter-row" xs={24} sm={24} md={24} lg={17}>
                        <div>
                            <Space direction='vertical' size={20}>
                                <Image src="/assets/images/service-details-img-1.jpg" alt="mkaits-services" width={'100%'} preview={false} />
                                <Space direction='vertical' className='py-5' space={20}>
                                    {
                                        state[serviceID]?.paras?.map((para, p) =>
                                            <div key={'para-' + p}>
                                                <Title level={p == 0 ? 3 : 4} className='brand-color'>
                                                    {
                                                        para?.title
                                                    }
                                                </Title>
                                                <Typography.Text>
                                                    {
                                                        para?.description
                                                    }
                                                </Typography.Text>
                                            </div>
                                        )
                                    }
                                </Space>
                                <div className="service-details__points-two my-0">
                                    <Row gutter={[16, 24]}>
                                        <Col className='gutter-row' xs={24} sm={24} md={12}>
                                            <div className="service-details__points-two-single">
                                                <div className="service-details__points-two-content">
                                                    <h4>Proin tempus</h4>
                                                    <p>There are many of of lorem Ipsum, but the majori have suffered.</p>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col className='gutter-row' xs={24} sm={24} md={12}>
                                            <div className="service-details__points-two-single">
                                                <div className="service-details__points-two-content">
                                                    <h4>Proin tempus</h4>
                                                    <p>There are many of of lorem Ipsum, but the majori have suffered.</p>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <Row gutter={[32, 16]}>
                                    <Col xs={24} sm={24} md={12} className='center'>
                                        <Image src='/assets/images/service-details-benefits-img.jpg' width={'100%'} alt='mkaits' preview={false} />
                                    </Col>
                                    <Col xs={24} sm={24} md={12} className='py-3 align-center'>
                                        <Space direction='vertical' size={20}>
                                            <div>
                                                <Typography.Title level={3} className='brand-color my-0'>
                                                    {
                                                        state[serviceID]?.benefits?.title
                                                    }
                                                </Typography.Title>
                                                <Text strong>
                                                    {
                                                        state[serviceID]?.benefits?.description
                                                    }
                                                </Text>
                                            </div>
                                            <Space direction='vertical' size={20}>
                                                {
                                                    state[serviceID]?.benefits?.list?.map((item, i) =>
                                                        <Space key={i} size={20}>
                                                            <img src='/assets/icons/checked.png' width={'20px'} />
                                                            <Text className='my-0'>{item}</Text>
                                                        </Space>
                                                    )
                                                }
                                            </Space>
                                        </Space>
                                    </Col>
                                </Row>
                                <Collapse
                                    defaultActiveKey={['1']}
                                    onChange={(keys) => { setCurrentPanel(keys) }}
                                    expandIcon={
                                        ({ isActive }) =>
                                            <CaretRightOutlined style={{ transform: 'translateY(-3px)' }} className={isActive ? 'brand-color' : ''} rotate={isActive ? 90 : 0} />
                                    }
                                >
                                    {
                                        state[serviceID]?.accordin?.map((accor, a) =>
                                            <Panel
                                                header={<Title level={5} className={currentPanel == a ? 'my-0 brand-color' : 'my-0'}>{accor?.title}</Title>}
                                                key={a}

                                            >
                                                <div>{accor?.description}</div>
                                            </Panel>
                                        )
                                    }
                                </Collapse>
                            </Space>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}
export default ServicesDetail