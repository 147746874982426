import React, { Component } from 'react'
import HomeTopSection from '../../components/HomeTopSection'
import Services from './services'
import Moto from './moto'
import Industrywe from '../../components/industry'
import ReplaMission from '../../components/mission'
import ReplaIntro from '../../components/intro'
import Choose from '../../components/choose'
import Videoslider from '../../components/videoSlider'
import Technology from '../../components/technology'
import ReplaProjects from '../../components/projects'
import Feedback from '../../components/feedback'
import NewsSlider from '../../components/newsSlider'
import WhyBCT from '../../components/whyBCT'
import { Helmet } from 'react-helmet'
class HomePage extends Component {
    render() {
        const title = "Mkaits Technologies";
        const description = "Mkaits Technologies - The One Your Business Needs Now!";
        const image = "https://www.mkaits.com/assets/logo.png";
        const url = "https://www.mkaits.com";
        return (
            <>
                <Helmet>
                    <title>{title}</title>
                    <meta name="description" content={description} />
                    <meta name="robots" content="index, follow" />
                    <meta name="author" content="Mkaits Technology" />
                    <meta property="og:title" content={title} />
                    <meta property="og:description" content={description} />
                    <meta property="og:image" content={image} />
                    <meta property="og:url" content={url} />
                    <meta property="og:type" content="website" />
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" content={title} />
                    <meta name="twitter:description" content={description} />
                    <meta name="twitter:image" content={image} />
                </Helmet>
                <HomeTopSection />
                <ReplaMission />
                <ReplaIntro />
                <Choose />
                <Moto />
                {/* <Counter/>  */}
                <Videoslider />
                <Services />
                <Industrywe />
                <Technology />
                <WhyBCT />
                {/* <Feedback /> */}
                <NewsSlider />
                <ReplaProjects />
            </>
        )
    }
}
export default HomePage;
