import {Row, Col, Typography} from "antd"
import './index.css'
const ReplaMission=()=>{
    const state=[
        {title:'Perfect solution for business demands',bg:'mission-1.jpg'},
        {title:'Helping businesses around the world',bg:'mission-2.jpg'},
        {title:'What is holding back the IT solution.',bg:'mission-3.jpg'},
    ]
    return (
            <div >
                <div className="brand-bg py-5">
                    <div className="justify-center py-5 mb-5">
                        <Row gutter={[64,32]} style={{width:'93%'}} className='mission-top-section'>
                            <Col xl={{span:12}} sm={{span:24}} xs={{span:24}}>
                                <Typography.Title level={3} className='text-white'>
                                    Technology platform
                                </Typography.Title>
                                <Typography.Title leve={5} className='my-0 text-white'>
                                    Mkaits delivering IT solutions with complete trust
                                </Typography.Title>
                            </Col>
                            <Col xl={{span:12}} sm={{span:24}} xs={{span:24}} className='align-center'>
                                <Typography.Title level={5}  className="text-white">
                                    Mkaits innovates businesses with effective and adaptable digital solutions that satisfy the today’s competition and liberate the opportunities of the future. 
                                    Our innovative tools, team of professionals and years of revel in software development gives us the benefit to face out from the crowd.  
                                </Typography.Title>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className="justify-center">
                    <Row gutter={[32,64]} style={{transform:'translateY(-100px)',width:'93%'}}>
                        {
                            state?.map((data,d)=>
                            <Col xl={{span:8}} lg={{span:12}} md={{span:24}} sm={{span:24}} xs={{span:24}}>
                                <div key={d} className='mission-box flex-col-center rounded'>
                                    <img src={'/assets/'+data?.bg} alt='Mkaits' width={'100%'} className='one'/>
                                    <div className="two animation"></div>
                                    <div className="mission-button px-5 py-4 justify-center shadow-sm bg-white">
                                        <div className="mission-button-effect animation"></div>
                                        <Typography.Title level={4} className='my-0 secondary-color mission-button-text'>
                                            {
                                                data?.title
                                            }
                                        </Typography.Title>
                                    </div>
                                </div>
                            </Col>
                            )
                        }
                    </Row>
                </div>
            </div>
    )
}
export default ReplaMission