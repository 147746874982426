
import { BreadCrumb } from '../../ui-elements/index';
import BlogBlock from '../../components/blogBlock/index'

import { Helmet } from 'react-helmet';
import AiBlogBlock from '../../components/aiBlock';
import DataAnalyticsBlock from '../../components/dataAnalyticsBlock';
const DataAnalyticsBlogs = () => {
    const title = "Data Analytics Blogs | Mkaits";
    const description = "Stay informed and inspired with Mkaits blogs. Explore expert insights, industry trends, and practical tips on software development, infrastructure management, and digital transformation. Unlock the knowledge to drive your business forward with Mkaits.";
    const image = "https://www.mkaits.com/assets/logo.png";
    const url = "https://www.mkaits.com/blogs/data-analytics";
    return <>
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta name="robots" content="index, follow" />
            <meta name="author" content="Mkaits Technology" />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={image} />
            <meta property="og:url" content={url} />
            <meta property="og:type" content="website" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content={image} />
        </Helmet>
        <BreadCrumb title='News & blogs' description='Your Business Partner' pageName='Data Analytics' breadcrumb={[{ to: '/blogs', name: 'Blogs' }]} />
        <DataAnalyticsBlock />

    </>
}
export default DataAnalyticsBlogs;
