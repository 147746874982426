const faqData=[
    {
        name:'Theme Feature',
        icon:'faq-thm-feature.png',
        description:'Phasellus tristique lorem et nulla tincidunt faucibus.',
        faqs:[
            {
                name:'What is a managed IT service provider?',
                description:'There are many variations of passages the majority have suffered alteration in some fo injected humour, or randomised words believable. Phasellus a rhoncus erat. Vivamus vel eros vitae est aliquet pellentesque vitae.'
            },
            {
                name:'What makes your company different?',
                description:'There are many variations of passages the majority have suffered alteration in some fo injected humour, or randomised words believable. Phasellus a rhoncus erat. Vivamus vel eros vitae est aliquet pellentesque vitae.'
            },
            {
                name:'Can I get an invoice for my purchase?',
                description:'There are many variations of passages the majority have suffered alteration in some fo injected humour, or randomised words believable. Phasellus a rhoncus erat. Vivamus vel eros vitae est aliquet pellentesque vitae.'
            },
            {
                name:'Will you be updating your theme weekly?',
                description:'There are many variations of passages the majority have suffered alteration in some fo injected humour, or randomised words believable. Phasellus a rhoncus erat. Vivamus vel eros vitae est aliquet pellentesque vitae.'
            },
            {
                name:'How much do I need to pay for the IT services?',
                description:'There are many variations of passages the majority have suffered alteration in some fo injected humour, or randomised words believable. Phasellus a rhoncus erat. Vivamus vel eros vitae est aliquet pellentesque vitae.'
            },
            {
                name:'What can you do if we hire a IT advisor for business?',
                description:'There are many variations of passages the majority have suffered alteration in some fo injected humour, or randomised words believable. Phasellus a rhoncus erat. Vivamus vel eros vitae est aliquet pellentesque vitae.'
            }
        ]
    },
    {
        name:'General inquiries',
        icon:'faq-gen-inqires.png',
        description:'Phasellus tristique lorem et nulla tincidunt faucibus.',
        faqs:[
            {
                name:'What is a managed IT service provider?',
                description:'There are many variations of passages the majority have suffered alteration in some fo injected humour, or randomised words believable. Phasellus a rhoncus erat. Vivamus vel eros vitae est aliquet pellentesque vitae.'
            },
            {
                name:'What makes your company different?',
                description:'There are many variations of passages the majority have suffered alteration in some fo injected humour, or randomised words believable. Phasellus a rhoncus erat. Vivamus vel eros vitae est aliquet pellentesque vitae.'
            },
            {
                name:'Can I get an invoice for my purchase?',
                description:'There are many variations of passages the majority have suffered alteration in some fo injected humour, or randomised words believable. Phasellus a rhoncus erat. Vivamus vel eros vitae est aliquet pellentesque vitae.'
            },
            {
                name:'Will you be updating your theme weekly?',
                description:'There are many variations of passages the majority have suffered alteration in some fo injected humour, or randomised words believable. Phasellus a rhoncus erat. Vivamus vel eros vitae est aliquet pellentesque vitae.'
            },
            {
                name:'How much do I need to pay for the IT services?',
                description:'There are many variations of passages the majority have suffered alteration in some fo injected humour, or randomised words believable. Phasellus a rhoncus erat. Vivamus vel eros vitae est aliquet pellentesque vitae.'
            },
            {
                name:'What can you do if we hire a IT advisor for business?',
                description:'There are many variations of passages the majority have suffered alteration in some fo injected humour, or randomised words believable. Phasellus a rhoncus erat. Vivamus vel eros vitae est aliquet pellentesque vitae.'
            }
        ]
    },
    {
        name:'Payments plain',
        icon:'faq-payment.png',
        description:'Phasellus tristique lorem et nulla tincidunt faucibus.',
        faqs:[
            {
                name:'What is a managed IT service provider?',
                description:'There are many variations of passages the majority have suffered alteration in some fo injected humour, or randomised words believable. Phasellus a rhoncus erat. Vivamus vel eros vitae est aliquet pellentesque vitae.'
            },
            {
                name:'What makes your company different?',
                description:'There are many variations of passages the majority have suffered alteration in some fo injected humour, or randomised words believable. Phasellus a rhoncus erat. Vivamus vel eros vitae est aliquet pellentesque vitae.'
            },
            {
                name:'Can I get an invoice for my purchase?',
                description:'There are many variations of passages the majority have suffered alteration in some fo injected humour, or randomised words believable. Phasellus a rhoncus erat. Vivamus vel eros vitae est aliquet pellentesque vitae.'
            },
            {
                name:'Will you be updating your theme weekly?',
                description:'There are many variations of passages the majority have suffered alteration in some fo injected humour, or randomised words believable. Phasellus a rhoncus erat. Vivamus vel eros vitae est aliquet pellentesque vitae.'
            },
            {
                name:'How much do I need to pay for the IT services?',
                description:'There are many variations of passages the majority have suffered alteration in some fo injected humour, or randomised words believable. Phasellus a rhoncus erat. Vivamus vel eros vitae est aliquet pellentesque vitae.'
            },
            {
                name:'What can you do if we hire a IT advisor for business?',
                description:'There are many variations of passages the majority have suffered alteration in some fo injected humour, or randomised words believable. Phasellus a rhoncus erat. Vivamus vel eros vitae est aliquet pellentesque vitae.'
            }
        ]
    },
    {
        name:'Account setting',
        icon:'faq-account.png',
        description:'Phasellus tristique lorem et nulla tincidunt faucibus.',
        faqs:[
            {
                name:'What is a managed IT service provider?',
                description:'There are many variations of passages the majority have suffered alteration in some fo injected humour, or randomised words believable. Phasellus a rhoncus erat. Vivamus vel eros vitae est aliquet pellentesque vitae.'
            },
            {
                name:'What makes your company different?',
                description:'There are many variations of passages the majority have suffered alteration in some fo injected humour, or randomised words believable. Phasellus a rhoncus erat. Vivamus vel eros vitae est aliquet pellentesque vitae.'
            },
            {
                name:'Can I get an invoice for my purchase?',
                description:'There are many variations of passages the majority have suffered alteration in some fo injected humour, or randomised words believable. Phasellus a rhoncus erat. Vivamus vel eros vitae est aliquet pellentesque vitae.'
            },
            {
                name:'Will you be updating your theme weekly?',
                description:'There are many variations of passages the majority have suffered alteration in some fo injected humour, or randomised words believable. Phasellus a rhoncus erat. Vivamus vel eros vitae est aliquet pellentesque vitae.'
            }
        ]
    },
    {
        name:'Legal information',
        icon:'faq-ligal-info.png',
        description:'Phasellus tristique lorem et nulla tincidunt faucibus.',
        faqs:[
            {
                name:'What is a managed IT service provider?',
                description:'There are many variations of passages the majority have suffered alteration in some fo injected humour, or randomised words believable. Phasellus a rhoncus erat. Vivamus vel eros vitae est aliquet pellentesque vitae.'
            },
            {
                name:'How much do I need to pay for the IT services?',
                description:'There are many variations of passages the majority have suffered alteration in some fo injected humour, or randomised words believable. Phasellus a rhoncus erat. Vivamus vel eros vitae est aliquet pellentesque vitae.'
            },
            {
                name:'What can you do if we hire a IT advisor for business?',
                description:'There are many variations of passages the majority have suffered alteration in some fo injected humour, or randomised words believable. Phasellus a rhoncus erat. Vivamus vel eros vitae est aliquet pellentesque vitae.'
            }
        ]
    }
]
export default faqData