import React from 'react';
import { Row, Col, Typography, Image, Collapse } from "antd";
import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll';
import "./index.css";
import { Helmet } from 'react-helmet';
import { BreadCrumb } from '../../ui-elements/index';
import ShareBlog from '../shareBlog';
import ShareMidButton from '../ShareMidButton';

const { Title } = Typography;
const { Panel } = Collapse;

const Blog5 = () => {
    const title = "Smart Contracts - Mkaits Blockchain Blog";
    const description = "Explore the world of smart contracts and how they are revolutionizing agreements.";
    const image = "https://www.mkaits.com/assets/blog5cover.jpg";
    const url = "https://www.mkaits.com/blogs/blockchain/smart-contracts";

    React.useEffect(() => {
        Events.scrollEvent.register('begin', function () {
            console.log("begin", arguments);
        });

        Events.scrollEvent.register('end', function () {
            console.log("end", arguments);
        });

        scrollSpy.update();

        return () => {
            Events.scrollEvent.remove('begin');
            Events.scrollEvent.remove('end');
        };
    }, []);

    return (
        <>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
                <meta name="robots" content="index, follow" />
                <meta name="author" content="Mkaits Technology" />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
                <meta property="og:image" content={image} />
                <meta property="og:url" content={url} />
                <meta property="og:type" content="website" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={title} />
                <meta name="twitter:description" content={description} />
                <meta name="twitter:image" content={image} />
            </Helmet>
            <BreadCrumb title='Blogs' description='Your Business Partner' pageName='Smart Contracts' breadcrumb={[{ to: '/blogs', name: 'Blogs' }]} />
            <div className=''>
                <ShareBlog url={"/blogs/blockchain/smart-contracts"} />
            </div>
            <div className="py-5 px-lg-5 px-md-5 px-sm-0">
                <Row>
                    <Col xs={0} sm={0} md={0} lg={6} className="toc-container px-3 my-3 h-50">
                        {/* Links for scrolling */}
                        <h2 className="text-black fw-bold">Table of Contents</h2>
                        <div className='px-2 mx-2 border-black'>
                            <Link activeClass="activeTab" to="section1" spy={true} smooth={true} duration={50}>
                                Smart Contracts
                            </Link>
                            <br />
                            <br />
                            <Link activeClass="activeTab" to="section2" spy={true} smooth={true} duration={50}>
                                Conclusion
                            </Link>
                            {/* <br />
                            <br />
                            <Link activeClass="activeTab" to="section3" spy={true} smooth={true} duration={50}>
                                Revolutionizing Supply Chains
                            </Link>
                            <br />
                            <br />
                            <Link activeClass="activeTab" to="section4" spy={true} smooth={true} duration={50}>
                                Empowering Identity Management
                            </Link>
                            <br />
                            <br />
                            <Link activeClass="activeTab" to="section5" spy={true} smooth={true} duration={50}>
                                Automating Agreements with Smart Contracts
                            </Link>
                            <br />
                            <br />
                            <Link activeClass="activeTab" to="section6" spy={true} smooth={true} duration={50}>
                                Decentralizing the Future
                            </Link> */}
                        </div>
                    </Col>
                    <Collapse accordion className="d-md-none w-100 mx-3 position-sticky z_i">
                        <Panel header="Table of Contents" key="1" className='py-2 ' >
                            <div className='d-flex flex-column gap-3 border-black mx-3 px-3'>
                                <Link activeClass="activeTab" to="section1" spy={true} smooth={true} duration={50}>
                                    Smart Contracts
                                </Link>
                                <Link activeClass="activeTab" to="section2" spy={true} smooth={true} duration={50}>
                                    Conclusion
                                </Link>
                                {/* <Link activeClass="activeTab" to="section3" spy={true} smooth={true} duration={50}>
                                    Revolutionizing Supply Chains
                                </Link>
                                <Link activeClass="activeTab" to="section4" spy={true} smooth={true} duration={50}>
                                    Empowering Identity Management
                                </Link>
                                <Link activeClass="activeTab" to="section5" spy={true} smooth={true} duration={50}>
                                    Automating Agreements with Smart Contracts
                                </Link>
                                <Link activeClass="activeTab" to="section6" spy={true} smooth={true} duration={50}>
                                    Decentralizing the Future
                                </Link> */}
                            </div>

                        </Panel>
                    </Collapse>
                    <Col xs={24} sm={24} md={24} lg={14} className="content px-3 my-3">
                        <Element name="section1" className="element">
                            <Title className='text-black mb-1 fs-1'>
                                Smart Contracts
                            </Title>
                            <p>Ah, smart contracts. The future of contracts, but with a sprinkle of intelligence. Sounds fancy, right? But what exactly are smart contracts and why should we care? Let’s break it down in a way that even your grandma could understand (well, maybe).</p>
                            <p><span className='fst-italic fw-bold'>Picture this:</span> you want to buy a cute little unicorn plushie online. You place your order and pay the seller in Bitcoin. But how can you be sure that the seller will actually send you the plushie? Enter smart contracts. These digital contracts are like your trusty sidekick, ensuring that both parties fulfill their end of the deal. It’s like having a virtual referee making sure everyone plays nice.</p>
                        </Element>
                        <Element name="" className="element">
                            {/* <Title className='text-black mb-1 fs-4'>
                                The Blockchain Breakthrough
                            </Title> */}
                            <p><span className='fw-bolder'>But how do these smart contracts work? </span> Well, they are self-executing contracts with the terms of the agreement directly written into lines of code. Think of it as a digital version of your typical legal contract, but without the need for lawyers (sorry lawyers, we still love you). Once the terms are met, bam! The contract executes itself and the agreed upon action is carried out. It’s like magic, but with less rabbits and more coding. </p>
                        </Element>
                        <Element name="section3" className="element">
                            {/* <Title className='text-black mb-1 fs-4'>
                                Revolutionizing Supply Chains
                            </Title> */}
                            <p>Now, you might be wondering, <span className='fw-bolder'>“But what if someone tries to cheat the system?” </span>Ah, my dear friend, that’s where the beauty of blockchain technology comes into play. Smart contracts are stored on a blockchain, which is a decentralized and tamper-proof digital ledger. This means that once a smart contract is deployed, it cannot be altered or tampered with. So sorry scammers, no loopholes for you.</p> <br />
                            <div className='custom-width-sm custom-width-md-lg m-auto'>
                                <Image src="/assets/blog5a.jpg" alt="Blockchain" className='img-fluid w-100' preview={false} />
                            </div>
                            <br />
                        </Element>
                        <br />
                        <Element name="section5" className="element">
                            {/* <Title className='text-black mb-1 fs-4'>
                                Automating Agreements with Smart Contracts
                            </Title> */}
                            <p>Now, let’s talk about some of the real-world applications of smart contracts. From insurance claims to real estate transactions, these digital contracts have the potential to revolutionize how we do business. Imagine a world where contracts are executed seamlessly, without the need for middlemen or lengthy legal processes. It’s like every lawyer’s nightmare, but a dream come true for efficiency lovers.</p>
                        </Element>
                        <Element name="section6" className="element">
                            <br />
                            {/* <Title className='text-black my-3 fs-4'>
                                Decentralizing the Future
                            </Title> */}
                            <p> <span className='fw-bolder'>But hey, let’s not get ahead of ourselves.</span>Smart contracts are still in their early stages and there are certainly some kinks to iron out. Issues like security vulnerabilities and scalability challenges still exist. So, before you start converting all your contracts into smart contracts, maybe have a chat with a human lawyer first.</p>
                        </Element>
                        <br />
                        <Element name="section2" className="element">
                            {/* <Title className='text-black mb-1 fs-4'>
                                Empowering Identity Management
                            </Title> */}
                            <p className='bg_grey p-lg-5 p-3 rounded-3 fst_italic'> <span className='fs-1'>‟</span> <br /> <span className='fw-bold fs-3'>Conclusion</span> <br /> <br />
                                In conclusion, smart contracts are like the cool kids on the block, revolutionizing the way we make agreements in the digital age. They’re efficient, transparent, and heck, they even sound fancy. So here’s to a future where contracts are no longer a hassle, but a piece of cake (or maybe a slice of unicorn plushie?). Cheers to the future of contracts, smart style.
                            </p>
                        </Element>
                    </Col>
                </Row>
                <div className='d-flex justify-content-center mt-3'>
                    <ShareMidButton url={"/blogs/blockchain/smart-contracts"} />
                </div>
            </div>
        </>
    );
}

export default Blog5;
