import React, { useState } from 'react'
import './index.css'
import { BreadCrumb } from '../../ui-elements/index';
import { DataProjectDetail } from './DataProjectDetail';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons'


const ProjectDetail = () => {
    const [dataprojectdetail, setDataProjectDetail] = useState(DataProjectDetail)
    const [selectdetail, setSelectDetail] = useState(0)
  return (
    <div>
        <BreadCrumb title='Projects Details'  pageName='Projects ' breadcrumb={[{to:'/',name:'Home'}]}/>
        <section className="project-details">
            <div className="container">
                <div className="project-details__top">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="project-details__img-box">
                                <img src="assets/project/project-details-img.jpg" alt="" />
                                <div className="project-details__details-box">
                                    <ul className="list-unstyled project-details__details">
                                        {
                                            dataprojectdetail?.[selectdetail]?.clientdetail?.map((clientd, cd)=>
                                                <li key={cd}>
                                                    <div className="project-details__details-content">
                                                        <span className="project-details__details-title">{clientd?.clienttitle}</span>
                                                        <p className="project-details__details-name">{clientd?.clientname}</p>
                                                    </div>
                                                </li>
                                            )
                                        }
                                    </ul>
                                </div>
                            </div>
                            <div className="project-details__content-one">
                                <h3 className="project-details__content-one-title">
                                    {
                                        dataprojectdetail?.[selectdetail]?.contentone?.onetitle
                                    }
                                </h3>
                                <p className="project-details__content-one-text">
                                    {
                                        dataprojectdetail?.[selectdetail]?.contentone?.onedescription
                                    }
                                </p>
                            </div>
                            <div className="project-details__content-two">
                                <h3 className="project-details__content-two-title">
                                    {
                                        dataprojectdetail?.[selectdetail]?.contenttwo?.twotitle
                                    }
                                </h3>
                                <p className="project-details__content-two-text">
                                    {
                                        dataprojectdetail?.[selectdetail]?.contenttwo?.twodescription
                                    }
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row align-center">
                        <div className="col-xl-5">
                            <div className="project-details__points-img">
                                <img src={"assets/"+dataprojectdetail?.[selectdetail]?.clientimg} alt="" />
                            </div>
                        </div>
                        <div className="col-xl-7">
                            <ul className="list-unstyled project-details__points">
                                {
                                    dataprojectdetail?.[selectdetail]?.list?.map((list,li)=>
                                    <li>
                                        <div className="icon">
                                            <span>
                                                {
                                                    list?.listicon
                                                }
                                            </span>
                                        </div>
                                        <div className="text">
                                            <p>
                                                {
                                                    list?.listtext
                                                }
                                            </p>
                                        </div>
                                    </li>
                                    )
                                }
                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="project-details__content-three">
                                <h3 className="project-details__content-three-title">
                                    {
                                        dataprojectdetail?.[selectdetail]?.contentthree?.threetitle
                                    }
                                </h3>
                                <p className="project-details__content-three-text">
                                    {
                                        dataprojectdetail?.[selectdetail]?.contentthree?.threedescription
                                    }
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="projectc-details__pagination-box">
                                <ul className="projectc-details__pagination list-unstyled clearfix">
                                    <li className="next">
                                        <div className="icon">
                                            <a href="#" aria-label="Previous">
                                                <i>
                                                    <ArrowRightOutlined />
                                                </i>
                                            </a>
                                        </div>
                                        <div className="content">
                                            <span>Previous post</span>
                                            <p className='mb-0'>Cyber security</p>
                                        </div>
                                    </li>
                                    <li className="count"><a href="#"></a></li>
                                    <li className="count"><a href="#"></a></li>
                                    <li className="count"><a href="#"></a></li>
                                    <li className="count"><a href="#"></a></li>
                                    <li className="previous">
                                        <div className="content">
                                            <span>Next post</span>
                                            <p className='mb-0'>Tech solutions</p>
                                        </div>
                                        <div className="icon">
                                            <a href="#" aria-label="Previous">
                                                <i>
                                                    <ArrowRightOutlined />
                                                </i>
                                                
                                            </a>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}

export default ProjectDetail