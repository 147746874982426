import { Link } from 'react-router-dom';
import ReplaButton from '../buttons';
import { SectionTop } from '../../ui-elements';
import Slider from "react-slick";
import { Typography, Image, Space } from 'antd';
import { ClockCircleOutlined, UserOutlined } from "@ant-design/icons"
import "./index.css"
const NewsSlider = () => {
    const news = [
        {
            title: "The Role of IoT in Mobile App Development: Connecting Devices and People",
            date: '25 October 2024',
            url: 'mobileblog4cover.jpg',
            type: 'Mobile App Development',
            route: '/blogs/mobile-app-development/role-of-iot-in-mobile-app-development',
        },
        {
            title: "Mobile App Development Services We Offer: From Concept to Launch",
            date: '12 October 2024',
            url: 'mobileblog2cover.jpg',
            type: 'Mobile App Development',
            route: '/blogs/mobile-app-development/mobile-app-development-services-we-offer-from-concept-to-launch',
        },
        {
            title: " The Future of Big Data Analytics: What’s on the Horizon?",
            date: '28 September 2024',
            url: 'datablog7cover.jpg',
            type: 'Data Analytics',
            route: '/blogs/data-analytics/the-future-of-big-data-analytics'
        },
        {
            title: "The Impact of Big Data on Marketing Strategies",
            date: '16 September 2024',
            url: 'datablog6cover.jpg',
            type: 'Data Analytics',
            route: '/blogs/data-analytics/impact-of-big-data-on-marketing-strategies'
        },
        {
            title: 'How to Begin Learning AI Skills?',
            date: '23 July 2024',
            url: 'aiblog2cover.jpg',
            type: 'Artificial Intelligence',
            route: '/blogs/artificial-intelligence/how-to-begin-learning-ai-skills'
        },
        {
            title: 'What is coming in the world of AI in 2024?',
            date: '17 July 2024',
            url: 'aiblog1cover.jpg',
            type: 'Artificial Intelligence',
            route: '/blogs/artificial-intelligence/what-is-coming-in-the-world-of-ai-in-2024'
        },
        {
            title: 'Exploring the World of Dapps: The Future of Decentralized Applications',
            date: '13 July 2024',
            url: 'blog9cover.jpg',
            type: 'Blockchain',
            route: '/blogs/blockchain/exploring-the-world-of-dapps-the-future-of-decentralized-applications'
        },
        {
            title: 'DeFi: Reshaping the Financial Landscape',
            date: '09 July 2024',
            url: 'blog8cover.jpg',
            type: 'Blockchain',
            route: '/blogs/blockchain/defi-reshaping-the-financial-landscape'
        },
    ]
    var settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,

        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 0
                }
            }
        ]
    }
    return (
        <>
            <div className="py-5" style={{ overflowX: 'hidden' }}>
                <div className='py-5 flex-col-center'>
                    <SectionTop title='' description='News & Blogs' />
                    <div style={{ width: '93%' }}>
                        <Slider {...settings}>
                            {
                                news?.map((news, n) => {
                                    return (
                                        <Link to={news.route} className='px-2' key={'news-' + n}>
                                            <div className=" rounded-1 news-slider">
                                                <div className='news-slider-image-box'>
                                                    <Image src={'/assets/' + news?.url} preview={false} alt='Mkaits' className='img-fluid one animation' style={{ maxWidth: '100%', maxHeight: '100%' }} />
                                                    <div className='two animation'>
                                                        <Image src={'/assets/plus.png'} alt='olive' />
                                                    </div>
                                                    <span className='tag px-3 py-1'>
                                                        <Typography.Text strong className='text-white'>
                                                            {
                                                                news?.type
                                                            }
                                                        </Typography.Text>
                                                    </span>
                                                </div>
                                                <Space direction='vertical' className='align-center py-3 px-2' style={{ width: '100%' }}>
                                                    <Space direction='horizontal' size={10}>
                                                        <Space>
                                                            <ClockCircleOutlined
                                                                style={{ transform: 'translateY(-4px)' }}
                                                                className='brand-color'
                                                            />
                                                            <Typography.Text className='my-0' strong> {news.date} </Typography.Text>
                                                        </Space>
                                                        <Space>
                                                            <UserOutlined
                                                                style={{ transform: 'translateY(-4px)' }}
                                                                className='brand-color'
                                                            />
                                                            <Typography.Text className='my-0' strong>By Admin</Typography.Text>
                                                        </Space>
                                                    </Space>
                                                    <Typography.Title className='w-100 my-0 text-center news-heading' level={4}>
                                                        {
                                                            news?.title
                                                        }
                                                    </Typography.Title>
                                                </Space>
                                            </div>
                                        </Link>
                                    )
                                })
                            }
                        </Slider>
                    </div>
                </div>
                <div className='pt-5 d-flex justify-content-center w-100'>
                    <Link to={"/blogs"} style={{ width: '300px' }}>
                        <ReplaButton title='See all Blogs' />
                    </Link>
                </div>
            </div>
        </>
    )
}

export default NewsSlider;
