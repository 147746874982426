import React from 'react';
import { Row, Col, Typography, Image, Collapse } from "antd";
import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll';
import "./index.css";
import {
    Helmet
} from 'react-helmet';
import { BreadCrumb } from '../../ui-elements/index';
import ShareBlog from '../shareBlog';
import ShareMidButton from '../ShareMidButton';
const { Title } = Typography;
const { Panel } = Collapse;

const Blog6 = () => {
    const title = "DAO - Mkaits Blockchain";
    const description = "Explore the fascinating world of DAOs (Decentralized Autonomous Organizations) and their impact on governance and collaboration.";
    const image = "https://www.mkaits.com/assets/blog6cover.jpg";
    const url = "https://www.mkaits.com/blogs/blockchain/decentralized-autonomous-organization";
    React.useEffect(() => {
        Events.scrollEvent.register('begin', function () {
            console.log("begin", arguments);
        });

        Events.scrollEvent.register('end', function () {
            console.log("end", arguments);
        });

        scrollSpy.update();

        return () => {
            Events.scrollEvent.remove('begin');
            Events.scrollEvent.remove('end');
        };
    }, []);

    return (

        <>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
                <meta name="robots" content="index, follow" />
                <meta name="author" content="Mkaits Technology" />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
                <meta property="og:image" content={image} />
                <meta property="og:url" content={url} />
                <meta property="og:type" content="website" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={title} />
                <meta name="twitter:description" content={description} />
                <meta name="twitter:image" content={image} />
            </Helmet>
            <BreadCrumb title='Blogs' description='Your Business Partner' pageName='DAO' breadcrumb={[{ to: '/blogs', name: 'Blogs' }]} />
            <div className=''>
                <ShareBlog url={"/blogs/blockchain/decentralized-autonomous-organization"} />
            </div>
            <div className="py-5 px-lg-5 px-md-5 px-sm-0">
                <Row>
                    <Col xs={0} sm={0} md={0} lg={6} className="toc-container px-3 my-3 h-50">
                        {/* Links for scrolling */}
                        <h2 className="text-black fw-bold">Table of Contents</h2>
                        <div className='px-2 mx-2 border-black'>
                            <Link activeClass="activeTab" to="section1" spy={true} smooth={true} duration={50}>
                                DAO
                            </Link>
                            <br />
                            <br />
                            <Link activeClass="activeTab" to="section2" spy={true} smooth={true} duration={50}>
                                Quick Points
                            </Link>
                            <br />
                            <br />
                            <Link activeClass="activeTab" to="section3" spy={true} smooth={true} duration={50}>
                                Example
                            </Link>
                            {/* <br />
                            <br />
                            <Link activeClass="activeTab" to="section3" spy={true} smooth={true} duration={50}>
                                Revolutionizing Supply Chains
                            </Link>
                            <br />
                            <br />
                            <Link activeClass="activeTab" to="section4" spy={true} smooth={true} duration={50}>
                                Empowering Identity Management
                            </Link>
                            <br />
                            <br />
                            <Link activeClass="activeTab" to="section5" spy={true} smooth={true} duration={50}>
                                Automating Agreements with Smart Contracts
                            </Link>
                            <br />
                            <br />
                            <Link activeClass="activeTab" to="section6" spy={true} smooth={true} duration={50}>
                                Decentralizing the Future
                            </Link> */}
                        </div>
                    </Col>
                    <Collapse accordion className="d-md-none w-100 mx-3 position-sticky z_i">
                        <Panel header="Table of Contents" key="1" className='py-2 ' >
                            <div className='d-flex flex-column gap-3 border-black mx-3 px-3'>
                                <Link activeClass="activeTab" to="section1" spy={true} smooth={true} duration={50}>
                                    DAO
                                </Link>
                                <Link activeClass="activeTab" to="section2" spy={true} smooth={true} duration={50}>
                                    Quick Points
                                </Link>
                                <Link activeClass="activeTab" to="section3" spy={true} smooth={true} duration={50}>
                                    Example
                                </Link>
                                {/* <Link activeClass="activeTab" to="section3" spy={true} smooth={true} duration={50}>
                                    Revolutionizing Supply Chains
                                </Link>
                                <Link activeClass="activeTab" to="section4" spy={true} smooth={true} duration={50}>
                                    Empowering Identity Management
                                </Link>
                                <Link activeClass="activeTab" to="section5" spy={true} smooth={true} duration={50}>
                                    Automating Agreements with Smart Contracts
                                </Link>
                                <Link activeClass="activeTab" to="section6" spy={true} smooth={true} duration={50}>
                                    Decentralizing the Future
                                </Link> */}
                            </div>

                        </Panel>
                    </Collapse>
                    <Col xs={24} sm={24} md={24} lg={14} className="content px-3 my-3">
                        <Element name="section1" className="element">
                            <Title className='text-black mb-4 fs-1'>
                                Decentralized Autonomous Organization (DAO)
                            </Title>
                            <p> <span className='fw-bold mt-3'>Decentralized Autonomous Organization:</span>The Future of Organizational Structure or Just a Fancy Buzzword?</p>
                            <p className='mt-3'>Hey there, fellow tech enthusiasts and crypto nerds! Today, we're diving into the wild world of decentralized autonomous organizations (DAOs). But don't worry, we'll try to keep it light and fun. So grab your favorite snack and settle in for a wild ride through the decentralized landscape.</p>
                        </Element>
                        <Element name="" className="element">
                            {/* <Title className='text-black mb-1 fs-4'>
                                The Blockchain Breakthrough
                            </Title> */}
                            <p>First off, let's break down what exactly a DAO is in a few quick points:</p>
                        </Element>
                        <br />
                        <Element name="section2" className="element">
                            {/* <Title className='text-black mb-1 fs-4'>
                                Empowering Identity Management
                            </Title> */}
                            <p className='bg_grey p-lg-5 p-3 rounded-3 fst_italic'> <span className='fs-1'>‟</span> <br /> <span className='fw-bold fs-3'>Quick Points:</span> <br /> <br /> <span className='fw-bold'>1. Decentralized: </span> This means that there is no central authority or control over the organization. Instead, decision-making and governance are spread out among the participants, often through the use of blockchain technology. <br />
                                <span className='fw-bold'>2. Autonomous:</span>  As the name suggests, DAOs are designed to be self-governing and self-operating. This means that they can carry out tasks and make decisions without the need for human intervention. <br />
                                <span className='fw-bold'>3. Organization:</span>: Just like traditional organizations, DAOs have a structure and purpose. They can be used for a wide range of purposes, from managing funds to governing a community or project.


                            </p>
                        </Element>
                        <br />
                        <Element name="section3" className="element">
                            {/* <Title className='text-black mb-1 fs-4'>
                                Revolutionizing Supply Chains
                            </Title> */}
                            <p>Now that we've got the basics out of the way, let's talk about some of the fun and wacky things that can happen in the world of DAOs. Trust me, it's not all serious business and high-stakes decisions.</p> <br />
                            <div className='custom-width-sm custom-width-md-lg m-auto'>
                                <Image src="/assets/blog6b.jpg" alt="Blockchain" className='img-fluid w-100' preview={false} />
                            </div>
                            <br />
                        </Element>
                        <br />
                        <Element name="section5" className="element">
                            {/* <Title className='text-black mb-1 fs-4'>
                                Automating Agreements with Smart Contracts
                            </Title> */}
                            <p>Imagine a group of people coming together to create a DAO for the sole purpose of deciding where to order lunch from every day. That's right, a decentralized, autonomous organization dedicated to the important task of choosing between pizza, burgers, or tacos. Talk about a game-changer in the world of office lunch decisions!</p>
                        </Element>
                        <Element name="section6" className="element">
                            <br />
                            {/* <Title className='text-black my-3 fs-4'>
                                Decentralizing the Future
                            </Title> */}
                            <br />
                            <div className='custom-width-sm custom-width-md-lg m-auto'>
                                <Image src="/assets/blog6a.jpg" alt="Blockchain" className='img-fluid w-100' preview={false} />
                            </div>
                            <br />
                            <p>Or how about a DAO that's tasked with managing a community garden? Members could vote on what to plant, how to care for the plants, and even how to divide up the harvest. It's like the ultimate gardening club, but with blockchain technology backing it up. </p>
                            <br />
                            <p>And let's not forget about the potential for DAOs in the world of art and creativity. Picture a collective of artists coming together to create a decentralized organization that funds and supports creative projects. No more dealing with gatekeepers or middlemen – just pure, unfiltered artistic expression.</p>
                            <br />
                            <p>But of course, it's not all sunshine and rainbows in the world of DAOs. Just like any new and exciting technology, there are plenty of opportunities for things to go hilariously wrong. Imagine a DAO that accidentally votes to allocate all of its funds to a project that turns out to be a complete disaster. Cue the virtual facepalms and "I told you so" comments.</p>
                        </Element>
                        <br />
                    </Col>
                </Row>
                <div className='d-flex justify-content-center mt-3'>
                    <ShareMidButton url={"/blogs/blockchain/decentralized-autonomous-organization"} />
                </div>
            </div>
        </>
    );
}

export default Blog6;
