import AboutRepla from '../../components/aboutRepla'
import Profiles from '../../components/profiles';
import BusinessPartner from '../../components/businessPartner';
import { BreadCrumb } from '../../ui-elements/index';
import CounterBct from '../../components/counter/index'
import { Helmet } from 'react-helmet';
import WhyBCT from '../../components/whyBCT';
const AboutUs = () => {
    const title = "Mkaits - About Us";
    const description = "Providing Innovative Technology Solutions and Expert Brand Image to Achieve Business Goals";
    const image = "https://www.mkaits.com/assets/logo.png";
    const url = "https://www.mkaits.com/about-us";
    return <>
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta name="robots" content="index, follow" />
            <meta name="author" content="Mkaits Technology" />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={image} />
            <meta property="og:url" content={url} />
            <meta property="og:type" content="website" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content={image} />
        </Helmet>
        <BreadCrumb title='About Mkaits' description='Your Business Partner' pageName='About Us' breadcrumb={[{ to: '/', name: 'Home' }]} />
        <AboutRepla />
        <BusinessPartner />
        <Profiles />
        <WhyBCT />

    </>
}
export default AboutUs;
