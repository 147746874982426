import { LaptopOutlined,EditOutlined,PieChartOutlined,SnippetsOutlined } from '@ant-design/icons';

const Offerdata = [
    {
        title: "Product development",
        description: "Lorem dolor sit amet do.",
        icon: <LaptopOutlined />,
        btntext:"Learn more"
    },
    {
        title: "UI/UX designing",
        description: "Lorem dolor sit amet do.",
        icon: <EditOutlined />,
        btntext:"Learn more"
    },
    {
        title: "Digital marketing",
        description: "Lorem dolor sit amet do.",
        icon: <PieChartOutlined />,
        btntext:"Learn more"
    },
    {
        title: "Content management",
        description: "Lorem dolor sit amet do.",
        icon: <SnippetsOutlined />,
        btntext:"Learn more"
    },
]
export default Offerdata;