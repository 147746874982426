import React from 'react'
import {Typography, Space, Image } from 'antd'
import './index.css';
import Datafeed from './Datafeed.js'
import Slider from "react-slick";
import { SectionTop } from '../../ui-elements';
const {Title}=Typography
function Feedback() {
    var settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 0,
        
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 0
            }
          }
        ]
      }
  return (
    <div className="light-bg py-5 w-100" style={{overflowX:'hidden'}}>
        <div className='py-5 flex-col-center'>
            <SectionTop title='Our client feedbacks' description='What they’re talking?'/>
            <Slider {...settings} style={{width:'93%'}}>
                {
                    Datafeed?.map((feedback,fd)=>
                    <div key={'feedback-'+fd}>
                        <div className='mx-3 py-5 bg-white feedback-box animation'>
                            <div className='bg-img animation'>
                                <Image src='/assets/testimonial-two-shape.png' alt="Mkaits" preview={false}/>
                            </div>
                            <Space direction='vertical' size={25}>
                                <Space className='w-100 align-center' direction='vertical' size={20}>
                                    <Image src={"/assets/"+feedback?.topicon} alt="Mkaits-feedback" preview={false}/>
                                    <p className='px-5 text-center'>
                                        {
                                            feedback?.description
                                        }
                                    </p>
                                </Space>
                                <Space className='w-100 align-center' direction='vertical' size={10}>
                                    <Image src={"/assets/"+feedback?.imageu} alt="Mkaits-client" style={{borderRadius:'60%'}} preview={false}/>
                                    <Title level={5} className='my-0'>
                                        {
                                            feedback?.name
                                        }
                                    </Title>
                                    <p className='my-0'>
                                        {
                                            feedback?.jobtitle
                                        }
                                    </p>
                                    <Image src='/assets/stars.png' alt='Mkaits-client-feedback' preview={false}/>
                                </Space>
                            </Space>
                        </div>
                    </div>
                    )
                }                                        
            </Slider>
        </div>
    </div>
  )
}

export default Feedback