import React, { useState, useRef } from 'react';
import { Col, Row, Space, Typography, Button, Card, message, Form } from 'antd';
import { SectionTop } from '../../ui-elements';
import { StoreSingleFileUpload } from '../Forms';
import './index.css';
import { Audio } from './Audio';
import emailjs from '@emailjs/browser';

const ContactUs = () => {
    const [loading, setLoading] = useState(false);
    const [audio, setAudio] = useState(null);
    const formRef = useRef();

    const data = [
        {
            count: '1',
            desc: 'Having received and processed your request, we will get back to you shortly to detail your project needs and sign an NDA to ensure the confidentiality of information.'
        },
        {
            count: '2',
            desc: 'After examining requirements, our analysts and developers devise a project proposal with the scope of works, team size, time and cost estimates.'
        },
        {
            count: '3',
            desc: 'We arrange a meeting with you to discuss the offer and come to an agreement.'
        },
        {
            count: '4',
            desc: 'We sign a contract and start working on your project as quickly as possible.'
        },
    ];

    const sendEmail = (e) => {
        e.preventDefault();
        setLoading(true);

        emailjs.sendForm('service_v5k42iu', 'template_ra6yolq', formRef.current, '7hfizV5Un-7Fvkr-L')
            .then((result) => {
                message.success('Email sent successfully!');
                setLoading(false);
                setAudio(null);
                formRef.current.reset();
            }, (error) => {
                message.error('Failed to send email.');
                console.error('EmailJS Error:', error);
                setLoading(false);
            });
    };

    const validateWordCount = (rule, value) => {
        const wordCount = value ? value.trim().split(/\s+/).length : 0;
        if (wordCount > 100) {
            return Promise.reject('The message must be up to 100 words.');
        }
        return Promise.resolve();
    };

    const inputStyle = {
        marginBottom: '16px'
    };

    return (
        <div className='flex-col-center my-5'>
            <SectionTop
                title='Contact us!'
                description='We’re Here to Help You'
            />
            <div className='width-93'>
                <Row gutter={[64, 24]} style={{ margin: 0 }}>
                    <Col md={{ span: 12 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                        <Space direction='vertical' size={30}>
                            <Typography.Title level={4} className='my-0'>
                                <a href='https://calendly.com/mughiraaa' target='_blank' className='brand-color f-weight-bold'>Book a call</a> or fill out the form below and we’ll get back to you once we’ve processed your request.
                            </Typography.Title>
                            <form
                                ref={formRef}
                                className='contact-us-form'
                                onSubmit={sendEmail}
                            >
                                <Row gutter={[16, 16]}>
                                    <Col md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                                        <input
                                            type="text"
                                            name="name"
                                            placeholder='Name'
                                            required
                                            className='border-bottom-only w-100 fs-5'
                                            style={inputStyle}
                                        />
                                    </Col>
                                    <Col md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                                        <input
                                            type="text"
                                            name="company"
                                            placeholder='Company Name'
                                            className='border-bottom-only w-100 fs-5'
                                            style={inputStyle}
                                        />
                                    </Col>
                                    <Col md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                                        <input
                                            type="email"
                                            name="email"
                                            placeholder='Email'
                                            required
                                            className='border-bottom-only w-100 fs-5'
                                            style={inputStyle}
                                        />
                                    </Col>
                                    <Col md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                                        <input
                                            type="text"
                                            name="phone"
                                            placeholder='Phone number'
                                            required
                                            className='border-bottom-only w-100 fs-5'
                                            style={inputStyle}
                                        />
                                    </Col>
                                    <Col md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                                        <select
                                            name="subject"
                                            required
                                            className='border-bottom-only w-100 fs-5'
                                            style={inputStyle}
                                        >
                                            <option value="" selected hidden>Please select a career</option>
                                            <option value="Web Development">Web Development</option>
                                            <option value="Mobile App Development">Mobile App Development</option>
                                            <option value="Blockchain Development">Blockchain Development</option>
                                            <option value="Web3 Development">Web3 Development</option>
                                            <option value="Artificial Intelligence">Artificial Intelligence</option>
                                            <option value="DApp Development">DApp Development</option>
                                            <option value="Custom Software Solutions">Custom Software Solutions</option>
                                        </select>
                                    </Col>
                                    <Col md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                                        <input
                                            type="number"
                                            name="projectBudget"
                                            placeholder='Project Budget'
                                            required
                                            className='border-bottom-only w-100 fs-5'
                                            style={inputStyle}
                                        />
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item
                                            name="message"
                                            rules={[{ required: true, validator: validateWordCount }]}
                                            style={inputStyle}
                                        >
                                            <label htmlFor="message" className='fs-5 my-3'>Description of your Project (Max 100 Words) <span style={{ color: 'red' }}>*</span>:</label>
                                            <textarea
                                                name="message"
                                                maxlength ="100"
                                                placeholder='Short Description of your Project...'
                                                required
                                                className='border-bottom-only w-100 fs-5'
                                                style={inputStyle}
                                            />
                                        </Form.Item>
                                        <Typography.Text className='gray-sm-text'>
                                            Please include project details, duration, tech stack, IT professionals needed, and other relevant info
                                        </Typography.Text>
                                    </Col>
                                    <Col span={24} style={inputStyle}>
                                        <div className='block-border'>
                                            <Typography.Text>
                                                Record a voice message about your project to help us understand it better
                                            </Typography.Text>
                                            <div className='center'>
                                                <Audio setAudio={setAudio} />
                                            </div>
                                            {
                                                audio ?
                                                    <Typography.Text style={{ color: "#BE3130" }}>
                                                        audio recording.webm
                                                    </Typography.Text>
                                                    : <></>
                                            }
                                        </div>
                                    </Col>
                                    <Col span={24} style={inputStyle}>
                                        <div className='block-border'>
                                            <Typography.Text>
                                                upload a file / doc about your project to help us understand it better
                                            </Typography.Text>
                                            <Space align='center' className='w-100' style={{ justifyContent: "center" }}>
                                                <StoreSingleFileUpload
                                                    name='file'
                                                    label={null}
                                                    form={formRef}
                                                />
                                            </Space>
                                        </div>
                                    </Col>
                                </Row>
                                <Row gutter={[24, 24]} className='justify-end mt-4'>
                                    <Col xs={{ span: 24 }} sm={{ span: 24 }}>
                                        <Button
                                            htmlType='submit'
                                            size='large'
                                            type='primary'
                                            className='brand-bg brand-border w-100'
                                            loading={loading}
                                        >
                                            Send
                                        </Button>
                                    </Col>
                                </Row>
                            </form>
                        </Space>
                    </Col>
                    <Col
                        md={{ span: 12 }} xs={{ span: 24 }} sm={{ span: 24 }}
                        className='align-center'
                    >
                        <Card className='card-block'>
                            <Space size={30} direction='vertical'>
                                <Typography.Title level={1}>What happens next?</Typography.Title>
                                <div className='list-circle'>
                                    <ul>
                                        {
                                            data?.map((datlst, ds) =>
                                                <li key={ds}>
                                                    <div className='circle-count'>{datlst?.count}</div>
                                                    {
                                                        datlst?.desc
                                                    }
                                                </li>
                                            )
                                        }
                                    </ul>
                                </div>
                                <Typography.Text className='gray-sm-text'>
                                    Please be informed that when you click the Send button Innowise will process your personal data in accordance with our Privacy Policy for the purpose of providing you with appropriate information.
                                </Typography.Text>
                            </Space>
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export { ContactUs };
