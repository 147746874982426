import React from 'react';
import { Row, Col, Typography, Image, Collapse } from "antd";
import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll';
import ShareBlog from '../shareBlog';
import ShareMidButton from '../ShareMidButton';
import {
    Helmet
} from 'react-helmet';
import { BreadCrumb } from '../../ui-elements/index';

const { Title } = Typography;
const { Panel } = Collapse;

const MobileBlog4 = () => {
    const title = "The Role of IoT in Mobile App Development: Connecting Devices and People | Mkaits";
    const description = "Discover how IoT devices revolutionize mobile app development ";
    const image = "https://www.mkaits.com/assets/mobileblog4cover.jpg";
    const url = "https://www.mkaits.com/blogs/mobile-app-development/role-of-iot-in-mobile-app-development";
    // Set up scroll behavior on component mount and clean up on unmount
    React.useEffect(() => {
        Events.scrollEvent.register('begin', function () {
            console.log("begin", arguments);
        });

        Events.scrollEvent.register('end', function () {
            console.log("end", arguments);
        });

        scrollSpy.update();

        return () => {
            Events.scrollEvent.remove('begin');
            Events.scrollEvent.remove('end');
        };
    }, []);

    return (
        <>
            <Helmet>
                <title>{title}</title>
                <meta name="title" content={title} />
                <meta name="description" content={description} />

                <meta property="og:type" content="website" />
                <meta property="og:url" content={url} />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
                <meta property="og:image" content={image} />

                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content={url} />
                <meta property="twitter:title" content={title} />
                <meta property="twitter:description" content={description} />
                <meta property="twitter:image" content={image} />

            </Helmet>
            <BreadCrumb title='Blogs' description='Your Business Partner' pageName="Role of IoT in Mobile Apps" breadcrumb={[{ to: '/blogs/mobile-app-development', name: 'Blogs' }]} />
            <div className="py-5 px-lg-5 px-md-5 px-sm-0">
                <div className=''>
                    <ShareBlog url={"/blogs/mobile-app-development/role-of-iot-in-mobile-app-development"} />
                </div>
                <Row>
                    <Col xs={0} sm={0} md={0} lg={6} className="toc-container px-3 my-3 h-50">
                        {/* Links for scrolling */}
                        <h2 className="text-black fw-bold">Table of Contents</h2>
                        <div className='px-2 mx-2 border-black'>
                            {/* <Link activeClass="activeTab" to="section1" spy={true} smooth={true} duration={50}>
                                A Glimpse into the Future with Mkaits
                            </Link>
                            <br />
                            <br /> */}
                            <Link activeClass="activeTab" to="section2" spy={true} smooth={true} duration={50}>
                                Smart Homes: More Than Just Buzzwords
                            </Link>
                            <br />
                            <br />
                            <Link activeClass="activeTab" to="section3" spy={true} smooth={true} duration={50}>
                                Healthcare: Empowering People and Professionals
                            </Link>
                            <br />
                            <br />
                            <Link activeClass="activeTab" to="section4" spy={true} smooth={true} duration={50}>
                                Industrial Automation: Smart Solutions for the Workplace
                            </Link>
                            <br />
                            <br />
                            <Link activeClass="activeTab" to="section5" spy={true} smooth={true} duration={50}>
                                Conclusion
                            </Link>
                        </div>
                    </Col>
                    <Collapse accordion className="d-md-none w-100 mx-3 position-sticky z_i">
                        <Panel header="Table of Contents" key="1" className='py-2 ' >
                            <div className='d-flex flex-column gap-3 border-black mx-3 px-3'>
                                {/* <Link activeClass="activeTab" to="section1" spy={true} smooth={true} duration={50}>
                                    A Glimpse into the Future with Mkaits
                                </Link> */}
                                <Link activeClass="activeTab" to="section2" spy={true} smooth={true} duration={50}>
                                    Smart Homes: More Than Just Buzzwords
                                </Link>
                                <Link activeClass="activeTab" to="section3" spy={true} smooth={true} duration={50}>
                                    Healthcare: Empowering People and Professionals
                                </Link>
                                <Link activeClass="activeTab" to="section4" spy={true} smooth={true} duration={50}>
                                    Industrial Automation: Smart Solutions for the Workplace
                                </Link>
                                <Link activeClass="activeTab" to="section5" spy={true} smooth={true} duration={50}>
                                    Conclusion
                                </Link>
                            </div>

                        </Panel>
                    </Collapse>
                    <Col xs={24} sm={24} md={24} lg={14} className="content px-3 my-3">
                        <Title className='text-black mb-1 fs-1'>
                            The Role of IoT in Mobile App Development: Connecting Devices and People
                        </Title>
                        <br />
                        <p>Hey there, tech enthusiasts! Today, let’s dive into the exciting intersection of the Internet of Things (IoT) and mobile app development. You’ve probably heard of IoT—those nifty devices that talk to each other and make our lives easier. But how do they connect with mobile apps, and what’s in it for us? Let’s explore this dynamic duo and see how they’re reshaping smart homes, healthcare, and industrial automation.</p>
                        <br />
                        <Element name="section1" className="element">
                        </Element>
                        <div className='custom-width-sm custom-width-md-lg m-auto'>
                            <Image src="/assets/mobileblog4a.jpg" alt="Data Analytics" className='img-fluid w-100' preview={false} />
                        </div>
                        <br />
                        <Element name="section2" className="element">
                            <Title className='text-black mb-1 fs-4'>
                                Smart Homes: More Than Just Buzzwords
                            </Title>
                            <br />
                            <p>Picture this: you’re sprawled out on your couch after a long day, and all you want to do is kick back and relax. With IoT-enabled devices working seamlessly with mobile apps, you can control your home environment without lifting a finger. Lights, thermostat, security cameras—you name it. Apps like Google Home and Apple HomeKit allow you to manage everything from your smartphone. Want to dim the lights? No problem. Adjust the temperature? Easy peasy. </p>
                            <br />
                            <p>What’s even cooler is the ability to set up routines. You can program your app to dim the lights and play soft music when it’s movie night. How’s that for a Friday night upgrade? The connectivity of IoT devices empowers us to create personalized living spaces—basically turning our homes into smart havens.</p>
                            <br />
                        </Element>
                        <div className='custom-width-sm custom-width-md-lg m-auto'>
                            <Image src="/assets/mobileblog4b.jpg" alt="Data Analytics" className='img-fluid w-100' preview={false} />
                        </div>
                        <br />
                        <Element name="section3" className="element">
                            <Title className='text-black mb-1 fs-4'>
                                Healthcare: Empowering People and Professionals
                            </Title>
                            <br />
                            <p>Now, let’s pivot to a more serious area: healthcare. IoT is making waves in this field by connecting patients and healthcare providers through mobile apps. Wearable devices, like fitness trackers and smartwatches, gather data on our health, such as heart rates or activity levels, and transmit that information to mobile apps. </p>
                            <br />
                            <p>But where does the magic happen? That data feeds into apps that not only monitor our health but provide actionable insights. For instance, if your watch detects an irregular heartbeat, the app can alert you and even give your doctor a heads-up. This real-time monitoring means quicker responses and better patient care. </p>
                            <br />
                            <p>Plus, telemedicine has become increasingly popular, thanks to the synergy between IoT and mobile apps. Now you can consult with healthcare professionals from the comfort of your home, reducing the need for unnecessary trips to the doctor. Talk about convenience!</p>
                            <br />
                        </Element>
                        <div className='custom-width-sm custom-width-md-lg m-auto'>
                            <Image src="/assets/mobileblog4c.jpg" alt="Data Analytics" className='img-fluid w-100' preview={false} />
                        </div>
                        <br />
                        <Element name="section4" className="element">
                            <Title className='text-black mb-1 fs-4'>
                                Industrial Automation: Smart Solutions for the Workplace
                            </Title>
                            <br />
                            <p>Lastly, let’s take a look at industrial automation. IoT is revolutionizing the workplace in ways that are both efficient and transformative. From factories to supply chains, mobile apps can keep managers updated in real time about equipment status, inventory levels, and safety conditions.</p>
                            <br />
                            <p>Imagine mobile apps connected to sensors on machinery that send alerts when maintenance is needed. This prevents costly downtimes and keeps operations running smoothly. Plus, data collected from various IoT devices can be analyzed to optimize processes and improve productivity. All this means businesses can focus on growth and innovation rather than firefighting problems.</p>
                            <br />
                        </Element>
                        <div className='custom-width-sm custom-width-md-lg m-auto'>
                            <Image src="/assets/mobileblog4d.jpg" alt="Data Analytics" className='img-fluid w-100' preview={false} />
                        </div>
                        <br />
                        <Element name="section5" className="element">
                            <br />
                            <p className='bg_grey p-lg-5 p-3 rounded-3 fst_italic'> <span className='fs-1'>‟
                                <br />
                                <span className='fw-bold fs-3'>Wrapping It Up!</span> </span><br />
                                So, there you have it: the IoT and mobile app development combo is a game-changer across different spheres of our lives. Whether it’s enabling cozy smart homes, enhancing healthcare monitoring, or boosting industrial efficiency, the connections made possible by IoT are shaping our world in ways that were once the stuff of science fiction.
                            </p>
                        </Element>
                    </Col>
                </Row>
                <div className='d-flex justify-content-center mt-3'>
                    <ShareMidButton url={"/blogs/mobile-app-development/role-of-iot-in-mobile-app-development"} />
                </div>
            </div>
        </>
    );
}

export default MobileBlog4;
