import './index.css'
import {Row, Col, Typography, Space, Image} from "antd"
const AllServices=()=>
    {
        const state=[
            {
                title:'Software Development',
                description:'We have full -stack developers who are skilled in web-based apps with expertise in all web technologies including Laravel, PHP, NodeJS, Angular, Bootstrap, ASP.NET, ReactJS. For desktop apps C# and Electron.',
                bgImage:'software-light.png',
                icon1:'software.png',
                icon2:'software-white.png'
            },
            {
                title:'Website development',
                description:'Mkaits develops innovative and intuitive websites that ensure product quality and customer satisfaction, all resulting in a high conversion rate. We developed more than 100+ websites and web apps',
                bgImage:'web-development-light.png',
                icon1:'web.png',
                icon2:'web-development-white.png'
            },
            {
                title:'App Development',
                description:'Mkaits provides mobile app development services which includes building iOS & Android and applications that efficiently complement web solutions. ',
                bgImage:'mobile-development-light.png',
                icon1:'mobile-development.png',
                icon2:'mobile-development-white.png'
            },
            {
                title:'Game Development',
                description:'Mkaits provides game development services which includes building iOS, Android and Windows games',
                bgImage:'game-development-light.png',
                icon1:'game-development.png',
                icon2:'game-development-white.png'
            },
            {
                title:'BlockChain',
                description:'Mkaits provides Mkaits development services which includes banking, marketing, industry applications.',
                bgImage:'blockchain-light.png',
                icon1:'blockchain.png',
                icon2:'blockchain-white.png'
            },
            {
                title:'Desktop App Development',
                description:'Mkaits provides desktop app development services which includes windows, mac, linux applications.',
                bgImage:'desktop-app-light.png',
                icon1:'desktop-app.png',
                icon2:'desktop-app-white.png'
            },
            {
                title:'UI/UX Design',
                description:'Our innovative approach to UI/UX Design ensures customer satisfaction as they are meant for developing visually attractive, emblem strengthening digital interfaces that attracts customers.',
                bgImage:'ui-light.png',
                icon1:'ui.png',
                icon2:'ui-white.png'
            },
            {
                title:'Digital Marketing',
                description:'Mkaits takes an innovative approach to digital advertising and marketing, focused on targeted customers at every level of the digital marketing.',
                bgImage:'digital-light.png',
                icon1:'digital.png',
                icon2:'digital-white.png'
            },
            {
                title:'Search Engine Optimization',
                description:'Mkaits has the most advanced testing platform in SEO. We build calibrated search engine models against any existing search engine.',
                bgImage:'seo-light.png',
                icon1:'seo.png',
                icon2:'seo-white.png'
            }
        ]
        return(
            <>
                <Row gutter={[2,2]}>
                    {
                        state?.map((data,d)=>
                            <Col key={'services-'+d} lg={{span:8}} md={{span:12}}>
                                <div className="service-box animation h-100">
                                    <div className='service-info flex-col'>
                                        <Image src={'/assets/'+data?.icon1} preview={false} className='service-icon-black mb-3' width='95px'/>
                                        <Image src={'/assets/'+data?.icon2} preview={false} className='service-icon-white mb-3' width='95px'/>
                                        <Typography.Title level={3} className='secondary-color service-title'>{data?.title}</Typography.Title>
                                        <Typography.Text className='txt-white service-description'>
                                            {
                                                data?.description
                                            }
                                        </Typography.Text>
                                    </div>
                                    <img src={'/assets/'+data?.bgImage} className='service-bg-image animation'/>
                                </div>
                            </Col>
                        )
                    }
                </Row>
               
            </>
        )
    }
export default AllServices;