export const data = 
    {
        title: 'Services we’re providing to our customers',
        subtitle: 'What we’re offering',
        description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. quis nostrud exercitation ullamco laboris.',
        separatBlock:[
            {
                topicon: 'software-brand.png',
                blocktitle: 'Software development',
                blockdescription: 'Lorem ipsum dolor sit amet do.',
            },
            {
                topicon: 'web-development-brand.png',
                blocktitle: 'Web development',
                blockdescription: 'Lorem ipsum dolor sit amet do.',
            },
            {
                topicon: 'mobile-development-brand.png',
                blocktitle: 'Game development',
                blockdescription: 'Lorem ipsum dolor sit amet do.',
            },
            {
                topicon: 'blockchain-brand.png',
                blocktitle: 'Mkaits',
                blockdescription: 'Lorem ipsum dolor sit amet do.',
            },
            {
                topicon: 'desktop-app-brand.png',
                blocktitle: 'Desktop app development',
                blockdescription: 'Lorem ipsum dolor sit amet do.',
            },
            {
                topicon: 'ui-brand.png',
                blocktitle: 'UI/UX',
                blockdescription: 'Lorem ipsum dolor sit amet do.',
            },
            {
                topicon: 'digital-brand.png',
                blocktitle: 'Digital marketing',
                blockdescription: 'Lorem ipsum dolor sit amet do.',
            },
            {
                topicon: 'seo-brand.png',
                blocktitle: 'SEO',
                blockdescription: 'Lorem ipsum dolor sit amet do.',
            },
        ]
    }
