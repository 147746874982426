import { Col, Row, Image, Progress, Typography, Space } from "antd";
import "./index.css";
const { Title } = Typography;
function Technology() {
  return (
    <div className="flex-col-center py-5 my-5">
      <Row style={{ width: "93%" }}>
        <Col span={24}>
          <div className="technology">
            <div className="one">
              <Image src="assets/we-stand-shape.png" alt="Mkaits" />
            </div>
            <h2
              style={{ backgroundImage: "url(assets/tech-bg-1-1.jpg)" }}
              className="we-stand__top-title two"
            >
              Mkaits....
            </h2>
            <h2
              style={{ backgroundImage: "url(assets/tech-bg-1-1.jpg)" }}
              className="we-stand__top-title two two-2"
            >
              Mkaits
            </h2>
          </div>
        </Col>
      </Row>
      <Row gutter={[32, 32]} style={{ width: "93%" }} className="mt-5">
        <Col
          lg={{ span: 8 }}
          md={12}
          sm={{ span: 24 }}
          xs={{ span: 24 }}
          className="align-center"
        >
          <Space direction="vertical">
            <Title level={4}>Technology growth</Title>
            <Title level={3} className="my-0 brand-color">
              Our Expertise development and diversity workspace strategy
              accelerates business growth.
            </Title>
          </Space>
        </Col>
        <Col
          lg={{ span: 8 }}
          md={12}
          sm={{ span: 24 }}
          xs={{ span: 24 }}
          className="align-center"
        >
          <Space direction="vertical">
            <p>
              Our expertise team spans all fundamental technologies and
              structures, and advances to innovative technology developments.
              Our team is constantly open to sharing their expertise all around
              the globe about developing professional IT content. With years of
              experience in software development and a team of seasoned
              professionals, we offer a range of cutting-edge tools and
              technologies to help our clients stand out from the crowd.
            </p>
            <p>
              Our expertise in developing reliable and scalable software
              solutions allows us to combine deep industry knowledge with the
              latest IT advancements, resulting in custom solutions and products
              that perfectly align with the needs and behavior of their users.
              From concept to implementation, our end-to-end approach ensures
              that each project is tailored to our clients' unique requirements
              and is delivered on time and on budget. Whether you're looking to
              streamline your operations, enhance customer engagement, or gain a
              competitive edge in your industry, Mkaits has the experience and
              expertise to help you achieve your goals. Contact us today to
              learn more about how our innovative digital solutions can
              transform your business.
            </p>
          </Space>
        </Col>
        <Col lg={{ span: 8 }} sm={{ span: 24 }} xs={{ span: 24 }}>
          <Space direction="vertical" className="w-100">
            <Space direction="vertical" className="w-100">
              <Title level={5} className="my-0">
                Software Development
              </Title>
              <Progress
                strokeColor={{
                  "0%": "rgb(22,108,159)",
                  "100%": "rgb(22, 68, 139)",
                }}
                percent={97}
              />
            </Space>
            <Space direction="vertical" className="w-100">
              <Title level={5} className="my-0">
                Innovation and Research
              </Title>
              <Progress
                strokeColor={{
                  "0%": "rgb(22,108,159)",
                  "100%": "rgb(22, 68, 139)",
                }}
                percent={98}
              />
            </Space>
            <Space direction="vertical" className="w-100">
              <Title level={5} className="my-0">
                Quality Assurance and Testing
              </Title>
              <Progress
                strokeColor={{
                  "0%": "rgb(22,108,159)",
                  "100%": "rgb(22, 68, 139)",
                }}
                percent={98}
              />
            </Space>
            <Space direction="vertical" className="w-100">
              <Title level={5} className="my-0">
                Data Analytics
              </Title>
              <Progress
                strokeColor={{
                  "0%": "rgb(22,108,159)",
                  "100%": "rgb(22, 68, 139)",
                }}
                percent={96}
              />
            </Space>
            <Space direction="vertical" className="w-100">
              <Title level={5} className="my-0">
                Adaptability and Scalability
              </Title>
              <Progress
                strokeColor={{
                  "0%": "rgb(22,108,159)",
                  "100%": "rgb(22, 68, 139)",
                }}
                percent={97}
              />
            </Space>
            <Space direction="vertical" className="w-100">
              <Title level={5} className="my-0">
                Customer Relationship Management
              </Title>
              <Progress
                strokeColor={{
                  "0%": "rgb(22,108,159)",
                  "100%": "rgb(22, 68, 139)",
                }}
                percent={99}
              />
            </Space>
            <Space direction="vertical" className="w-100">
              <Title level={5} className="my-0">
                Customer Statisfaction
              </Title>
              <Progress
                strokeColor={{
                  "0%": "rgb(22,108,159)",
                  "100%": "rgb(22, 68, 139)",
                }}
                percent={99.5}
              />
            </Space>
          </Space>
        </Col>
      </Row>
    </div>
  );
}

export default Technology;
