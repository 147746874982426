
import { AudioRecorder } from 'react-audio-voice-recorder'

const Audio = ({setAudio})=>{
    const addAudioElement = (blob) => {
        setAudio(blob)
    }
    return (
        <AudioRecorder 
            onRecordingComplete={addAudioElement}
            audioTrackConstraints={{
                noiseSuppression: true,
                echoCancellation: true,
            }} 
            downloadOnSavePress={false}
            downloadFileExtension="webm"
        />
    )
}
export {Audio}