import {Row, Col, Typography} from "antd"
import Slider from 'react-slick';
import {ArrowRightOutlined} from "@ant-design/icons"
import './index.css'
const {Title}=Typography 
const ReplaProjects=()=>{
    const state=[
        {
            title:'Solution',
            description:'Platform Integration',
            bg:'project-1.jpg'
        },
        {
            title:'Solution',
            description:'Platform Integration',
            bg:'project-2.jpg'
        },
        {
            title:'Solution',
            description:'Platform Integration',
            bg:'project-3.jpg'
        },
        {
            title:'Solution',
            description:'Platform Integration',
            bg:'project-4.jpg'
        },
        {
            title:'Solution',
            description:'Platform Integration',
            bg:'project-5.jpg'
        },
        {
            title:'Solution',
            description:'Platform Integration',
            bg:'project-6.jpg'
        },
    ]
    var settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 0,
        
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 0
            }
          }
        ]
      }
    return (
            <div style={{display:'flex',flexDirection:'column',alignItems:'center',overflowX:'hidden'}} className='py-5 light-bg w-100'>
                <div className="justify-center py-5 w-100">
                    <Row gutter={[64,32]} style={{width:'93%'}}>
                        <Col xl={{span:12}} lg={{span:24}} md={{span:24}} sm={{span:24}} xs={{span:24}}>
                            <Typography.Title level={3}>
                                Recently completed work
                            </Typography.Title>
                            <Typography.Title leve={5} className='my-0 brand-color'>
                                 Our recently completed projects list
                            </Typography.Title>
                        </Col>
                        <Col xl={{span:12}} lg={{span:24}} md={{span:24}} sm={{span:24}} xs={{span:24}} className='align-center'>
                            <Typography.Title level={5}>
                                Mkaits innovates businesses with effective and adaptable digital solutions that satisfy the today’s competition and liberate the opportunities of the future. 
                                Our innovative tools, team of professionals and years of revel in software development gives us the benefit to face out from the crowd.  
                            </Typography.Title>
                        </Col>
                    </Row>
                </div>
                <div style={{width:'93%'}} className='py-5'>
                    <Slider {...settings}>
                        {
                            state?.map((data,d)=>
                                <div className="px-3">
                                    <div key={d} className='project-box flex-col-center rounded '>
                                        <img src={'/assets/'+data?.bg} alt='mkaits' width={'100%'} className='one'/>
                                        <div className="two animation"></div>
                                        <div className="project-button px-4 py-4 justify-center shadow-sm bg-white">
                                            <div className="project-button-effect animation">
                                            </div>
                                            <div  className="project-button-circle animation">
                                                <ArrowRightOutlined className="brand-color"/>
                                            </div>
                                            <div className='my-0 project-button-text-cover'>
                                                <Title level={5} className='brand-color project-button-text my-0'>
                                                    {
                                                        data?.title
                                                    }
                                                </Title>
                                                <Title level={3} className='project-button-text my-0'>
                                                    {
                                                        data?.description
                                                    }
                                                </Title>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </Slider>
                </div>
            </div>
    )
}
export default ReplaProjects