import { NavLink } from "react-router-dom";
import './index.css'
import {Typography} from  'antd'
const {Title}=Typography;
const BreadCrumb=({title,description,pageName,breadcrumb})=>
    {
        return(
            <div 
                style={{backgroundImage:`linear-gradient(rgba(0,0,0,0.7),rgba(0,0,0,0.7)),url(${'/assets/page-header-bg.jpg'})`,width:'100%'}}
                className='title flex-col-center-center py-5'
            >
                <div className='my-5' style={{width:'93%'}}>
                        <Title level={1} className='justify-center brand-color my-0'>{title}</Title>
                        <div className="justify-center">
                            {
                                breadcrumb.map(
                                    (breadCrumb,b)=> 
                                        <Typography.Text strong key={'bread-crumb-'+b}>
                                            <NavLink to={breadCrumb?.to} className='text-white'>{breadCrumb?.name}</NavLink>
                                            <span className="mx-2 text-white">/</span>
                                        </Typography.Text>
                                    )
                            }
                            <Typography.Text strong  className="brand-color">{pageName}</Typography.Text>
                        </div>
                </div>
            </div>
        )
    }
export {BreadCrumb}