import {Row, Col, Typography, Space, Image} from "antd"
import {MessageOutlined} from "@ant-design/icons"
import  {Link} from "react-router-dom"
import './index.css'
const {Title, Text}=Typography
const AboutServices=()=>{
    const lists=[
        {
            title:'We deliver perfect solutions',
            description:'Lorem Ipsum nibh vel velit auctor aliqu aenean sollic.'
        },
        {
            title:'We work with global brands',
            description:'Lorem Ipsum nibh vel velit auctor aliqu aenean sollic.'
        }
    ]
    return (
            <div className="justify-center intro py-5 my-5">
                <Row gutter={[32,32]} style={{width:'93%'}}>
                    <Col xl={{span:12}} lg={{span:24}} md={{span:24}} sm={{span:24}} xs={{span:24}}>
                        <div className="about-services-left align-center">
                            <Image src='/assets/about-services.jpg' alt='Mkaits' className="img" width={'100%'}/>
                            <div className="two">
                                <Link to='/about-us'>
                                    <Image src='/assets/fast-forward.png' alt='Mkaits' preview={false}/>
                                </Link>
                            </div>
                        </div>
                    </Col>
                    <Col xl={{span:12}} lg={{span:24}} md={{span:24}} sm={{span:24}} xs={{span:24}} className='align-center'>
                        <Space direction='vertical' size={30}>
                            <div>
                                <Title level={3} className='brand-color'>Stay protected</Title>
                                <Title level={1} className='my-0'>
                                    Tech services for your business ideas
                                </Title>
                            </div>
                            <div>
                                <Text>
                                    Donec suscipit ante ipsum. Donec convallis quam at tortor hendrerit, ut elementum augue suscipit. Morbi tincidunt risus vel neque convallis pretium non nec justo.
                                </Text>
                            </div>
                            <Space direction='vertical' size={10} style={{width:'100%'}}>
                                {
                                    lists?.map((list, l)=>
                                        <Space size={13} key={'list-'+l} className={'animation latest-blogs latest-comments px-3 py-2'}>
                                            <div className='latest-comments-cover center'>
                                                <MessageOutlined style={{fontSize:'18px'}} className='latest-comments-icon'/>
                                            </div>
                                            <Space direction='vertical' size={2}>
                                                <Title level={4}>
                                                    {
                                                        list?.title
                                                    }
                                                </Title>
                                                <Text>
                                                    {
                                                        list?.description
                                                    }
                                                </Text>
                                            </Space>
                                        </Space>
                                    )
                                }
                            </Space>
                        </Space>
                    </Col>
                </Row>
            </div>
    )
}
export default AboutServices