export const DataVideo = [
    {
        video:'videogallery/1.mp4',
        title:'Laika',
        linkvideo:'Our New Product',
    },
    {
        video:'videogallery/2.mp4',
        title:'Wufiswap',
        linkvideo:'Our New Product',
    },
    {
        video:'videogallery/3.mp4',
        title:'NFT Gaming Stars',
        linkvideo:'Our New Product',
    },
    {
        video:'videogallery/4.mp4',
        title:'NFSounds',
        linkvideo:'Our New Product',
    },
    {
        video:'videogallery/5.mp4',
        title:'Randomz',
        linkvideo:'Our New Product',
    },
    {
        video:'videogallery/6.mp4',
        title:'EonStarter',
        linkvideo:'Our New Product',
    },
    {
        video:'videogallery/1.mp4',
        title:'Laika',
        linkvideo:'Our New Product',
    },
    {
        video:'videogallery/2.mp4',
        title:'Wufiswap',
        linkvideo:'Our New Product',
    },
    {
        video:'videogallery/3.mp4',
        title:'NFT Gaming Stars',
        linkvideo:'Our New Product',
    },
    {
        video:'videogallery/4.mp4',
        title:'NFSounds',
        linkvideo:'Our New Product',
    },
    {
        video:'videogallery/5.mp4',
        title:'Randomz',
        linkvideo:'Our New Product',
    },
    {
        video:'videogallery/6.mp4',
        title:'EonStarter',
        linkvideo:'Our New Product',
    },
    // {
    //     video:'videogallery/7.mp4',
    //     title:'Product',
    //     linkvideo:'Our New Product',
    // },
    // {
    //     video:'videogallery/8.mp4',
    //     title:'Product',
    //     linkvideo:'Our New Product',
    // },
    // {
    //     video:'videogallery/9.mp4',
    //     title:'Product',
    //     linkvideo:'Our New Product',
    // },
    // {
    //     video:'videogallery/10.mp4',
    //     title:'Product',
    //     linkvideo:'Our New Product',
    // },
]